import { DownloadIcon } from "lucide-react"

import { SAFE_EMPTY_URL } from "../constants"

interface Props {
  children: React.ReactNode
  href?: string
  height?: string
  className?: string
  onClick?: () => void
}

const DownloadLink: React.FC<Props> = ({
  children,
  href,
  height = "12",
  className,
  onClick,
}) => {
  let linkClass = "items-center hover:underline "
  if (className) {
    linkClass += className
  }
  if (onClick) {
    // Use a button to avoid popup blocker.
    return (
      <button
        className={"appearance-none bg-transparent outline-0 " + linkClass}
        onClick={onClick}
      >
        {children} <DownloadIcon className="inline" size={height} />
      </button>
    )
  }

  return (
    <a
      className={linkClass}
      href={href ?? SAFE_EMPTY_URL}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children} <DownloadIcon className="inline" size={height} />
    </a>
  )
}

export default DownloadLink
