import Button from "antd/es/button"

import { getColumnLetter } from "../../sheets/utils"
import type { WorksheetRange } from "../../types/sheets"

interface Props {
  location: WorksheetRange
  className?: string
  onClickLocation: (location: WorksheetRange) => void
}

const TitleCTA: React.FC<Props> = ({
  location: discLocation,
  onClickLocation,
  className = "",
}) => {
  const locationLabel = `${getColumnLetter(discLocation.firstColIndex)}${discLocation.firstRowIndex}`
  return (
    <div className={"flex items-center font-bold " + className}>
      <Button
        size="small"
        className="m-0 p-0 font-bold"
        type="link"
        onClick={() => onClickLocation(discLocation)}
      >
        {locationLabel}
      </Button>
      <span className="ml-1">DISCUSSION</span>
    </div>
  )
}

export default TitleCTA
