import { httpsCallable } from "../serverUtils"
import type {
  GetWebPageMetadataArgs,
  GetWebPageMetadataResult,
} from "./types.js"

export const getWebPageMetadata = httpsCallable<
  GetWebPageMetadataArgs,
  GetWebPageMetadataResult
>("getWebPageMetadata")
