import Button from "antd/es/button"
import Form from "antd/es/form"
import Input from "antd/es/input"
import Select from "antd/es/select"
import Skeleton from "antd/es/skeleton"
import { collection, query, where } from "firebase/firestore"
import {
  Calendar,
  CalendarIcon,
  CheckIcon,
  CircleUserRoundIcon,
  XIcon,
} from "lucide-react"
import { useCallback, useMemo, useState } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"

import AssignKnowledgeItemButton from "../../components/AssignKnowledgeItemForm"
import DownloadLink from "../../components/DownloadLink"
import ExternalLink from "../../components/ExternalLink"
import { useActiveUserAuthorizationFromContext } from "../../contexts/ActiveUserAuthorizationContext"
import { makeConverter } from "../../dbUtils"
import { getDocumentUrl } from "../../documents/api"
import { db } from "../../firebaseApp"
import useErrorPopup from "../../hooks/useErrorPopup"
import { useGroupMembers } from "../../hooks/useGroupMembers"
import { useGroupTags } from "../../hooks/useGroupTags"
import {
  editKnowledgeItemsApi,
  reviewKnowledgeItems,
} from "../../knowledge/api"
import type { AnyUserDocument } from "../../knowledge/types"
import { SOURCE_KIND_DESCRIPTION } from "../../sources/constants"
import { TagListItem } from "../../tags/Tag"
import { USER_DOCUMENTS_COLLECTION } from "../../types/common"
import { EXCEL_SUPPORTED_MIMETYPES } from "../../types/mimetype"
import type { UserDocument } from "../../types/userDocument"
import { toHumanReadableString } from "../../utils"

interface FormDataType {
  tags: string[]
  summary_for_search?: string
}

const FormTextItemStyle: React.FC<React.PropsWithChildren> = ({ children }) => (
  <div className="flex flex-wrap items-center gap-1 overflow-y-auto text-[14px] leading-5 text-gray-600">
    {children}
  </div>
)

const UserDocumentForm: React.FC<{ doc: UserDocument }> = ({ doc: srcDoc }) => {
  const [form] = Form.useForm<FormDataType>()
  const { activeGroupOid, authUser, hasPerm } =
    useActiveUserAuthorizationFromContext()

  const { handleSuccess, handleError } = useErrorPopup()
  const [groupMembers, groupMembersLoading] = useGroupMembers(activeGroupOid)
  const [tags, tagsLoading] = useGroupTags(activeGroupOid)
  const [submitting, setSubmitting] = useState(false)
  const [reviewing, setReviewing] = useState(false)
  const [unreviewing, setUnreviewing] = useState(false)

  const couldHaveLinkedItems =
    srcDoc.mimetype && EXCEL_SUPPORTED_MIMETYPES.includes(srcDoc.mimetype)
  const [generatedDocs, generatedDocsLoading] = useCollectionData(
    couldHaveLinkedItems
      ? query(
          collection(db, USER_DOCUMENTS_COLLECTION),
          where("group_oid", "==", activeGroupOid),
          where("source.source_oid", "==", srcDoc.oid),
        ).withConverter(makeConverter<AnyUserDocument>())
      : null,
  )

  let sourceDocUserText: React.ReactNode
  if (authUser.uid === srcDoc.creator.uid) {
    sourceDocUserText = authUser.email
  } else if (groupMembersLoading) {
    sourceDocUserText = (
      <Skeleton
        active
        className="w-36"
        title={false}
        paragraph={{ className: "m-0", rows: 1 }}
      />
    )
  } else {
    const groupMember = groupMembers?.find(
      (member) => member.uid === srcDoc.creator.uid,
    )
    sourceDocUserText = groupMember?.email ?? "Unknown User"
  }

  const initialValues = useMemo(
    () => ({
      tags: srcDoc.tags ? Object.keys(srcDoc.tags) : [],
    }),
    [srcDoc.tags],
  )

  const onDownload = useCallback(async () => {
    try {
      const { data: downloadUrl } = await getDocumentUrl({
        oid: srcDoc.oid,
        isDownload: true,
      })
      window.open(downloadUrl, "_blank", "noopener,noreferrer")
      void handleSuccess("Downloading document")
    } catch (error) {
      handleError({ error, prefix: "Couldn't download document" })
    }
  }, [handleSuccess, handleError, srcDoc.oid])

  const onChangeReview = useCallback(
    async (
      kind: "REVIEW" | "UNREVIEW",
      setStatus: (status: boolean) => void,
    ) => {
      setStatus(true)
      try {
        await reviewKnowledgeItems({
          requests: [
            {
              oid: srcDoc.oid,
              kind,
            },
          ],
        })
        handleSuccess(
          kind === "REVIEW" ? "Document reviewed!" : "Document unreviewed!",
        )
      } catch (error) {
        handleError({ error, prefix: "Couldn't change review status" })
      } finally {
        setStatus(false)
      }
    },
    [handleSuccess, handleError, srcDoc.oid],
  )

  const reviewItem = useCallback(async () => {
    return await onChangeReview("REVIEW", setReviewing)
  }, [onChangeReview])

  const unreviewItem = useCallback(async () => {
    return await onChangeReview("UNREVIEW", setUnreviewing)
  }, [onChangeReview])

  const onFinish = useCallback(
    async (formData: FormDataType) => {
      setSubmitting(true)
      try {
        await editKnowledgeItemsApi({
          requests: [
            {
              oid: srcDoc.oid,
              tags: formData.tags
                ? Object.fromEntries(formData.tags.map((tag) => [tag, null]))
                : undefined,
              summary_for_search: formData.summary_for_search ?? undefined,
            },
          ],
        })
        handleSuccess("Updated document")
      } catch (error) {
        handleError({ error, prefix: "Couldn't update document" })
      } finally {
        setSubmitting(false)
      }
    },
    [handleSuccess, handleError, srcDoc.oid],
  )

  const submitTitle = hasPerm("doc.modify")
    ? "Update"
    : "Cannot update, you do not have write permissions"

  return (
    <Form
      form={form}
      layout="vertical"
      requiredMark="optional"
      initialValues={initialValues}
      onFinish={onFinish}
      disabled={submitting}
    >
      <Form.Item className="font-semibold" label="Title" required name="title">
        {srcDoc.source_kind === "FILEUPLOAD" ||
        srcDoc.source_kind === "QUESTIONNAIRE_ASSISTANT_IMPORT" ? (
          <DownloadLink
            className="text-primary justify-start text-left"
            onClick={onDownload}
          >
            {srcDoc.title || "Document"}
          </DownloadLink>
        ) : (
          <ExternalLink
            className="text-primary justify-start text-left"
            href={srcDoc.document_url}
          >
            {srcDoc.title || (
              <span className="break-words">{srcDoc.document_url}</span>
            )}
          </ExternalLink>
        )}
      </Form.Item>
      <Form.Item label="Created By" required>
        <FormTextItemStyle>
          <CircleUserRoundIcon size={14} />
          {sourceDocUserText}
        </FormTextItemStyle>
        <FormTextItemStyle>
          <CalendarIcon size={14} />
          {toHumanReadableString(srcDoc.created_at)}
        </FormTextItemStyle>
      </Form.Item>

      <Form.Item label="Last Updated At" className="font-semibold" required>
        <div className="flex items-center gap-1 font-normal">
          <Calendar size={14} />
          {toHumanReadableString(srcDoc.updated_at)}
        </div>
      </Form.Item>

      <Form.Item label="Import Method" className="font-semibold" required>
        <div className="font-normal">
          {SOURCE_KIND_DESCRIPTION[srcDoc.source_kind]}
        </div>
      </Form.Item>

      {srcDoc.last_assigned_to && srcDoc.last_assigned_at && (
        <Form.Item label="Last Assigned To" required>
          <FormTextItemStyle>
            <CircleUserRoundIcon size={14} />
            {srcDoc.last_assigned_to.email}
          </FormTextItemStyle>
          <FormTextItemStyle>
            <CalendarIcon size={14} />
            {new Date(srcDoc.last_assigned_at.seconds * 1000).toDateString()}
          </FormTextItemStyle>
        </Form.Item>
      )}
      {srcDoc.last_reviewed_at && srcDoc.last_reviewed_by && (
        <Form.Item label="Last Reviewed By" required>
          <FormTextItemStyle>
            <CircleUserRoundIcon size={14} />
            {srcDoc.last_reviewed_by.email}
          </FormTextItemStyle>
          <FormTextItemStyle>
            <CalendarIcon size={14} />
            {new Date(srcDoc.last_reviewed_at.seconds * 1000).toDateString()}
          </FormTextItemStyle>
        </Form.Item>
      )}

      <Form.Item label="Tags" name="tags" className="mb-0">
        <Select
          mode="multiple"
          className="w-full"
          options={tags?.map((tag) => ({
            value: tag.oid,
            label: <TagListItem tag={tag} />,
            name: tag.name,
          }))}
          optionFilterProp="name"
          placeholder={
            tagsLoading ? (
              <Skeleton active loading title={false} paragraph={{ rows: 1 }} />
            ) : (
              "Select Tags"
            )
          }
          disabled={tagsLoading}
        />
      </Form.Item>
      <div className="mb-4 text-xs text-gray-500">
        To add a new tag visit{" "}
        <a href="/groups" className="font-semibold">
          groups settings
        </a>
      </div>
      <Form.Item
        label="Summary (for search)"
        name="summary_for_search"
        className="mb-0"
      >
        <Input.TextArea
          className="w-full"
          placeholder="Keywords or short summary to improve search"
        />
      </Form.Item>
      <div className="mt-8 pb-2">
        <div className="mb-3 flex">
          <Button
            type="primary"
            className="submit mr-2 flex font-bold"
            htmlType="submit"
            title={submitTitle}
            disabled={submitting || !hasPerm("doc.modify") || tagsLoading}
          >
            {submitting ? "Updating..." : "Update"}
          </Button>
          <AssignKnowledgeItemButton
            knowledgeOid={srcDoc.oid}
            assignedTo={srcDoc.last_assigned_to}
            isUserDocument
          />
        </div>
        {hasPerm("doc.modify") && (
          <div className="mt-3 flex">
            <Button
              className="submit flex items-center font-bold"
              onClick={reviewItem}
              icon={<CheckIcon />}
              title="Mark as Reviewed"
              disabled={submitting || reviewing || unreviewing}
            >
              {reviewing ? "Reviewing..." : "Review"}
            </Button>
            <Button
              className="submit ml-2 flex items-center font-bold"
              onClick={unreviewItem}
              icon={<XIcon />}
              title="Remove review status"
              disabled={
                submitting ||
                reviewing ||
                unreviewing ||
                !srcDoc.last_reviewed_at
              }
            >
              {unreviewing ? "Removing..." : "Remove Review"}
            </Button>
          </div>
        )}
      </div>
      {couldHaveLinkedItems && (
        <Skeleton
          active
          loading={generatedDocsLoading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {generatedDocs ? (
            <a
              href={`/knowledge-items?source=${srcDoc.oid}`}
              className="text-primary mt-4 font-semibold"
            >
              View {generatedDocs.length} linked Answer Bank items
            </a>
          ) : (
            "Could not load linked Answer Bank items"
          )}
        </Skeleton>
      )}
    </Form>
  )
}
export default UserDocumentForm
