import Button from "antd/es/button"
import Form from "antd/es/form"
import Input from "antd/es/input"
import TextArea from "antd/es/input/TextArea"
import { useEffect, useState } from "react"
import { useIntercom } from "react-use-intercom"

import HelpButton from "../../components/HelpButton"

interface FormData {
  url: string
  username?: string
  password?: string
  instructions: string
}

const FormTitle: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <span className="px-2 pb-6 pt-5 text-center text-2xl font-bold">
    {children}
  </span>
)

const InputPage: React.FC = () => {
  const [errorString] = useState<string | undefined>()

  const initialValues: FormData = {
    url: "",
    username: "",
    password: "",
    instructions: "",
  }
  const [form] = Form.useForm<FormData>()

  const submitting = false

  const onFinish = () => {
    alert("Done")
  }

  const { showNewMessage: showIntercom } = useIntercom()

  // Show intercom on error.
  useEffect(() => {
    if (errorString) {
      showIntercom(
        `I'm having trouble signing in. I got this error: "${errorString}"`,
      )
    }
  }, [errorString, showIntercom])

  return (
    <div className="m-auto flex h-full w-[480px] flex-col items-center justify-start pt-8">
      <div className="flex w-[480px] flex-col items-center justify-center rounded-[20px] bg-white p-6 backdrop-blur-md">
        <FormTitle>Automated End-to-End Testing</FormTitle>
        <div className="flex w-[480px] flex-col items-center">
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            initialValues={initialValues}
            className="w-full"
          >
            <Form.Item name="url" label="App URL" required>
              <Input
                disabled={submitting}
                title="The URL of your app"
                placeholder="https://chat.openai.com/"
              />
            </Form.Item>
            <Form.Item name="username" label="Username">
              <Input
                disabled={submitting}
                title="Your username"
                placeholder="johndoe@gmail.com"
              />
            </Form.Item>
            <Form.Item name="password" label="Password">
              <Input
                disabled={submitting}
                title="Your password"
                placeholder="Make sure to use a test account!"
              />
            </Form.Item>
            <Form.Item name="instructions" label="Instructions" required>
              <TextArea
                autoSize
                disabled={submitting}
                title="Instructions"
                placeholder="User should be able to navigate to the home page and send a message"
              />
            </Form.Item>
            <Button
              type="primary"
              className="w-26"
              style={{ float: "right" }}
              disabled={submitting}
              htmlType="submit"
            >
              {submitting ? "Submitting..." : "Submit"}
            </Button>
          </Form>
          {errorString && (
            <div className="text-center text-red-600">{errorString}</div>
          )}
        </div>
      </div>
      <div className="mt-4 flex w-[390px] flex-col items-center text-sm text-gray-600">
        <span>
          Need help?{" "}
          <HelpButton className="text-primary font-medium hover:underline">
            Contact Us
          </HelpButton>
        </span>
      </div>
    </div>
  )
}

export default InputPage
