import { initializeApp } from "firebase/app"
import { browserPopupRedirectResolver } from "firebase/auth"

import {
  getFirebaseAuth,
  getFirebaseConfig,
  getFirebaseFirestore,
  getFirebaseStorage,
} from "./firebaseConfig"

export const firebaseApp = initializeApp(getFirebaseConfig())
export const auth = getFirebaseAuth(firebaseApp, browserPopupRedirectResolver)
export const db = getFirebaseFirestore(firebaseApp)
export const firebaseStorage = getFirebaseStorage(firebaseApp)
