import Button from "antd/es/button"
import Popover from "antd/es/popover"
import type { ColumnType } from "antd/es/table"
import Table from "antd/es/table"
import Tag from "antd/es/tag"
import { collection, query, where } from "firebase/firestore"
import { EllipsisVerticalIcon } from "lucide-react"
import { useMemo, useState } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"

import { useActiveUserAuthorizationFromContext } from "../../contexts/ActiveUserAuthorizationContext"
import { makeConverter } from "../../dbUtils"
import { db } from "../../firebaseApp"
import type {
  Group,
  GroupInvite,
  GroupMembership,
  GroupRole,
} from "../../groups/types"
import { useGroupMembers } from "../../hooks/useGroupMembers"
import { INVITES_COLLECTION } from "../../types/common"
import UserAvatar from "../UserAvatar"
import { dateColumnProps } from "../table/dateColumn"
import AddMemberButton from "./AddMemberButton"
import DeleteGroupInviteConfirmation from "./DeleteGroupInviteConfirmation"
import DeleteMemberConfirmation from "./DeleteMemberConfirmation"
import UpdateMemberConfirmation from "./UpdateMemberConfirmation"
import { ROLE_INFOS } from "./constants"

type TableItem =
  | (GroupMembership & { type: "member" })
  | (GroupInvite & { type: "invite"; photoURL?: string })

interface UnifiedGroupTableProps {
  group: Group
}

const UnifiedGroupTable: React.FC<UnifiedGroupTableProps> = ({ group }) => {
  const { hasPerm } = useActiveUserAuthorizationFromContext()
  const [members, membersLoading] = useGroupMembers(group.oid)
  const [editMember, setEditMember] = useState<GroupMembership | null>(null)
  const [deleteMember, setDeleteMember] = useState<GroupMembership | null>(null)
  const [deleteInvite, setDeleteInvite] = useState<GroupInvite | null>(null)

  const colRef = collection(db, INVITES_COLLECTION)
  const allDocsQuery = query(
    colRef,
    where("group_oid", "==", group.oid),
    where("status", "==", "PENDING"),
  ).withConverter(makeConverter<GroupInvite>())

  const [invites, invitesLoading] = useCollectionData<GroupInvite>(allDocsQuery)

  const hidePopovers = () => {
    setEditMember(null)
    setDeleteMember(null)
    setDeleteInvite(null)
  }

  const tableData: TableItem[] = useMemo(() => {
    const memberEmails = new Set(members?.map((m) => m.email))
    const filteredInvites =
      invites?.filter((inv) => !memberEmails.has(inv.invitee_email)) || []
    return [
      ...(members || []).map((member) => ({
        ...member,
        type: "member" as const,
      })),
      ...filteredInvites.map((invite) => ({
        ...invite,
        photoURL: undefined,
        type: "invite" as const,
      })),
    ]
  }, [members, invites])

  const renderActions = (record: TableItem) => {
    if (!hasPerm("admin")) {
      return null
    }
    return (
      <Popover
        content={
          record.type === "invite" ? (
            deleteInvite === record ? (
              <DeleteGroupInviteConfirmation
                invite={record}
                onClose={hidePopovers}
              />
            ) : (
              <Button
                danger
                size="small"
                onClick={() => {
                  setDeleteInvite(record)
                }}
              >
                Delete Invite
              </Button>
            )
          ) : editMember === record ? (
            <UpdateMemberConfirmation
              group={group}
              membership={record}
              onClose={hidePopovers}
            />
          ) : deleteMember === record ? (
            <DeleteMemberConfirmation
              group={group}
              membership={record}
              onClose={hidePopovers}
            />
          ) : (
            <div className="flex items-center gap-2">
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  setEditMember(record)
                  setDeleteMember(null)
                }}
              >
                Edit Role
              </Button>
              <Button
                size="small"
                danger
                onClick={() => {
                  setEditMember(null)
                  setDeleteMember(record)
                }}
              >
                Delete
              </Button>
            </div>
          )
        }
        trigger="click"
        placement="bottomRight"
        onOpenChange={(visible) => {
          if (!visible) {
            hidePopovers()
          }
        }}
      >
        <Button icon={<EllipsisVerticalIcon />} size="small" />
      </Popover>
    )
  }

  const roleFilters = useMemo(
    () =>
      Object.entries(ROLE_INFOS).map(([key, value]) => ({
        text: value.name,
        value: key,
      })),
    [],
  )

  const columns: ColumnType<TableItem>[] = [
    {
      title: "User",
      dataIndex: "email",
      render: (_, record: TableItem) => (
        <div className="flex items-center">
          <UserAvatar
            name={
              record.type === "member" ? record.email : record.invitee_email
            }
            photoURL={record.photoURL}
            size="sm"
          />
          <span className="ml-2">
            {record.type === "member" ? record.email : record.invitee_email}
          </span>
        </div>
      ),
      sorter: (a: TableItem, b: TableItem) => {
        const aEmail = a.type === "member" ? a.email : a.invitee_email
        const bEmail = b.type === "member" ? b.email : b.invitee_email
        return aEmail.localeCompare(bEmail)
      },
    },
    {
      title: "Role",
      dataIndex: "role",
      width: 220,
      filters: roleFilters,
      onFilter: (value: unknown, record: TableItem) => record.role === value,
      render: (role: GroupRole, record: TableItem) => (
        <Tag
          className="text-xs font-semibold"
          color={record.type === "member" ? ROLE_INFOS[role].color : "orange"}
        >
          {record.type === "member"
            ? ROLE_INFOS[role].name
            : `Invitation Pending (${ROLE_INFOS[role].name})`}
        </Tag>
      ),
      sorter: (a: TableItem, b: TableItem) => {
        const aRole = ROLE_INFOS[a.role].name
        const bRole = ROLE_INFOS[b.role].name
        if (a.type !== b.type) {
          return a.type === "member" ? -1 : aRole === bRole ? 0 : 1
        }
        return aRole.localeCompare(bRole)
      },
    },
    dateColumnProps({
      title: "Joined / Invited On",
      dataIndex: "created_at",
      width: 300,
      sortable: true,
    }),
    {
      title: "Actions",
      key: "actions",
      width: 60,
      render: (_: unknown, record: TableItem) => renderActions(record),
    },
  ]

  return (
    <div className="mb-8">
      <div className="mb-4 flex items-center justify-between">
        <h3>Group Members and Invites</h3>
        {hasPerm("admin") && <AddMemberButton group={group} />}
      </div>
      <Table
        dataSource={tableData}
        bordered
        rowKey={(record) => record.oid}
        loading={membersLoading || invitesLoading}
        pagination={{ pageSize: 8, hideOnSinglePage: true }}
        columns={columns}
      />
    </div>
  )
}

export default UnifiedGroupTable
