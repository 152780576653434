import { httpsCallable } from "../serverUtils"
import type { CredentialMetadata } from "./providerTypes"
import type { CredentialProvider, GetCredentialRequest } from "./types"

export const getUserCredentials = httpsCallable<
  GetCredentialRequest,
  { credentials: CredentialMetadata[] }
>("getUserCredential")

interface RemoveCredentialEntryRequest {
  provider: CredentialProvider
  subProvider: string
}
export const removeUserCredentialEntry = httpsCallable<
  RemoveCredentialEntryRequest,
  void
>("removeUserCredentialEntry")
