import Skeleton from "antd/es/skeleton"
import { useEffect, useState } from "react"

import { getAnswer } from "../api"
import ChatMessageError from "../components/Chatv2/ChatMessageError"
import ChatReferenceList from "../components/Chatv2/ChatReferenceList"
import ConfidenceScore from "../components/ConfidenceScore"
import { ANALYTICS_PRODUCT } from "../constants"
import { getUserFacingError } from "../errors"
import type { UserFacingError } from "../errors"
import type { Answer } from "../types/answerer"
import type { CardDetail } from "./types"

interface Props {
  cardDetail: CardDetail
}
const CardDetailContent: React.FC<Props> = ({ cardDetail }) => {
  if (cardDetail.kind !== "ANSWERED_QUESTION") {
    throw new Error("Unsupported card detail kind")
  }

  const [loading, setLoading] = useState<boolean>(true)
  const [answer, setAnswer] = useState<Answer>()
  const [error, setError] = useState<UserFacingError>()

  useEffect(() => {
    void (async () => {
      try {
        setLoading(true)
        const response = await getAnswer({
          question: {
            text: cardDetail.question,
            details: null,
          },
          analyticsProduct: ANALYTICS_PRODUCT,
          analyticsSurface: "WEB_LIVE_CARD_DETAIL",
        })
        setAnswer(response.answer)
      } catch (error) {
        console.error("Error fetching answer", error)
        setError(getUserFacingError(error))
      } finally {
        setLoading(false)
      }
    })()
  }, [cardDetail.question])

  return (
    <div>
      <div className="mt-7 text-xs font-bold text-gray-400">ANSWER</div>
      {error && <ChatMessageError error={error} size="large" />}
      {loading ? (
        <Skeleton active paragraph={{ rows: 2 }} />
      ) : answer ? (
        <>
          <div className="bg-purple-25 text-semibold mt-4 flex w-full resize-none rounded-md p-3 text-lg font-medium text-purple-800">
            {answer.primary_answer}
          </div>
          <div className="flex flex-wrap items-center justify-between">
            <div className="mr-2 mt-4 whitespace-nowrap">
              <ConfidenceScore score={answer.confidence} />
            </div>
          </div>
        </>
      ) : null}
      <div className="mb-2 text-xs font-bold text-gray-400">REFERENCES</div>
      <Skeleton active loading={loading} paragraph={{ rows: 4 }}>
        <ChatReferenceList
          references={answer?.references ?? []}
          loadEagerly
          size="large"
        />
      </Skeleton>
    </div>
  )
}

export default CardDetailContent
