import type { AxiosResponse } from "axios"

import { functionsAxiosInstance } from "./api"

interface Options {
  method?: "GET" | "POST"
  authNotRequired?: boolean
  withCredentials?: boolean
}

// TODO(mgraczyk): Rename to tsBackendFunction.
const httpsCallable = <TArgs extends object | void, TResult = never>(
  name: string,
  options?: Options,
): ((data: TArgs) => Promise<AxiosResponse<TResult>>) => {
  // Reroute to cloud run backend.
  return async (data: TArgs): Promise<AxiosResponse<TResult>> => {
    return await functionsAxiosInstance.request<TResult>({
      url: `v1/${name}`,
      data,
      method: options?.method ?? "POST",
      metadata: { authNotRequired: options?.authNotRequired ?? false },
      withCredentials: options?.withCredentials ?? false,
    })
  }
}

export { httpsCallable }
