import { useState } from "react"

import { useQuiltAuthState } from "../contexts/useQuiltAuthState"
import { auth } from "../firebaseApp"
import { acceptGroupInvite } from "../groups/api"
import useErrorPopup from "./useErrorPopup"

export const useAcceptInvite = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const { handleSuccess, handleError } = useErrorPopup()
  const { forceRefreshAuthUser } = useQuiltAuthState()

  const handleAcceptInvite = async (inviteId: string) => {
    setLoading(true)
    try {
      await acceptGroupInvite({ oid: inviteId })
      await forceRefreshAuthUser(auth.currentUser)
      handleSuccess("Accepted Invite")
    } catch (error) {
      handleError({ error, prefix: "Couldn't accept invite" })
    } finally {
      setLoading(false)
    }
  }

  return { loading, handleAcceptInvite }
}
