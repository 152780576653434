import { ChromeIcon } from "lucide-react"

import { EXTENSION_ID, EXTENSION_URL } from "../constants"

interface Props {
  className?: string
}

const OpenExtensionButton: React.FC<Props> = ({ className = "" }) => {
  const handleClick = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    try {
      const result = await chrome.runtime.sendMessage<
        Record<string, string>,
        boolean
      >(EXTENSION_ID, {
        name: "OPEN_SIDEPANEL",
      })
      if (result) {
        return
      }
    } catch {
      // pass
    }
    // Failed to open, so go to the install page.
    window.open(EXTENSION_URL, "_blank", "noopener,noreferrer")
  }

  return (
    <a
      href={EXTENSION_URL}
      className={`flex items-center ${className}`}
      onClick={handleClick}
    >
      <ChromeIcon className="h-4 w-4" />
      <span className="ml-2">Open Extension</span>
    </a>
  )
}

export default OpenExtensionButton
