import Button from "antd/es/button"
import { ListTodo, Table2 } from "lucide-react"
import { useNavigate } from "react-router-dom"

import { capitalizeFirstLetter } from "../../utils"

const AssistantTile: React.FC<{
  type: "questionnaire" | "knowledge"
  title: string
  url: string
  ctaText: string
}> = ({ type, title, url, ctaText }) => {
  const capitalisedType = capitalizeFirstLetter(type)
  const Icon = type === "questionnaire" ? ListTodo : Table2
  const navigate = useNavigate()

  return (
    <div className="flex h-48 flex-1 flex-col gap-4 md:flex-row">
      <div className="flex flex-grow flex-col rounded-2xl border border-gray-100 p-6">
        <div className="flex items-center text-sm font-bold text-gray-400">
          <Icon className="mr-2" size={18} />{" "}
          <div>{capitalisedType} Assistant</div>
        </div>
        <h4 className="mb-2 flex-1 font-semibold">{title}</h4>

        <div>
          <Button
            type="primary"
            onClick={() => navigate(url)}
            className="text-primary float-right bg-purple-50 font-semibold"
          >
            {ctaText}
          </Button>
        </div>
      </div>
    </div>
  )
}
export default AssistantTile
