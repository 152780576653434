import type {
  AnalyticsEventProduct,
  AnalyticsEventSurface,
} from "../analytics/types"
import type { Answer, Question } from "../types/answerer"

export const CHAT_SESSIONS_SUBCOLLECTION = "chat_sessions"
export const MESSAGES_SUBCOLLECTION = "messages"

export const SessionSourceKindValues = {
  WEBAPP: "WEBAPP",
  EXTENSION: "EXTENSION",
  EXTENSION_SHEETS: "EXTENSION_SHEETS",
  EXTENSION_WEB_PORTAL: "EXTENSION_WEB_PORTAL",
} as const

export interface BaseChatSession {
  oid: string

  created_at: FirebaseFirestore.Timestamp
  last_message_time: FirebaseFirestore.Timestamp
  summary_text: string
  deleted: boolean
}

export interface WebAppChatSession extends BaseChatSession {
  // TODO(mgraczyk): Use surface for source.
  source: "WEBAPP"
}
export interface ExtensionChatSession extends BaseChatSession {
  source: "EXTENSION"
}
export interface ExtensionSheetsChatSession extends BaseChatSession {
  source: "EXTENSION_SHEETS"
}
export interface ExtensionWebPortalSession extends BaseChatSession {
  source: "EXTENSION_WEB_PORTAL"
  url: string
  page_title: string
}
export type ChatSession =
  | WebAppChatSession
  | ExtensionChatSession
  | ExtensionSheetsChatSession
  | ExtensionWebPortalSession

export type ChatWithSummarySessionKind =
  | "WEBAPP"
  | "EXTENSION"
  | "EXTENSION_SHEETS"

export type SessionSourceKind =
  (typeof SessionSourceKindValues)[keyof typeof SessionSourceKindValues]

export interface MessagePair {
  question: Question
  // TODO(mgraczyk): Migrate to new answer format (copy text to primary_answer).
  answer: Answer | null
  answererRequestId: string | null
}

export interface ChatMessage {
  oid: string

  content: MessagePair

  // timestamp is created_at
  timestamp: FirebaseFirestore.Timestamp
  updated_at: FirebaseFirestore.Timestamp

  // Linked knowledge item, if there is one.
  // When the message is saved, the new knowledge item is linked.
  // Subsequent updates update the same knowledge item.
  knowledge_item_oid?: string
}

export interface SaveChatMessageAnswerRequest {
  sessionOid: string
  messageOid: string
  answerUpdate: Pick<Answer, "primary_answer">
  analyticsProduct: AnalyticsEventProduct
  analyticsSurface: AnalyticsEventSurface
}

export interface SaveChatMessageAnswerResult {
  knowledgeItemOid: string
  createdKnowledgeItem: boolean
}

export interface DeleteAllChatMessagesRequest {
  sessionOid: string
}
