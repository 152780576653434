import ChatReference from "../components/ChatReference"
import { isVerbatimFromAnswerBank } from "../hooks/useEnrichedReference"
import type { AnswerReference } from "../types/answerer"

interface ChatReferenceListProps {
  references: AnswerReference[]
  isLast?: boolean
  textClassName?: string
}

const ChatReferenceList: React.FC<ChatReferenceListProps> = ({
  references,
  isLast,
  textClassName = "",
}) => {
  if (!references || references.length === 0) {
    return null
  }

  const loading = false

  return (
    <div>
      <div className="flex items-center justify-start gap-2">
        <div className={`text-[12px] text-[#AAA] ${textClassName}`}>
          References:{" "}
        </div>
        {references.map((reference, idx) => (
          <ChatReference
            key={idx}
            idx={idx}
            reference={reference}
            loading={loading}
            loadKnowledgeItemEagerly={isLast}
            isVerbatimFromAnswerBank={isVerbatimFromAnswerBank(references)}
          />
        ))}
      </div>
    </div>
  )
}

export default ChatReferenceList
