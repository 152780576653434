import Popover from "antd/es/popover"
import { AlertTriangle } from "lucide-react"

interface ConfidenceScoreProps {
  score: number
}

const Title: React.FC = () => (
  <div className="flex flex-row items-center justify-start gap-2 border-orange-100 text-orange-500">
    <AlertTriangle size={16} />
    <div className="text-sm font-bold">Low Confidence</div>
  </div>
)

const ConfidenceScore: React.FC<ConfidenceScoreProps> = ({ score }) => {
  if (score > 0.333 || score === 0) {
    return null
  }

  return (
    <Popover
      title={
        <div className="w-72 p-2">
          <Title />
          <div className="mt-2 text-sm font-normal text-gray-400">
            This answer may not be correct. Please review the provided
            references.
          </div>
        </div>
      }
    >
      <div>
        <Title />
      </div>
    </Popover>
  )
}

export default ConfidenceScore
