import Collapse from "antd/es/collapse"
import { Timestamp } from "firebase/firestore"
import { CircleCheck, CircleX } from "lucide-react"

import HelpButton from "../../components/HelpButton"
import { randomId } from "../../utils"

interface TestResult {
  isSuccessful: boolean
  instructions: string
  steps: Step[]
}

interface Substep {
  oid: string
  text: string
  imageUrl?: string
  timestamp: Timestamp
}

interface Step {
  oid: string
  title: string
  description: string
  substeps: Substep[]
}

const screenshot1 =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABAAAAABMCAYAAADzyMWHAAABs0lEQVR42u3YAQEAAAQDsEsuOj3YYqyS6QAAAACnlQAAAAAAAQAAAAAIAAAAAEAAAAAAAAIAAAAAEAAAAACAAAAAAAAEAAAAACAAAAAAQAAAAAAAAgAAAAAQAAAAAIAAAAAAAAQAAAAAIAAAAAAAAQAAAAAIAAAAABAAAAAAgAAAAAAABAAAAAAgAAAAAAABAAAAAAgAAAAAQAAAAAAAAgAAAAAEgAAAAAAAAQAAAAAIAAAAAEAAAAAAAAIAAAAAEAAAAACAAAAAAAAEAAAAACAAAAAAQAAAAAAAAgAAAAAQAAAAAIAAAAAAAAQAAAAAIAAAAAAAAQAAAAAIAAAAABAAAAAAgAAAAAAABAAAAAAgAAAAAAABAAAAAAgAAAAAQAAAAAAAAgAAAAAEgAAAAAAAAQAAAAAIAAAAAEAAAAAAAAIAAAAAEAAAAACAAAAAAAAEAAAAACAAAAAAQAAAAAAAAgAAAAAQAAAAAIAAAAAAAAQAAAAAIAAAAAAAAQAAAAAIAAAAABAAAAAAgAAAAAAABAAAAAAgAAAAAAABAAAAAAgAAAAAQAAAAAAAAgAAAAB+W4oFcbW2ajaoAAAAAElFTkSuQmCC"

const OutputPage: React.FC = () => {
  const _TEST_RESULT: TestResult = {
    isSuccessful: true,
    instructions:
      "User should be able to navigate to the home page and send a message",
    steps: [
      {
        oid: randomId(),
        title: "Open Chrome and go to the ChatGPT website",
        description:
          "We need to open an internet browser in order to visit the web page",
        substeps: [
          {
            oid: randomId(),
            text: "First, let's open Chrome",
            imageUrl: "",
            timestamp: Timestamp.now(),
          },
          {
            oid: randomId(),
            text: "",
            imageUrl: screenshot1,
            timestamp: Timestamp.now(),
          },
          {
            oid: randomId(),
            text: "Now, let's go to chat.openai.com",
            imageUrl: "",
            timestamp: Timestamp.now(),
          },
          {
            oid: randomId(),
            text: "",
            imageUrl: screenshot1,
            timestamp: Timestamp.now(),
          },
        ],
      },
      {
        oid: randomId(),
        title: "Type in a message",
        description:
          "We need to open an internet browser in order to visit the web page",
        substeps: [
          {
            oid: randomId(),
            text: "Let's find the input field",
            imageUrl: "",
            timestamp: Timestamp.now(),
          },
          {
            oid: randomId(),
            text: "",
            imageUrl: screenshot1,
            timestamp: Timestamp.now(),
          },
          {
            oid: randomId(),
            text: "Come up with a message and type it in",
            imageUrl: "",
            timestamp: Timestamp.now(),
          },
          {
            oid: randomId(),
            text: "",
            imageUrl: screenshot1,
            timestamp: Timestamp.now(),
          },
        ],
      },
      {
        oid: randomId(),
        title: "Submit the message",
        description:
          "We need to open an internet browser in order to visit the web page",
        substeps: [
          {
            oid: randomId(),
            text: "Now, let's click the send button",
            imageUrl: "",
            timestamp: Timestamp.now(),
          },
          {
            oid: randomId(),
            text: "Now, the page is loading",
            imageUrl: "",
            timestamp: Timestamp.now(),
          },
          {
            oid: randomId(),
            text: "It looks like we are waiting for a response",
            imageUrl: "",
            timestamp: Timestamp.now(),
          },
          {
            oid: randomId(),
            text: "",
            imageUrl: screenshot1,
            timestamp: Timestamp.now(),
          },
          {
            oid: randomId(),
            text: "We got a response: Got it! How can I assist you today?",
            imageUrl: "",
            timestamp: Timestamp.now(),
          },
          {
            oid: randomId(),
            text: "",
            imageUrl: screenshot1,
            timestamp: Timestamp.now(),
          },
        ],
      },
    ],
  }
  const testResult = _TEST_RESULT

  return (
    <div className="m-auto flex h-full w-[720px] flex-col items-center justify-start pt-8">
      <div className="flex w-full flex-col rounded-[20px] bg-white p-6 backdrop-blur-md">
        <div className="flex w-full flex-col items-center">
          <h2>Your Report</h2>
          {testResult.isSuccessful ? (
            <h3 className="flex text-green-500">
              <CircleCheck className="mr-1" /> Success!
            </h3>
          ) : (
            <h3 className="flex text-red-500">
              <CircleX className="mr-1" /> Failed
            </h3>
          )}
        </div>
        <h4>Your instructions</h4>
        {testResult.instructions}
        <h4>Agent&lsquo;s steps</h4>
        <Collapse
          className="w-full"
          items={testResult.steps.map((step, i) => ({
            key: step.oid,
            label: `Step ${i + 1}: ${step.title}`,
            children: (
              <div key={step.oid} className="w-full">
                {step.substeps.map((substep) => (
                  <div key={substep.oid}>
                    {substep.text && substep.text}
                    {substep.imageUrl && (
                      <img
                        className="w-[256px]"
                        width={256}
                        height={256}
                        src={substep.imageUrl}
                      />
                    )}
                  </div>
                ))}
              </div>
            ),
          }))}
        ></Collapse>
      </div>
      <div className="mt-4 flex-col items-center text-sm text-gray-600">
        <span>
          Need help?{" "}
          <HelpButton className="text-primary font-medium hover:underline">
            Contact Us
          </HelpButton>
        </span>
      </div>
    </div>
  )
}

export default OutputPage
