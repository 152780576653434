import type { QuiltPermission } from "../../auth/permissions"
import type { Group } from "../../groups/types"
import AddTagButton from "./AddTagButton"
import GroupTagsManager from "./TagsManager"

const TagsTab: React.FC<{
  activeGroup: Group
  hasPerm: (permission: QuiltPermission) => boolean
}> = ({ activeGroup, hasPerm }) => {
  return (
    <>
      <div className="flex items-center justify-between">
        <h3>Tags</h3>
        <div className="flex items-center justify-between">
          <div className="left my-4 flex">
            {hasPerm("admin") && <AddTagButton group={activeGroup} />}
          </div>
        </div>
      </div>
      <GroupTagsManager group={activeGroup} />
    </>
  )
}
export default TagsTab
