import type { SlackChannel } from "../slack/apiTypes.js"

export const INTEGRATION_METADATA_SUBCOLLECTION = "integration_metadata"

export interface SlackIntegrationMetadata {
  oid: string
  kind: "SLACK"
  channels: SlackChannel[]
  created_at: FirebaseFirestore.Timestamp
  updated_at: FirebaseFirestore.Timestamp
}
