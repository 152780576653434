import Button from "antd/es/button"
import { useState } from "react"

import { removeGroupInvite } from "../../groups/api"
import type { GroupInvite } from "../../groups/types"
import { useKeydown } from "../../hooks/events"
import useErrorPopup from "../../hooks/useErrorPopup"

interface DeleteGroupInviteConfirmationProps {
  invite: GroupInvite
  onClose: () => void
}

const DeleteGroupInviteConfirmation: React.FC<
  DeleteGroupInviteConfirmationProps
> = ({ invite, onClose }) => {
  const [isSubmitting, setSubmitting] = useState<boolean>(false)
  useKeydown(27 /*escape*/, onClose)

  const { handleSuccess, handleError } = useErrorPopup()

  const onSubmit = async () => {
    setSubmitting(true)
    try {
      await removeGroupInvite({
        oid: invite.oid,
      })
      handleSuccess("Deleted group invite")
    } catch (error) {
      handleError({ error, prefix: "Couldn't delete group invite" })
    } finally {
      setSubmitting(false)
      onClose()
    }
  }

  return (
    <div className="max-w-[500px]">
      <h4>Delete Invite</h4>
      <p>
        {"Invite to "}
        {<strong>{invite.invitee_email}</strong>}
        {` will be deleted from ${invite.group_name}. Click below to confirm.`}
      </p>
      <div className="flex gap-2">
        <Button
          type="primary"
          htmlType="submit"
          loading={isSubmitting}
          disabled={isSubmitting}
          onClick={onSubmit}
        >
          {isSubmitting ? "Removing..." : "Confirm"}
        </Button>
        <Button type="default" onClick={onClose}>
          Cancel
        </Button>
      </div>
    </div>
  )
}

export default DeleteGroupInviteConfirmation
