import { AxiosError } from "axios"
import { useEffect, useState } from "react"

import { functionsAxiosInstance } from "../../api"
import LoadingSpinner from "../../components/LoadingSpinner"
import DocNotSupported from "./DocNotSupported"
import DocPreviewError from "./DocPreviewError"

interface Props {
  pageId: string
  url: string
}

const NotionViewer: React.FC<Props> = ({ url, pageId }) => {
  const [recordMap, setRecordMap] = useState<never>()
  const [error, setError] = useState<string>()

  useEffect(() => {
    void (async () => {
      try {
        // TODO(mgraczyk): Fetch iframe content instead.
        const result = await functionsAxiosInstance.get<never>(
          `v1/notionPage/${pageId}`,
        )
        setRecordMap(result.data)
      } catch (e) {
        if (
          e instanceof AxiosError &&
          (e.response?.data as { message: string } | undefined)?.message ===
            "Notion account not linked"
        ) {
          setError(
            "You must link your notion account to view this page.\nGo to your profile to link it.",
          )
        } else if (e instanceof Error) {
          setError(e.message)
        } else {
          setError("An unknown error occurred")
        }
      }
    })()
  }, [pageId])

  if (error) {
    return <DocPreviewError message={error} />
  }
  if (!recordMap) {
    return <LoadingSpinner />
  }

  return (
    <div className="mx-auto w-full p-4">
      {recordMap && <DocNotSupported documentUrl={url} />}
    </div>
  )
}

export default NotionViewer
