import Divider from "antd/es/divider"
import Skeleton from "antd/es/skeleton"

const FeedLoadingState = () => {
  return (
    <div className="flex w-full flex-col overflow-hidden p-2">
      <Skeleton
        className="mt-4 w-full rounded-xl border border-gray-200 p-4"
        loading
        paragraph={{ rows: 2 }}
        avatar={false}
        title={false}
        active
      />
      <Divider />
      <div className="flex w-full gap-2 overflow-hidden">
        <div className="ml-8 flex grow flex-col gap-4">
          {[...Array<number>(4)].map((_, index) => (
            <Skeleton
              key={index}
              className="rounded-xl border border-gray-200 p-4"
              loading
              paragraph={{ rows: 3 }}
              avatar
              active
            />
          ))}
        </div>

        <div className="flex w-[30rem] flex-col gap-4 overflow-y-hidden">
          {[...Array<number>(4)].map((_, index) => (
            <div key={index} className="rounded-xl bg-gray-50 p-4">
              <div className="mb-2 flex items-center">
                <Skeleton.Avatar active size="small" />
                <div className="ml-2 flex-grow">
                  <Skeleton.Input className="3/4" active size="small" />
                </div>
              </div>
              <Skeleton paragraph={{ rows: 2 }} active />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default FeedLoadingState
