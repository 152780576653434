import type { GridApi } from "@ag-grid-community/core"

import { getColumnLetter } from "../../sheets/utils"

export const focusCell = (
  rowIndex: number,
  colIndex: number,
  api?: GridApi,
): void => {
  if (!api) return
  const numericRowIndex = Number(rowIndex)
  const columnLetter = getColumnLetter(Number(colIndex))

  api.setFocusedCell(numericRowIndex, columnLetter)
  api.ensureColumnVisible(columnLetter)
  api.ensureIndexVisible(numericRowIndex, "top")
}
