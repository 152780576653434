import Button from "antd/es/button"
import DatePicker from "antd/es/date-picker"
import Form from "antd/es/form"
import Select from "antd/es/select"
import Space from "antd/es/space"
import dayjs from "dayjs"
import { useCallback, useEffect, useState } from "react"

import { useKeydown } from "../hooks/events"
import useErrorPopup from "../hooks/useErrorPopup"
import { getSlackWorkspaceMembersApi } from "../slack/api"
import type { SlackChannel } from "../slack/apiTypes"
import { updateOrCreateSource } from "../sources/api"
import type { SlackSource, SlackThreadUser } from "../sources/types"
import useSlackChannels from "./useSlackChannels"

interface FormDataType {
  channelIds: string[]
  startDate: dayjs.Dayjs | undefined
  participants: string[]
}

const getInitialValues = (source: SlackSource | undefined): FormDataType => {
  if (!source) {
    return {
      channelIds: [],
      startDate: dayjs().subtract(1, "month"),
      participants: [],
    }
  }

  const channelIds = source.source_meta.channels.map((c) => c.channel_id)

  const startValueFromSource =
    source.source_meta.default_filters?.start_time?.value
  const startDate =
    startValueFromSource instanceof Date
      ? dayjs(startValueFromSource)
      : startValueFromSource
        ? dayjs(startValueFromSource.toDate())
        : undefined

  return {
    channelIds,
    startDate,
    participants: source.source_meta.default_filters?.participants?.value ?? [],
  }
}

const formDataToSourceMeta = (
  formData: FormDataType,
  channels: SlackChannel[],
): SlackSource["source_meta"] => {
  return {
    channels: formData.channelIds.map((channel_id) => ({
      channel_id,
      name: channels.find((c) => c.id === channel_id)!.name,
    })),
    default_filters: {
      start_time: formData.startDate
        ? {
            field: "thread_ts",
            operator: ">=",
            value: formData.startDate?.toDate(),
          }
        : undefined,
      participants:
        formData.participants.length > 0
          ? {
              field: "thread_user_id",
              operator: "in",
              value: formData.participants,
            }
          : undefined,
    },
  }
}

const SlackPickerForm: React.FC<{
  source: SlackSource | undefined
  onClose: () => void
  channels: SlackChannel[] | undefined
  loading: boolean
  error: Error | null
}> = ({ source, onClose, channels, loading, error }) => {
  const [form] = Form.useForm<FormDataType>()
  const { handleSuccess, handleError } = useErrorPopup()
  const initialValues = getInitialValues(source)
  const [submitting, setSubmitting] = useState<boolean>(false)

  const [participantsLoading, setParticipantsLoading] = useState(true)
  const [participantsNextCursor, setParticipantsNextCursor] = useState<
    string | undefined
  >()
  const [, setParticipantsPrevCursor] = useState<string | undefined>()
  const [currentParticipants, setCurrentParticipants] = useState<
    SlackThreadUser[]
  >([])

  const onSubmitForm = useCallback(
    async (formData: FormDataType) => {
      if (!channels) {
        return
      }
      try {
        setSubmitting(true)
        await updateOrCreateSource({
          kind: "SLACK",
          source_meta: formDataToSourceMeta(formData, channels),
        })
        handleSuccess(
          "Successfully updated data source. Your data will be synced in the background",
        )
        onClose()
      } catch (error) {
        handleError({ error, prefix: "Could not update Slack Source" })
      } finally {
        setSubmitting(false)
      }
    },
    [handleSuccess, handleError, onClose, channels],
  )

  // TODO(mgraczyk): Pagination for participants, otherwise large slacks will
  // not work.
  const onLoadNextParticipants = useCallback(async () => {
    setParticipantsLoading(true)
    try {
      const {
        data: { members, next_cursor },
      } = await getSlackWorkspaceMembersApi({
        cursor: participantsNextCursor,
      })
      setCurrentParticipants(
        members.map(({ id, name }) => ({ id, name: name ?? id })),
      )
      setParticipantsPrevCursor(participantsNextCursor)
      setParticipantsNextCursor(next_cursor)
    } catch (error) {
      handleError({ error, message: "Could not load participants" })
    } finally {
      setParticipantsLoading(false)
    }
  }, [handleError, participantsNextCursor])

  useEffect(() => {
    void onLoadNextParticipants()
    // Only load on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const channelOptions = channels?.map(({ id, name }) => ({
    label: `#${name}`,
    value: id,
  }))

  const participantsOptions = currentParticipants.map((user) => ({
    label: user.name,
    value: user.id,
  }))

  // TODO(mgraczyk): Mention that the bot needs to be added to private channels
  // to sync that channel, link to docs.

  return (
    <Form
      form={form}
      className="min-w-[500px] max-w-[700px]"
      layout="vertical"
      onFinish={onSubmitForm}
      initialValues={initialValues}
      disabled={loading || submitting}
    >
      {error ? (
        <div>Error loading data: {error.message}</div>
      ) : (
        <>
          <Form.Item
            label="Channels"
            name="channelIds"
            tooltip="Threads from these channels will be synced as data sources for question answering"
            rules={[
              { required: true, message: "Please select at least one channel" },
            ]}
          >
            <Select<string[]>
              mode="multiple"
              placeholder="Select channels"
              optionFilterProp="label"
              options={channelOptions}
              loading={loading}
            />
          </Form.Item>
          <Form.Item
            label="Start Date"
            name="startDate"
            rules={[{ required: true, message: "Please select a start date" }]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            label="Participants (optional)"
            name="participants"
            tooltip="If you select people here, we will only sync threads that included the people you selected as participants"
          >
            <Select<string[]>
              mode="multiple"
              placeholder="Select participants"
              optionFilterProp="label"
              options={participantsOptions}
              disabled={participantsLoading}
              loading={participantsLoading}
            />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit" loading={loading}>
                Confirm
              </Button>
            </Space>
          </Form.Item>
        </>
      )}
    </Form>
  )
}

const SlackPicker: React.FC<{
  source: SlackSource | undefined
  onClose: () => void
}> = ({ source, onClose }) => {
  const [channels, channelsLoading, channelsError] = useSlackChannels()

  useKeydown(
    27, // Escape
    onClose,
  )

  return (
    <SlackPickerForm
      source={source}
      onClose={onClose}
      channels={channels}
      error={channelsError ?? null}
      loading={channelsLoading}
    />
  )
}

export default SlackPicker
