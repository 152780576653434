import { useCallback, useState } from "react"
import useDrivePicker from "react-google-drive-picker"

import { sendAnalyticsEvent } from "../analytics"
import { createUserDocuments } from "../api"
import DriveLogo from "../assets/img/drive_logo.svg"
import { GOOGLE_DRIVE_DOCUMENTS_SCOPES, VOID_FUNCTION } from "../constants"
import { SupportedUserDocumentMimetypes } from "../files/mimetype"
import { firebaseApp } from "../firebaseApp"
import useErrorPopup from "../hooks/useErrorPopup"
import useGoogleAccessToken from "../hooks/useGoogleAccessToken"
import type { IndexableDocument } from "../types/common"
import {
  type MimeType,
  QUESTIONNAIRE_SUPPORTED_MIMETYPES,
  SOURCE_DOCUMENT_SUPPORTED_MIMETYPES,
} from "../types/mimetype"
import LoadingSpinner from "./LoadingSpinner"
import QuestionnaireWorkflowWizard from "./QuestionnaireWorkflowWizard"
import indexDocument from "./QuestionnaireWorkflowWizard/indexDocument"
import useIndexableGoogleDriveDocument from "./QuestionnaireWorkflowWizard/useIndexableGoogleDriveDocument"

// TODO(mgraczyk): Figure out how to import these.
type CallbackDoc = {
  downloadUrl?: string
  uploadState?: string
  description: string
  driveSuccess: boolean
  embedUrl: string
  iconUrl: string
  id: string
  isShared: boolean
  lastEditedUtc: number
  mimeType: string
  name: string
  rotation: number
  rotationDegree: number
  serviceId: string
  sizeBytes: number
  type: string
  url: string
}

type PickerCallback = {
  action: string
  docs: CallbackDoc[]
}

const isIgnoreableAuthError = (error: unknown): boolean => {
  if (!error) {
    return true
  }

  if (
    typeof error === "object" &&
    (error as Record<string, unknown>)["type"] === "popup_closed"
  ) {
    // User closed the popup, we can continue.
    return true
  }
  return false
}

interface Props {
  onClose: () => void
  isCompletedQuestionnaireActions: boolean
}

const AddDriveDocumentForm: React.FC<Props> = ({
  onClose,
  isCompletedQuestionnaireActions,
}) => {
  const { getAccessToken: getGoogleAccessToken } = useGoogleAccessToken(
    GOOGLE_DRIVE_DOCUMENTS_SCOPES,
  )
  const [loadingPicker, setLoadingPicker] = useState<boolean>(false)
  const [uploading, setUploading] = useState<boolean>(false)
  const {
    documents: wizardDocuments,
    processDocuments: processIndexableGoogleDriveDocuments,
    clearDocuments: clearWizardDocuments,
  } = useIndexableGoogleDriveDocument()
  const [openPicker] = useDrivePicker()
  const { handleSuccess, handleError } = useErrorPopup()

  const onPickFiles = useCallback(
    async (data: PickerCallback, accessToken: string) => {
      const { docs, action } = data
      if (action === "cancel" || action === "picked") {
        // Clean up the picker DOM.
        document.querySelectorAll(".picker").forEach((el) => el.remove())
      }
      if (action !== "picked") {
        return
      }

      // Convert documents into a form we understand.
      const indexDocs: IndexableDocument[] = []
      for (const doc of docs) {
        if (doc === null) {
          continue
        }
        const mimeType = doc.mimeType as MimeType
        if (!SupportedUserDocumentMimetypes.has(mimeType)) {
          console.error("Doc type not implemented", doc.mimeType, doc.name)
          handleError({
            message: `Sorry, the document you added is not supported (${doc.name}).`,
          })
          continue
        }
        indexDocs.push({
          url: "",
          external_document_id: doc.id,
          title: doc.name,
          mimetype: mimeType,
          source_kind: "GOOGLE_DRIVE" as const,
        })
      }
      const wizardDocs = indexDocs.filter((doc) =>
        QUESTIONNAIRE_SUPPORTED_MIMETYPES.includes(doc.mimetype),
      )
      const otherDocs = indexDocs.filter(
        (doc) => !QUESTIONNAIRE_SUPPORTED_MIMETYPES.includes(doc.mimetype),
      )

      // These are all done so we'll just process them now
      if (otherDocs.length > 0) {
        setUploading(true)
        try {
          await createUserDocuments(otherDocs)
        } catch (error) {
          handleError({ error, message: "Error adding document" })
        } finally {
          setUploading(false)
        }
      }
      // Add analytics events for each document
      otherDocs.forEach((doc) => {
        void sendAnalyticsEvent({
          surface: "WEB_SOURCE_DOCUMENTS",
          event_type: "CREATE_ENTITY",
          event_data: {
            entity_type: "SOURCE_DOCUMENT_GOOGLE_DRIVE",
            entity_id: doc && doc.external_document_id,
          },
        })
      })

      if (otherDocs.length === 1) {
        const doc = otherDocs[0]
        handleSuccess(
          doc.title
            ? `Added document "${doc.title.slice(0, 50)}"`
            : "Added document",
        )
      } else if (otherDocs.length > 1) {
        handleSuccess(`Added ${otherDocs.length} documents`)
      }

      if (wizardDocs.length > 0) {
        // TODO(mgraczyk): Access token!
        setUploading(true)
        try {
          await processIndexableGoogleDriveDocuments(accessToken, wizardDocs)
          handleSuccess(`Prepared ${wizardDocs.length} documents`)
        } catch (error) {
          handleError({
            error,
            message: "Error processing Google Drive documents",
          })
        } finally {
          setUploading(false)
        }
      }
      onClose()
    },
    [handleSuccess, handleError, processIndexableGoogleDriveDocuments, onClose],
  )

  const handleOpenPicker = useCallback(async () => {
    setLoadingPicker(true)
    let accessToken: string | null
    try {
      accessToken = await getGoogleAccessToken()
      // If we don't have an access token, get one.
      if (!accessToken) {
        // Cancelled
        return
      }
    } catch (error) {
      if (!isIgnoreableAuthError(error)) {
        handleError({
          error,
          message:
            "Error loading Google Drive, refresh the page or try disconnecting and reconnecting Google",
        })
      }
      return
    } finally {
      setLoadingPicker(false)
    }

    const viewMimeTypes = isCompletedQuestionnaireActions
      ? QUESTIONNAIRE_SUPPORTED_MIMETYPES
      : SOURCE_DOCUMENT_SUPPORTED_MIMETYPES

    try {
      openPicker({
        // This library uses implicit flow and never fetches a refresh token.
        // so we don't provide a clientId.
        clientId: "",
        appId: import.meta.env.VITE_PROJECT_NUMBER,
        developerKey: firebaseApp.options.apiKey as string,
        viewMimeTypes: viewMimeTypes?.join(","),
        token: accessToken,
        showUploadView: false,
        showUploadFolders: false,
        supportDrives: true,
        setIncludeFolders: true,
        setOrigin: window.location.protocol + "//" + window.location.host,
        multiselect: true,
        customViews: [],
        callbackFunction: (data) => onPickFiles(data, accessToken),
      })
    } catch (error) {
      if (
        error instanceof Error &&
        error.message === "Missing required parameter client_id."
      ) {
        // This is what happens when the user closes the window without signing
        // in.
        return
      }
      handleError({
        error,
        message:
          "Could not open Google Drive, it may be unsupported on your device.",
      })
    }
  }, [
    handleError,
    openPicker,
    onPickFiles,
    getGoogleAccessToken,
    isCompletedQuestionnaireActions,
  ])

  const disabled = uploading || loadingPicker

  return (
    <>
      {wizardDocuments.length > 0 && (
        <QuestionnaireWorkflowWizard
          onClose={() => clearWizardDocuments()}
          documents={wizardDocuments}
          handleDocumentProcessing={indexDocument}
          isCompletedQuestionnaire
        />
      )}

      <div className="flex h-64">
        <div
          onClick={disabled ? VOID_FUNCTION : () => handleOpenPicker()}
          className="add-drive-document-box m-8 flex flex-1 cursor-pointer flex-col items-center justify-center rounded-md border border-dashed border-slate-400 hover:border-purple-500"
        >
          {loadingPicker ? (
            <div>
              <LoadingSpinner />
              <p>Loading Google Drive...</p>
            </div>
          ) : uploading ? (
            <div>
              <LoadingSpinner />
              <p>Loading Files...</p>
            </div>
          ) : (
            <>
              <img
                src={DriveLogo}
                alt="Google Drive logo"
                className="add-drive-document-box-icon h-16 w-16"
              />
              <p>Click to add files from Google Drive</p>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default AddDriveDocumentForm
