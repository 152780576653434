import type { Timestamp } from "firebase/firestore"

function getNowDeltaSeconds(ts: Timestamp): number {
  const date = ts.toDate()

  // Allow dates or times to be passed
  const timeMs = typeof date === "number" ? date : date.getTime()

  // Get the amount of seconds between the given date and now
  return (timeMs - Date.now()) * (1 / 1000)
}
/**
 * Convert a date to a relative time string, such as
 * "a minute ago", "in 2 hours", "yesterday", "3 months ago", etc.
 * using Intl.RelativeTimeFormat
 *
 * https://www.builder.io/blog/relative-time
 */
export function getRelativeTimeString(
  ts: Timestamp,
  lang = navigator.language,
): string {
  // Get the amount of seconds between the given date and now
  const deltaSeconds = getNowDeltaSeconds(ts)

  // Array reprsenting one minute, hour, day, week, month, etc in seconds
  const cutoffs = [
    60,
    3600,
    86400,
    86400 * 7,
    86400 * 30,
    86400 * 365,
    Infinity,
  ]

  // Array equivalent to the above but in the string representation of the units
  const units: Intl.RelativeTimeFormatUnit[] = [
    "second",
    "minute",
    "hour",
    "day",
    "week",
    "month",
    "year",
  ]

  // Grab the ideal cutoff unit
  const unitIndex = cutoffs.findIndex(
    (cutoff) => cutoff > Math.abs(deltaSeconds),
  )

  // Get the divisor to divide from the seconds. E.g. if our unit is "day" our divisor
  // is one day in seconds, so we can divide our seconds by this to get the # of days
  const divisor = unitIndex ? cutoffs[unitIndex - 1] : 1

  // Intl.RelativeTimeFormat do its magic
  const rtf = new Intl.RelativeTimeFormat(lang, { numeric: "auto" })
  return rtf.format(Math.floor(deltaSeconds / divisor), units[unitIndex])
}

// If the time is very close, shows a relative time string.
// If the time is far, shows an absolute time string.
export function getInterpretableTimeString(
  ts: Timestamp,
  showTime: boolean = false,
  relativeCutOffSeconds: number = 86400, // 1 day
): string {
  const deltaSeconds = getNowDeltaSeconds(ts)
  if (Math.abs(deltaSeconds) < relativeCutOffSeconds) {
    return getRelativeTimeString(ts)
  }
  if (showTime) {
    return ts.toDate().toLocaleString()
  } else {
    return ts.toDate().toLocaleDateString()
  }
}
