import type { TableProps } from "antd"
import Button from "antd/es/button"
import Skeleton from "antd/es/skeleton"
import Table from "antd/es/table"
import Typography from "antd/es/typography"
import { useNavigate } from "react-router-dom"

import { useActiveUserAuthorizationFromContext } from "../contexts/ActiveUserAuthorizationContext"
import type { GroupMembership } from "../groups/types"
import useErrorPopup from "../hooks/useErrorPopup"
import { useGroupMembers } from "../hooks/useGroupMembers"
import { type PlanAndStatus, getPlanOverviewText } from "./planDetails"
import type { Subscription } from "./types"

const columns: TableProps<GroupMembership>["columns"] = [
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Role",
    dataIndex: "role",
    key: "role",
  },
]

interface PlanDetailsProps {
  activeQuiltSubscription: Subscription
  planAndStatus: PlanAndStatus
}

const getSeatsPurchasedDetail = (
  seatsPurchased: number,
  planAndStatus: PlanAndStatus,
) => {
  if (
    planAndStatus.status === "freeTrial" ||
    planAndStatus.status === "expired"
  ) {
    return ""
  }
  if (seatsPurchased === -1) {
    return "and have no limit on the number of seats."
  }
  return `out of ${seatsPurchased} available`
}

const getFreeTrialExpiryText = (subscription: Subscription) => {
  const { createdAt } = subscription
  if (!createdAt) {
    return ""
  }
  const twoWeeksEnd = new Date(createdAt.toMillis() + 14 * 24 * 60 * 60 * 1000)
  return `Your Free Trial expires on ${twoWeeksEnd.toDateString()}`
}

export const PlanDetailsComponent: React.FC<PlanDetailsProps> = ({
  activeQuiltSubscription,
  planAndStatus,
}) => {
  const { maxSeats } = activeQuiltSubscription
  const { handleError } = useErrorPopup()
  const navigate = useNavigate()

  const { activeGroupOid } = useActiveUserAuthorizationFromContext()

  const [groupMembers, loading, error] = useGroupMembers(activeGroupOid)

  if (error) {
    handleError({ error, prefix: "Unable to get your plan usage information" })
  }

  return (
    <div className="flex flex-col items-start space-y-4">
      <Skeleton loading={loading}>
        <Typography.Text>{getPlanOverviewText(planAndStatus)}</Typography.Text>
        <Typography.Text>
          You are using {groupMembers?.length} seat
          {groupMembers?.length === 1 ? "" : "s"}{" "}
          {getSeatsPurchasedDetail(maxSeats, planAndStatus)}
        </Typography.Text>
        {planAndStatus.status === "freeTrial" && (
          <Typography.Text>
            {getFreeTrialExpiryText(activeQuiltSubscription)}
          </Typography.Text>
        )}
        <Button
          type="primary"
          className="self-start"
          onClick={() => navigate("/billing/plans")}
        >
          View plans and upgrade
        </Button>
      </Skeleton>
      <div className="flex w-full flex-col space-y-2">
        <Table
          columns={columns}
          dataSource={groupMembers}
          loading={loading}
          pagination={{ pageSize: 8 }}
          size="middle"
          title={() => (
            <Typography.Title level={5}>Seats Being Used</Typography.Title>
          )}
        />
      </div>
    </div>
  )
}
