import { AxiosError } from "axios"

import { UserFacingErrorType } from "./exceptions/userFacingErrorTypes.js"

export const getPredefinedErrorSuffix = (
  type: UserFacingErrorType,
  defaultError: string,
): string | null => {
  if (defaultError) {
    return defaultError
  }

  switch (type) {
    case "MISSING_FIELD":
      return "Field cannot be empty"
    case "NOT_YET_IMPLEMENTED":
      return "Not yet implemented"
    case "BAD_ANSWER_LOCATION":
      return "Can't write and answer in that location"
    case "USER_HAS_NO_DOCUMENTS":
      return "Please upload documents so that we can use them to answer"
    case "NO_QUESTIONS_FOUND":
      return "We couldn't find any questions in the questionnaire. Check that the questions have not already been answered, and that the question location was set correctly"
    case "UNKNOWN_SHEET_LAYOUT":
      return "Could not determine the sheet layout"
    case "USER_DISABLED":
      return "You must upgrade your account."
    case "PERMISSION_DENIED":
    case "UNAUTHORIZED":
      return "You do not have the required permissions for this action"
    case "NO_TEXT_FOUND":
      return "We couldn't find any relevant text in the document. If this is a questionnaire, check that the selected Question and Answer columns contain text."
    case "NO_SLACKBOT_CONFIGURATION_FOUND":
      return "The Slack Knowledge Assistant is not enabled for this channel."
    case "ALREADY_EXISTS":
      return "It already exists."
    default:
      return ""
  }
}

export const getUserFacingErrorType = (error: unknown): UserFacingErrorType => {
  if (!error) {
    return "UNKNOWN"
  }
  if (error instanceof AxiosError) {
    const maybeData = error.response?.data as
      | {
          error?: string
        }
      | undefined
    if (maybeData) {
      const { error: err } = maybeData
      if (err && err in UserFacingErrorType) {
        return err as UserFacingErrorType
      }
    }
  } else if (typeof error === "string" && error in UserFacingErrorType) {
    return error as UserFacingErrorType
  } else if (typeof error === "object" && "userFacingErrorType" in error) {
    return error.userFacingErrorType as UserFacingErrorType
  }

  return "UNKNOWN"
}
