import { Tag } from "antd"
import Button from "antd/es/button"
import Popover from "antd/es/popover"
import Select from "antd/es/select"
import Space from "antd/es/space"
import { FilterIcon } from "lucide-react"
import { useMemo, useState } from "react"

import type {
  FeedFilterState,
  UseFeedFiltersResult,
} from "../../hooks/useQuestionnaireFeedFilters"
import { uniqueByField } from "../../utils"
import type { AnswerWithDiscussion } from "./types"

interface FeedFilterPopoverProps {
  answers: AnswerWithDiscussion[]
  filterState: FeedFilterState
  setFilterValue: UseFeedFiltersResult["setFilterValue"]
  resetFilters: UseFeedFiltersResult["resetFilters"]
  activeFilterCount: number
}

const FeedFilterPopover: React.FC<FeedFilterPopoverProps> = ({
  filterState,
  setFilterValue,
  resetFilters,
  answers,
  activeFilterCount,
}) => {
  const [isVisible, setIsVisible] = useState(false)

  const handleFilterChange =
    (key: keyof FeedFilterState) =>
    (value: string[] | string | null | undefined) => {
      setFilterValue(key, value)
    }
  const { owners, assignees } = useMemo(
    () => ({
      owners: uniqueByField(
        answers.map((item) => item.last_assigned_to).filter((item) => !!item),
        "uid",
      ),
      assignees: uniqueByField(
        answers
          .flatMap((item) => item.discussions ?? [])
          .filter((discussion) => discussion.assignment)
          .map((discussion) => discussion.assignment!),
        "uid",
      ),
    }),
    [answers],
  )

  const filterContent = (
    <Space direction="vertical" className="w-60">
      {filterState.oid.length > 0 && (
        <Tag closable onClose={() => setFilterValue("oid", [])}>
          {filterState.oid.length} answer{filterState.oid.length > 1 ? "s" : ""}
        </Tag>
      )}

      <div className="mt-2 font-semibold">Approval Status</div>
      <Select
        rootClassName="w-full"
        placeholder="Select approval status"
        value={filterState.approved}
        onChange={handleFilterChange("approved")}
        allowClear
        options={[
          { value: "1", label: "Approved" },
          { value: "0", label: "Not Approved" },
        ]}
      />

      <div className="mt-2 font-semibold">Assigned To</div>
      <Select
        mode="multiple"
        rootClassName="w-full"
        placeholder="Filter by assignee"
        value={filterState.discussionAssignedTo}
        onChange={handleFilterChange("discussionAssignedTo")}
        options={assignees.map((assignee) => ({
          value: assignee.uid,
          label: assignee.email,
        }))}
      />

      <div className="mt-2 font-semibold">Quilt Answered</div>
      <Select
        rootClassName="w-full"
        placeholder="Did Quilt answer?"
        value={filterState.confidence}
        onChange={handleFilterChange("confidence")}
        allowClear
        options={[
          { value: "1", label: "Quilt Answered" },
          { value: "0", label: "Quilt Did Not Answer" },
        ]}
      />

      <div className="mt-2 font-semibold">Owner</div>
      <Select
        mode="multiple"
        rootClassName="w-full"
        placeholder="Filter by owner"
        value={filterState.lastAssignedTo}
        onChange={handleFilterChange("lastAssignedTo")}
        options={owners.map((assignee) => ({
          value: assignee.uid,
          label: assignee.email,
        }))}
      />

      <Button
        className="mt-4"
        onClick={resetFilters}
        disabled={activeFilterCount === 0}
      >
        Clear All Filters
      </Button>
    </Space>
  )

  return (
    <Popover
      content={filterContent}
      title="Filter Options"
      trigger="click"
      open={isVisible}
      onOpenChange={setIsVisible}
    >
      <Button
        icon={<FilterIcon size="1em" />}
        type={activeFilterCount > 0 ? "primary" : "default"}
      >
        {activeFilterCount > 0
          ? `${activeFilterCount} ${activeFilterCount === 1 ? "filter" : "filters"}`
          : "Filter"}
      </Button>
    </Popover>
  )
}

export default FeedFilterPopover
