import Button from "antd/es/button"
import Popover from "antd/es/popover"
import Select from "antd/es/select"
import Skeleton from "antd/es/skeleton"
import { VoteIcon } from "lucide-react"
import { useCallback, useState } from "react"

import { useActiveUserAuthorizationFromContext } from "../contexts/ActiveUserAuthorizationContext"
import { useKeydown } from "../hooks/events"
import useErrorPopup from "../hooks/useErrorPopup"
import { useGroupMembers } from "../hooks/useGroupMembers"
import { assignKnowledgeItems, unassignKnowledgeItems } from "../knowledge/api"
import type { UserDocumentActor } from "../types/userDocument"

interface Props {
  onClose: () => void
  knowledgeOid: string
  assignedTo?: UserDocumentActor | null
  isUserDocument: boolean
  isSubmitting: boolean
  setSubmitting: (value: boolean) => void
}

const AssignKnowledgeItemForm: React.FC<Props> = ({
  onClose,
  knowledgeOid,
  assignedTo,
  isUserDocument,
  isSubmitting,
  setSubmitting,
}) => {
  const [selectedMember, setSelectedMember] = useState<string | undefined>(
    assignedTo?.uid ?? "",
  )
  const { activeGroupOid } = useActiveUserAuthorizationFromContext()
  const [members, loading] = useGroupMembers(activeGroupOid)
  const { handleSuccess, handleError } = useErrorPopup()

  useKeydown(27 /*escape*/, onClose)

  const member = members?.find((m) => m.uid === selectedMember)

  const unAssign = useCallback(async () => {
    if (!assignedTo) {
      return
    }

    setSubmitting(true)
    try {
      await unassignKnowledgeItems({
        requests: [
          {
            oid: knowledgeOid,
            kind: "UNASSIGN",
          },
        ],
      })
      handleSuccess(
        `Unassigned the ${isUserDocument ? "user document" : "knowledge item"}`,
      )
      onClose()
      setSelectedMember(undefined)
    } catch (error) {
      handleError({
        error,
        prefix: `Couldn't unassign ${
          isUserDocument ? "user document" : "knowledge item"
        }`,
      })
    } finally {
      setSubmitting(false)
    }
  }, [
    assignedTo,
    handleError,
    setSelectedMember,
    handleSuccess,
    onClose,
    knowledgeOid,
    isUserDocument,
    setSubmitting,
  ])

  const onSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      e.stopPropagation()
      if (!selectedMember) {
        return
      }

      if (!member) {
        return // users can only pick from the list, so this should never happen
      }

      setSubmitting(true)
      try {
        await assignKnowledgeItems({
          requests: [
            {
              oid: knowledgeOid,
              kind: "ASSIGN",
              assign_to: {
                uid: member.uid,
                email: member.email,
              },
            },
          ],
        })
        handleSuccess(
          `Assigned the ${isUserDocument ? "user document" : "knowledge item"}`,
        )
        onClose()
      } catch (error) {
        handleError({
          error,
          prefix: `Couldn't assign  ${isUserDocument ? "user document" : "knowledge item"}`,
        })
      } finally {
        setSubmitting(false)
      }
    },
    [
      handleError,
      handleSuccess,
      selectedMember,
      onClose,
      knowledgeOid,
      member,
      isUserDocument,
      setSubmitting,
    ],
  )

  if (loading) {
    return <Skeleton.Input active className="block h-4 w-full" />
  }

  return (
    <form className="flex w-[400px] text-sm" action="" onSubmit={onSubmit}>
      <Select
        placeholder="Select the assignee..."
        value={selectedMember}
        onChange={setSelectedMember}
        className="w-full"
      >
        {members?.map((member) => (
          <Select.Option key={member.uid} value={member.uid}>
            {member.email}
          </Select.Option>
        ))}
      </Select>
      {assignedTo && (
        <Button
          disabled={isSubmitting}
          className="ml-2"
          type="default"
          onClick={unAssign}
        >
          Remove
        </Button>
      )}
      <Button
        disabled={
          isSubmitting || !selectedMember || selectedMember === assignedTo?.uid
        }
        className="ml-2"
        type="primary"
        htmlType="submit"
      >
        {isSubmitting ? "Assigning..." : "Assign"}
      </Button>
    </form>
  )
}
const AssignKnowledgeItemButton: React.FC<{
  knowledgeOid: string
  assignedTo?: UserDocumentActor | null
  isUserDocument: boolean
}> = ({ knowledgeOid, assignedTo, isUserDocument }) => {
  const [open, setOpen] = useState(false)
  const hide = useCallback(() => {
    setOpen(false)
  }, [setOpen])
  const [isSubmitting, setSubmitting] = useState<boolean>(false)

  return (
    <Popover
      content={
        <AssignKnowledgeItemForm
          knowledgeOid={knowledgeOid}
          onClose={hide}
          assignedTo={assignedTo}
          isUserDocument={isUserDocument}
          isSubmitting={isSubmitting}
          setSubmitting={setSubmitting}
        />
      }
      trigger="click"
      placement="bottomLeft"
      open={open}
      onOpenChange={setOpen}
    >
      <Button
        type="primary"
        icon={<VoteIcon />}
        title="Assign"
        className="flex items-center justify-center"
        disabled={isSubmitting}
        loading={isSubmitting}
      >
        <span className="font-bold">
          {isSubmitting ? "Assigning..." : assignedTo ? "Assigned" : "Assign"}
        </span>
      </Button>
    </Popover>
  )
}

export default AssignKnowledgeItemButton
