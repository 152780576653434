import "./ProgressSpinner.css"

interface Props {
  className?: string
  on: boolean
  seconds: number
}

const ProgressSpinner: React.FC<Props> = ({ className = "", on, seconds }) => {
  const style = on
    ? {
        animation: `quilt-progress-spinner-animation ${seconds}s linear`,
      }
    : { display: "none" }
  return (
    <div className={className}>
      <svg className="h-full w-full" viewBox="0 0 100 100">
        <circle
          className="text-gray-200"
          strokeWidth="10"
          stroke="currentColor"
          fill="transparent"
          r="45"
          cx="50"
          cy="50"
        />
        <circle
          className="origin-center text-purple-500"
          strokeWidth="10"
          stroke="currentColor"
          fill="transparent"
          r="45"
          cx="50"
          cy="50"
          style={style}
        />
      </svg>
    </div>
  )
}

export default ProgressSpinner
