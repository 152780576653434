import { ref as storageRef, uploadBytes } from "firebase/storage"

import { auth, firebaseStorage } from "../firebaseApp"
import type { MimeType } from "../types/mimetype"
import { randomId } from "../utils"

const uploadUserFile = async (
  file: ArrayBuffer | Blob | Uint8Array,
  targetFolder: "source_document_uploads" | "answer_questionnaire_job_uploads",
  mimetype?: MimeType,
) => {
  const user = auth.currentUser
  if (!user) {
    throw new Error("Not logged in")
  }

  // Generate a random upload id.
  const uploadId = randomId()
  const bucketPath = `/${targetFolder}/${user.uid}/${uploadId}`
  const objectRef = storageRef(firebaseStorage, bucketPath)

  // TODO(mgraczyk): Use hook in parent to monitor upload progress.
  const metadata = {
    contentType: mimetype,
  }

  const uploadRes = await uploadBytes(objectRef, file, metadata)
  console.debug("Upload result", uploadRes)
  return { id: uploadId, uri: objectRef.toString() }
}

export default uploadUserFile
