import Tag from "antd/es/tag"
import { useContext } from "react"

import WizardContext from "./context"
import { isFileDocument, isGoogleDriveDocument } from "./state"
import { isValidSheet } from "./utils"

const ProgressStep: React.FC<{
  title: string
  completed: boolean
  currentIndex: number
  index: number
  onClick: () => void
}> = ({ title, currentIndex, completed, index, onClick }) => {
  const active = currentIndex === index
  return (
    <div
      onClick={onClick}
      className={`mb-2 flex grow basis-0 cursor-pointer items-start gap-2 border-t-4 py-2 ${
        active
          ? "border-gray-800"
          : completed
            ? "border-teal-500"
            : "border-gray-100"
      }`}
    >
      <div>
        <div
          className={`flex size-8 items-center justify-center rounded-full font-bold ${
            active
              ? "bg-gray-800 text-white"
              : completed
                ? "bg-teal-500 text-white"
                : "border border-gray-100 bg-white text-gray-500"
          }`}
        >
          {index + 1}
        </div>
      </div>
      <div
        className={`flex gap-2 font-bold ${
          active ? "text-gray-800" : "text-gray-500"
        }`}
      >
        {title}
      </div>
      <div>
        {!completed && index < currentIndex ? (
          <Tag className="border-0 bg-gray-50 text-[12px] font-bold text-gray-500">
            Skipped
          </Tag>
        ) : completed ? (
          <Tag className="border-0 bg-teal-100 text-[12px] font-bold text-teal-500">
            Completed
          </Tag>
        ) : null}
      </div>
    </div>
  )
}

const Progress: React.FC = () => {
  const { state, dispatch } = useContext(WizardContext)
  const { documentIndex, documents } = state
  const document = documents[documentIndex]
  const { sheets, sheetIndex } = document

  const title = (() =>
    (isFileDocument(document) && document.fileName) ||
    (isGoogleDriveDocument(document) && document.title))()

  return (
    <div>
      <>
        <div className="mb-4 flex items-baseline gap-2 text-lg font-bold text-gray-800">
          <div>{title}</div>
          {documents.length > 1 && (
            <div>
              {documentIndex + 1}/{documents.length}
            </div>
          )}
        </div>
      </>
      {sheets.length > 1 && (
        <div
          role="progressbar"
          aria-label="Sheet Progress"
          className="flex items-stretch gap-2 overflow-x-auto"
        >
          {sheets.map((sheet, index) => (
            <ProgressStep
              key={sheet.sheetName}
              title={sheet.sheetName}
              currentIndex={sheetIndex}
              completed={isValidSheet(sheet)}
              onClick={() => {
                dispatch({
                  type: "SHEET_INDEX",
                  documentIndex,
                  payload: index,
                })
              }}
              index={index}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default Progress
