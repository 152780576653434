import { pyBackendAxiosInstance } from "../api"
import type { Source, SourceUpdateOrCreateRequest } from "./types"

export const updateOrCreateSource = async (
  req: SourceUpdateOrCreateRequest,
): Promise<Source> =>
  await pyBackendAxiosInstance.post("/sources/update_or_create", req)

export const removeSource = async (sourceOid: string): Promise<void> =>
  await pyBackendAxiosInstance.post(`/sources/${sourceOid}/remove`)
