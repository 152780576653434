import type { TabsProps } from "antd"
import Button from "antd/es/button"
import Drawer from "antd/es/drawer"
import Skeleton from "antd/es/skeleton"
import Tabs from "antd/es/tabs"
import type { FirestoreError } from "firebase/firestore"
import {
  collection,
  doc,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore"
import { ListIcon } from "lucide-react"
import { useCallback, useState } from "react"
import {
  useCollectionData,
  useDocumentData,
  useDocumentDataOnce,
} from "react-firebase-hooks/firestore"
import { useNavigate, useParams } from "react-router"
import { Link, useSearchParams } from "react-router-dom"

import Discussions from "../../components/Discussions/Discussions"
import LoadingSpinner from "../../components/LoadingSpinner"
import NotFoundPage from "../../components/NotFoundPage"
import { getConfirmation } from "../../components/confirmAndExecute"
import { useActiveUserAuthorizationFromContext } from "../../contexts/ActiveUserAuthorizationContext"
import { makeConverter } from "../../dbUtils"
import {
  DISCUSSION_COLLECTION,
  type KnowledgeDiscussion,
} from "../../discussions/types"
import { db } from "../../firebaseApp"
import { useGroupMembers } from "../../hooks/useGroupMembers"
import { useGroupTags } from "../../hooks/useGroupTags"
import KnowledgeItemHistoryCard from "../../knowledge/KnowledgeItemHistoryCard"
import { deleteUserDocumentsApi } from "../../knowledge/api"
import { knowledgeItemHistoryConverter } from "../../knowledge/db"
import {
  GROUPS_COLLECTION,
  PIECES_SUBCOLLECTION,
  USER_DOCUMENTS_COLLECTION,
  USER_DOCUMENTS_HISTORY_SUBCOLLECTION,
} from "../../types/common"
import type { UserDocumentPiece } from "../../types/userDocument"
import type { UserDocument } from "../../types/userDocument"
import { sleep } from "../../utils"
import { shorten } from "../../utils"
import Header from "./../../components/Header"
import SourceDocumentViewer from "./SourceDocumentViewer"
import UserDocumentForm from "./UserDocumentForm"

const LoadingComponent: React.FC<{
  children?: JSX.Element
  loading: boolean
}> = ({ children, loading }) => (
  <Skeleton
    active
    className="mt-4 w-36"
    title={false}
    paragraph={{ className: "m-0", rows: 1 }}
    loading={loading}
  >
    {children}
  </Skeleton>
)

const UserDocumentReference: React.FC<{
  piece: UserDocumentPiece | undefined
  pieceLoading: boolean
  pieceError: FirestoreError | undefined
}> = ({ piece, pieceLoading, pieceError }) => {
  if (pieceLoading) {
    return <Skeleton loading active title={true} />
  }

  if (pieceError || !piece) {
    return (
      <p className="font-normal text-gray-600">
        Failed to load snippet. The reference may have been deleted
      </p>
    )
  }

  if (piece.kind === "RAW_TEXT") {
    return <p className="font-normal italic text-gray-600">{piece.content}</p>
  } else if (piece.kind === "ANSWERED_QUESTION") {
    return (
      <div>
        <h4 className="m-0 font-semibold text-gray-600">Question:</h4>
        <p className="m-0 font-normal italic text-gray-600">
          {piece.primary_question}
        </p>
        {piece.secondary_question && (
          <>
            <h4 className="m-0 font-semibold text-gray-600">
              Question Details:
            </h4>
            <p className="m-0 font-normal italic text-gray-600">
              {piece.secondary_question}
            </p>
          </>
        )}
        <h4 className="m-0 font-semibold text-gray-600">Answer:</h4>
        <p className="m-0 font-normal italic text-gray-600">
          {piece.primary_answer}
        </p>
        {piece.secondary_answer && (
          <>
            <h4 className="m-0 font-semibold text-gray-600">Answer Details:</h4>
            <p className="m-0 font-normal italic text-gray-600">
              {piece.secondary_answer}
            </p>
          </>
        )}
      </div>
    )
  } else {
    return (
      <p className="font-normal text-gray-600">Could not display reference.</p>
    )
  }
}

const UserDocumentPage: React.FC = () => {
  const { docOid = "" } = useParams<{ docOid: string }>()
  const docRef = doc(db, USER_DOCUMENTS_COLLECTION, docOid).withConverter(
    makeConverter<UserDocument>(),
  )
  const [searchParams] = useSearchParams()
  const discussionOid = searchParams.get("discussion_oid")
  const [sidebarOpen, setSidebarOpen] = useState(!!discussionOid)
  const [userDoc, loading, error] = useDocumentData(docRef)
  const { activeGroupOid } = useActiveUserAuthorizationFromContext()
  const [groupMembers, groupMembersLoading] = useGroupMembers(activeGroupOid)
  const [tags, tagsLoading] = useGroupTags(activeGroupOid)
  const [removing, setRemoving] = useState<boolean>(false)
  const [removed, setRemoved] = useState<boolean>(false)
  const navigate = useNavigate()

  const pieceOid = searchParams.get("pieceOid")

  const pieceRef = pieceOid
    ? doc(
        db,
        USER_DOCUMENTS_COLLECTION,
        docOid,
        PIECES_SUBCOLLECTION,
        pieceOid,
      ).withConverter(makeConverter<UserDocumentPiece>())
    : null

  const [piece, pieceLoading, pieceError] = useDocumentDataOnce(pieceRef)

  const discussionsColRef = collection(
    db,
    GROUPS_COLLECTION,
    activeGroupOid,
    DISCUSSION_COLLECTION,
  ).withConverter(makeConverter<KnowledgeDiscussion>())

  const discussionsQuery = query(
    discussionsColRef,
    where("user_document_oid", "==", docOid),
    where("deleted", "==", false),
    orderBy("created_at", "desc"),
    limit(100),
  )

  const [discussions, discussionsLoading, discussionsError] =
    useCollectionData<KnowledgeDiscussion>(discussionsQuery)

  const historyColRef = collection(
    db,
    USER_DOCUMENTS_COLLECTION,
    docOid,
    USER_DOCUMENTS_HISTORY_SUBCOLLECTION,
  ).withConverter(knowledgeItemHistoryConverter)

  const [history, historyLoading] = useCollectionData(
    query(
      historyColRef,
      where("group_oid", "==", activeGroupOid),
      orderBy("updated_at", "desc"),
    ),
  )

  const removeUserDocument = useCallback(async () => {
    if (
      !(await getConfirmation(
        "This will permanently remove the document from Quilt.",
      ))
    ) {
      return
    }

    setRemoving(true)
    try {
      await deleteUserDocumentsApi({ oids: [docOid] })
      setRemoved(true)
      await sleep(3000)
      navigate("/source-documents")
    } finally {
      setRemoving(false)
    }
  }, [docOid, navigate])

  // TODO(mgraczyk): Combine drawer logic with KnowledgeItemPage and KnowledgeItemForm.
  const drawerTabs: TabsProps["items"] = [
    {
      key: "discussions",
      label: "Discussions",
      children: (
        <LoadingComponent loading={discussionsLoading}>
          <Discussions
            group_oid={activeGroupOid}
            discussions={discussions}
            user_document_oid={docOid}
            kind="USER_DOCUMENT"
            discussionsError={discussionsError}
          />
        </LoadingComponent>
      ),
    },
  ]

  if (history?.length) {
    drawerTabs.push({
      key: "history",
      label: "History",
      children: (
        <LoadingComponent loading={historyLoading}>
          <div className="border-t border-gray-100">
            {history.map((h, index) => (
              <KnowledgeItemHistoryCard
                key={index}
                item={h}
                groupMembers={groupMembers}
                tags={tags}
                tagsLoading={tagsLoading}
                groupMembersLoading={groupMembersLoading}
              />
            ))}
          </div>
        </LoadingComponent>
      ),
    })
  }

  if (!docOid) {
    return <NotFoundPage />
  }

  let body: React.ReactNode
  if (removed) {
    body = (
      <div>
        <div className="m-4">Document marked for removal</div>
        <div>
          Redirecting to{" "}
          <Link to="/source-documents">your Source Documents</Link> in a few
          seconds...
        </div>
      </div>
    )
  } else if (removing) {
    body = (
      <div>
        <div className="m-4">Removing document...</div>
      </div>
    )
  } else if (loading) {
    body = <LoadingSpinner />
  } else if (!userDoc) {
    if (error) {
      if (error.code === "permission-denied") {
        return <NotFoundPage />
      }
      return (
        <span className="m-4">
          Failed to load document: {JSON.stringify(error)}
        </span>
      )
    } else {
      return <NotFoundPage />
    }
  } else {
    body = (
      <div className="m-8 flex flex-grow flex-row gap-8 overflow-hidden">
        <div className="w-72">
          {pieceOid && (
            <>
              <h3 className="font-bold text-gray-800">Reference</h3>
              <UserDocumentReference
                piece={piece}
                pieceLoading={pieceLoading}
                pieceError={pieceError}
              />
            </>
          )}
          <h3 className="font-bold text-gray-800">File Details</h3>
          <UserDocumentForm doc={userDoc} />
        </div>
        <div className="bg-gray-25 flex flex-auto grow flex-col justify-center rounded-lg">
          <SourceDocumentViewer userDocument={userDoc} piece={piece} />
        </div>
        <Drawer
          open={sidebarOpen}
          onClose={() => setSidebarOpen(false)}
          title="Options"
        >
          <Tabs
            rootClassName="quilt-questionnaire-sidebar"
            items={drawerTabs}
          />
        </Drawer>
      </div>
    )
  }

  const titleText = loading
    ? "Loading..."
    : userDoc && userDoc.title
      ? shorten(userDoc.title, 50)
      : "Source Document Details"

  return (
    <>
      <Header
        title={titleText}
        breadcrumbs={[
          {
            title: "Source Documents",
            href: "/source-documents",
          },
          {
            title: `Document ${docOid}`,
            href: `/source-documents/${docOid}`,
          },
        ]}
      >
        <div className="flex items-center">
          <Button onClick={removeUserDocument} className="font-bold" danger>
            Remove
          </Button>
          <Button
            icon={<ListIcon />}
            className="ml-4"
            onClick={() => setSidebarOpen(true)}
          />
        </div>
      </Header>
      {body}
    </>
  )
}

export default UserDocumentPage
