import { useLocalStorage } from "@uidotdev/usehooks"
import {
  Timestamp,
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore"
import { createContext, useCallback, useEffect, useMemo, useState } from "react"

import {
  CHAT_SESSIONS_SUBCOLLECTION,
  type ChatSession,
  type ChatWithSummarySessionKind,
  type ExtensionWebPortalSession,
} from "../../chat/types"
import { useActiveUserAuthorizationFromContext } from "../../contexts/ActiveUserAuthorizationContext"
import { makeConverter } from "../../dbUtils"
import { db } from "../../firebaseApp"
import {
  GROUPS_COLLECTION,
  GROUP_MEMBERSHIP_SUBCOLLECTION,
} from "../../types/common"
import { randomId } from "../../utils"
import useErrorPopup from "../useErrorPopup"
import {
  createNewChatSession,
  deleteSession as deleteSessionApi,
} from "./utils"

export interface ChatSessionsContextType {
  sessions: ChatSession[]
  selectedSession: ChatSession | null
  selectedSessionId: string
  setSelectedSessionId: (sessionId: string) => void
  selectNewSession: () => void
  createSession: (sessionId: string, summaryText: string) => Promise<void>
  deleteSession: (sessionOid: string) => Promise<void>
  sessionsLoading: boolean
}

const throwError = () => {
  throw new Error("Context not initialized")
}

const defaultContext: ChatSessionsContextType = {
  sessions: [],
  selectedSession: null,
  selectedSessionId: "",
  setSelectedSessionId: throwError,
  selectNewSession: throwError,
  createSession: throwError,
  deleteSession: throwError,
  sessionsLoading: false,
}

// Create a stable global value so we use the same default on rerender.
// TODO(mgraczyk): Remove and replace with a conspicuous value.
const INITIAL_RANDOM_SESSION_ID = randomId()

export const ChatSessionsContext =
  createContext<ChatSessionsContextType>(defaultContext)

export const useSessionProviderImpl = (
  sourceKind: ChatWithSummarySessionKind,
  selectedSessionId: string,
  setSelectedSessionId: (sessionId: string) => void,
): ChatSessionsContextType => {
  const { handleSuccess, handleError } = useErrorPopup()
  const { authUser, activeGroupOid } = useActiveUserAuthorizationFromContext()
  const [sessions, setSessions] = useState<ChatSession[]>([])
  const [sessionsLoading, setSessionsLoading] = useState<boolean>(true)

  // Subscribe to sessions.
  useEffect(() => {
    const colRef = collection(
      db,
      GROUPS_COLLECTION,
      activeGroupOid,
      GROUP_MEMBERSHIP_SUBCOLLECTION,
      authUser.uid,
      CHAT_SESSIONS_SUBCOLLECTION,
    )
    const q = query(
      colRef,
      where("source", "==", sourceKind),
      orderBy("created_at", "desc"),
      limit(100),
    ).withConverter(makeConverter<ChatSession>())

    setSessionsLoading(true)
    return onSnapshot(
      q,
      (querySnapshot) => {
        const newSessions = querySnapshot.docs
          .map((doc) => ({
            ...doc.data(),
            oid: doc.id,
          }))
          .filter((session) => !session.deleted)
          .sort((a, b) => b.created_at._compareTo(a.created_at))

        setSessions(newSessions)
        setSessionsLoading(false)
      },
      (error) => {
        console.error(error)
        // TODO(mgraczyk): Show session load error.
        setSessionsLoading(false)
      },
    )
  }, [authUser.uid, activeGroupOid, sourceKind])

  const selectNewSession = useCallback(() => {
    setSelectedSessionId(randomId())
  }, [setSelectedSessionId])

  const createSession = useCallback(
    async (sessionId: string, summaryText: string) => {
      await createNewChatSession(activeGroupOid, authUser.uid, sessionId, {
        source: sourceKind,
        summary_text: summaryText,
      })
      // Trigger write to storage or navigation.
      setSelectedSessionId(sessionId)
    },
    [activeGroupOid, authUser.uid, sourceKind, setSelectedSessionId],
  )

  const deleteSession = useCallback(
    async (deletedSessionId: string) => {
      try {
        await deleteSessionApi(activeGroupOid, authUser.uid, deletedSessionId)
        setSelectedSessionId(randomId())
        handleSuccess("Session deleted")
      } catch (error) {
        handleError({ error, prefix: "Couldn't delete session" })
      }
    },
    // setSelectedSessionId is stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeGroupOid, authUser.uid, handleSuccess, handleError],
  )

  const selectedSession =
    sessions.find((s) => s.oid === selectedSessionId) ?? null

  return useMemo(
    () => ({
      sessions,
      selectedSession,
      selectedSessionId,
      setSelectedSessionId,
      selectNewSession,
      createSession,
      deleteSession,
      sessionsLoading,
    }),
    [
      sessions,
      selectedSession,
      selectedSessionId,
      setSelectedSessionId,
      selectNewSession,
      createSession,
      deleteSession,
      sessionsLoading,
    ],
  )
}

export const LocalStorageChatSessionsProvider: React.FC<{
  sourceKind: ChatWithSummarySessionKind
  children: React.ReactNode
}> = ({ sourceKind, children }) => {
  const [selectedSessionId, setSelectedSessionId] = useLocalStorage(
    `quilt__chatSessionId__${sourceKind}`,
    INITIAL_RANDOM_SESSION_ID,
  )

  const value = useSessionProviderImpl(
    sourceKind,
    selectedSessionId,
    setSelectedSessionId,
  )

  return (
    <ChatSessionsContext.Provider value={value}>
      {children}
    </ChatSessionsContext.Provider>
  )
}

// A chat sessions provider that uses a fixed session ID instead of fetching
// sessions from the database.
export const ChatStaticSessionsProvider: React.FC<{
  sourceKind: ChatWithSummarySessionKind
  sessionId: string
  children: React.ReactNode
}> = ({ sourceKind, sessionId, children }) => {
  const selectedSessionId = sessionId
  const sessions = useMemo(
    () => [
      {
        oid: selectedSessionId,
        created_at: Timestamp.now() as FirebaseFirestore.Timestamp,
        last_message_time: Timestamp.now() as FirebaseFirestore.Timestamp,
        deleted: false,
        source: sourceKind,
        summary_text: "",
      },
    ],
    [selectedSessionId, sourceKind],
  )

  const value = useMemo(
    () => ({
      ...defaultContext,
      sessions,
      selectedSession: sessions[0],
      selectedSessionId,
      sessionsLoading: false,
    }),
    [sessions, selectedSessionId],
  )

  return (
    <ChatSessionsContext.Provider value={value}>
      {children}
    </ChatSessionsContext.Provider>
  )
}

// A chat sessions provider that uses a fixed session ID instead of fetching
// sessions from the database.
export const WebPortalStaticSessionsProvider: React.FC<{
  sessionId: string
  children: React.ReactNode
}> = ({ sessionId, children }) => {
  const source = "EXTENSION_WEB_PORTAL"
  const selectedSessionId = sessionId
  const sessions: ExtensionWebPortalSession[] = useMemo(
    () => [
      {
        oid: selectedSessionId,
        created_at: Timestamp.now() as FirebaseFirestore.Timestamp,
        last_message_time: Timestamp.now() as FirebaseFirestore.Timestamp,
        deleted: false,
        source,
        summary_text: "",
        url: "",
        page_title: "",
      },
    ],
    [selectedSessionId],
  )

  const value = useMemo(
    () => ({
      ...defaultContext,
      sessions,
      selectedSession: sessions[0],
      selectedSessionId,
      sessionsLoading: false,
      createSession: throwError,
    }),
    [sessions, selectedSessionId],
  )

  return (
    <ChatSessionsContext.Provider value={value}>
      {children}
    </ChatSessionsContext.Provider>
  )
}
