import Button from "antd/es/button"
import { useState } from "react"

import { adminRemoveGroupTag } from "../../groups/api"
import type { Group } from "../../groups/types"
import { useKeydown } from "../../hooks/events"
import useErrorPopup from "../../hooks/useErrorPopup"
import type { GroupTag } from "../../tags/types"

interface DeleteTagConfirmationProps {
  group: Group
  tag: GroupTag
  onClose: () => void
}

const DeleteTagConfirmation: React.FC<DeleteTagConfirmationProps> = ({
  group,
  tag,
  onClose,
}) => {
  const [isSubmitting, setSubmitting] = useState<boolean>(false)
  useKeydown(27 /*escape*/, onClose)

  const { handleSuccess, handleError } = useErrorPopup()

  const onSubmit = async () => {
    setSubmitting(true)
    try {
      await adminRemoveGroupTag({
        oid: tag.oid,
        group_oid: group.oid,
      })
      handleSuccess("Deleted tag!")
    } catch (error) {
      handleError({ error, prefix: "Couldn't delete tag" })
    } finally {
      setSubmitting(false)
      onClose()
    }
  }

  return (
    <div className="max-w-[500px]">
      <h4>Delete Tag</h4>
      {
        <p>
          {"Tag "}
          {<strong>{tag.name}</strong>}
          {` will be deleted from ${group.name}. Click below to confirm.`}
        </p>
      }
      <Button
        type="primary"
        htmlType="submit"
        loading={isSubmitting}
        disabled={isSubmitting}
        onClick={onSubmit}
      >
        {isSubmitting ? "Removing..." : "Confirm"}
      </Button>
    </div>
  )
}

export default DeleteTagConfirmation
