import Button from "antd/es/button"
import Dropdown from "antd/es/dropdown"
import { MoreHorizontal } from "lucide-react"
import { useState } from "react"

import type { Comment } from "../../discussions/types"
import { toHumanReadableString } from "../../utils"
import UserAvatar from "../UserAvatar"
import { CommentWithMentions } from "./CommentWithMentions"

export const CommentComponent: React.FC<{
  comment: Comment
  onDelete: () => Promise<void>
}> = ({ comment, onDelete }) => {
  const [isDeleting, setIsDeleting] = useState(false)

  const deleteComment = async () => {
    setIsDeleting(true)
    try {
      await onDelete()
    } finally {
      setIsDeleting(false)
    }
  }

  return (
    <div
      className={`group mb-6 rounded-md ${isDeleting ? "opacity-20" : "opacity-100"}`}
    >
      {comment.deleted ? (
        <i className="text-xs text-gray-400">This message has been deleted</i>
      ) : (
        <>
          <div className="flex">
            <div className="flex flex-1 items-center">
              <div className="mr-2">
                <UserAvatar name={comment.author_email} size="xs" />
              </div>
              <div>
                <div className="text-md mb-0 font-bold">
                  {comment.author_email}
                </div>
                <div className="text-xs font-semibold text-slate-400">
                  {toHumanReadableString(comment.created_at, {
                    showTime: true,
                  })}
                </div>
              </div>
            </div>
            <Dropdown
              menu={{
                items: [
                  {
                    label: "Delete Comment",
                    onClick: deleteComment as () => void,
                    key: "delete",
                    disabled: isDeleting,
                  },
                ],
              }}
            >
              <Button
                type="text"
                className="hidden items-center text-xs group-hover:flex"
                size="small"
              >
                <MoreHorizontal size={14} />
              </Button>
            </Dropdown>
          </div>
          <CommentWithMentions comment={comment.text} className="mt-1" />
        </>
      )}
    </div>
  )
}
