import { httpsCallable } from "../serverUtils"
import type {
  DeleteUserDocumentsRequest,
  KnowledgeItem,
  KnowledgeItemAssignManyRequest,
  KnowledgeItemCreationRequest,
  KnowledgeItemEditManyRequest,
  KnowledgeItemReviewManyRequest,
  KnowledgeItemUnassignManyRequest,
} from "./types"

export const createKnowledgeItemApi = httpsCallable<
  KnowledgeItemCreationRequest,
  KnowledgeItem
>("createKnowledgeItem")

export const editKnowledgeItemsApi = httpsCallable<
  KnowledgeItemEditManyRequest,
  void
>("editKnowledgeItems")

export const deleteUserDocumentsApi = httpsCallable<
  DeleteUserDocumentsRequest,
  void
>("deleteUserDocuments")

export const reviewKnowledgeItems = httpsCallable<
  KnowledgeItemReviewManyRequest,
  void
>("reviewKnowledgeItems")

export const assignKnowledgeItems = httpsCallable<
  KnowledgeItemAssignManyRequest,
  void
>("assignKnowledgeItems")

export const unassignKnowledgeItems = httpsCallable<
  KnowledgeItemUnassignManyRequest,
  void
>("unassignKnowledgeItems")
