import { useCallback } from "react"

import { sendAnalyticsEvent } from "../analytics"
import type { AnalyticsEventSurface } from "../analytics/types"
import useErrorPopup from "./useErrorPopup"

const useAnswerFeedback = (
  surface: AnalyticsEventSurface,
  answererRequestId: string | null | undefined,
  hideBadAnswerFeedbackPopup?: boolean,
) => {
  const { handleSuccess, messageApi } = useErrorPopup()
  const onGoodAnswer = useCallback(() => {
    sendAnalyticsEvent({
      event_type: "UPVOTE",
      surface,
      event_data: {
        entity_type: "ANSWER",
        entity_id: answererRequestId ?? null,
      },
    })
    handleSuccess("We recorded your feedback. We'll use it to improve!")
  }, [handleSuccess, surface, answererRequestId])

  const onBadAnswer = useCallback(() => {
    sendAnalyticsEvent({
      event_type: "DOWNVOTE",
      surface,
      event_data: {
        entity_type: "ANSWER",
        entity_id: answererRequestId ?? null,
      },
    })
    if (!hideBadAnswerFeedbackPopup) {
      void messageApi.open({
        type: "info",
        content:
          "Sorry about that! We'll use your feedback to improve future answers",
      })
    }
  }, [messageApi, surface, answererRequestId, hideBadAnswerFeedbackPopup])

  return { onGoodAnswer, onBadAnswer }
}

export default useAnswerFeedback
