import Popover from "antd/es/popover"
import { useState } from "react"

import SlackLogo from "../assets/img/slack_logo.svg"
import IntegrationBlock from "./IntegrationBlock"
import SlackConfiguration from "./SlackConfiguration"
import useSlackInstallation from "./useSlackInstallation"

interface SlackIntegrationBlockProps {
  integrationsLoading: boolean
}

const SlackIntegrationBlock: React.FC<SlackIntegrationBlockProps> = ({
  integrationsLoading,
}) => {
  const {
    slackInstallation,
    slackLoading,
    connectSlack,
    disconnectSlack,
    fetchSlackInstallation,
  } = useSlackInstallation()
  const [pickerOpen, setPickerOpen] = useState(false)
  return (
    <Popover
      content={
        <SlackConfiguration
          onClose={() => setPickerOpen(false)}
          fetchSlackInstallation={fetchSlackInstallation}
          loading={slackLoading}
          slackInstallation={slackInstallation}
        />
      }
      onOpenChange={setPickerOpen}
      open={pickerOpen}
      trigger="click"
    >
      <IntegrationBlock
        loading={slackLoading || integrationsLoading}
        name="Slack Knowledge Assistant"
        logo={SlackLogo}
        connected={!!slackInstallation}
        onConnect={connectSlack}
        onEdit={() => {
          setPickerOpen(true)
        }}
        onDisconnect={disconnectSlack}
        disconnectMessage="This will uninstall the Bot and remove any documents you may have synced from Slack"
      />
    </Popover>
  )
}

export default SlackIntegrationBlock
