import type { LocaleType } from "../../types/locale"
import type { MimeType } from "../../types/mimetype"
import type { ResponseStyle } from "../../types/sheets"
import type { AnyDocument, DocumentFields, DocumentSheet, State } from "./state"
import { initialDocumentSheetState, initialState } from "./state"

export type Action =
  | { type: "DOCUMENT_INDEX"; payload: number }
  | { type: "CLEAR_FIELDS"; documentIndex: number; sheetIndex: number }
  | { type: "CLEAR_SHEET"; documentIndex: number; sheetIndex: number }
  | {
      type: "COL_NAMES"
      documentIndex: number
      sheetIndex: number
      payload: string[]
    }
  | {
      type: "ROW_NAMES"
      documentIndex: number
      sheetIndex: number
      payload: string[]
    }
  | {
      type: "SECTION_NAMES"
      documentIndex: number
      sheetIndex: number
      payload: string[]
    }
  | {
      type: "IS_ROW"
      documentIndex: number
      sheetIndex: number
      payload: boolean
    }
  | { type: "DONE" }
  | {
      type: "SECTION_VALUE"
      documentIndex: number
      sheetIndex: number
      payload: {
        key: keyof DocumentFields
        value: string | undefined
        index: number | undefined
      }
    }
  | { type: "REUSE_MAPPING"; sheetIndex: number }
  | {
      type: "SET_RESPONSE_STYLE"
      documentIndex: number
      sheetIndex: number
      payload: ResponseStyle
    }
  | {
      type: "SECTION_KIND"
      documentIndex: number
      sheetIndex: number
      payload: { key: keyof DocumentFields; kind: "name" | "number" }
    }
  | {
      type: "FILE"
      documentIndex: number
      sheetIndex: number
      payload: {
        fileContents: Uint8Array
        fileType: MimeType
        fileName: string
      }
    }
  | {
      type: "SHEET_NAME"
      documentIndex: number
      sheetIndex: number
      payload: string
    }
  | {
      type: "SHEET_INDEX"
      documentIndex: number
      payload: number
    }
  | {
      type: "SET_VERBATIM_FROM_ANSWER_BANK"
      value: boolean
    }
  | { type: "SET_SELECTED_TAGS"; documentIndex: number; payload: string[] }
  | { type: "SET_TAG_OPERATOR"; documentIndex: number; payload: "AND" | "OR" }
  | { type: "SET_LOCALE"; documentIndex: number; payload: LocaleType }

const findApplyDoc = (
  state: State,
  apply: <T extends AnyDocument>(prevDoc: T) => T,
): State => {
  return {
    ...state,
    documents: state.documents.map((prevDoc, dIdx) =>
      dIdx === state.documentIndex ? apply(prevDoc) : prevDoc,
    ),
  }
}

// Find a document and sheet index and apply a function to it
const findApply = (
  state: State,
  documentIndex: number,
  sheetIndex: number,
  apply: (sheet: DocumentSheet) => DocumentSheet,
): State => {
  return {
    ...state,
    documents: state.documents.map((document, dIdx) => {
      if (dIdx === documentIndex) {
        return {
          ...document,
          sheets: document.sheets.map((sheet, sIdx) => {
            if (sIdx === sheetIndex) {
              return apply(sheet)
            }
            return sheet
          }),
        }
      }
      return document
    }),
  }
}

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "DOCUMENT_INDEX": {
      return { ...state, documentIndex: action.payload }
    }
    case "CLEAR_FIELDS": {
      return findApply(
        state,
        action.documentIndex,
        action.sheetIndex,
        (sheet) => ({
          ...sheet,
          fields: {
            ...initialDocumentSheetState.fields,
          },
        }),
      )
    }
    case "CLEAR_SHEET": {
      return findApply(
        state,
        action.documentIndex,
        action.sheetIndex,
        (sheet) => ({
          ...initialDocumentSheetState,
          sheetName: sheet.sheetName,
        }),
      )
    }
    case "COL_NAMES": {
      return findApply(
        state,
        action.documentIndex,
        action.sheetIndex,
        (sheet) => ({
          ...sheet,
          colNames: action.payload,
        }),
      )
    }
    case "ROW_NAMES": {
      return findApply(
        state,
        action.documentIndex,
        action.sheetIndex,
        (sheet) => ({
          ...sheet,
          rowNames: action.payload,
        }),
      )
    }
    case "SECTION_NAMES": {
      return findApply(
        state,
        action.documentIndex,
        action.sheetIndex,
        (sheet) => ({
          ...sheet,
          sectionNames: action.payload,
        }),
      )
    }
    case "IS_ROW": {
      return findApply(
        state,
        action.documentIndex,
        action.sheetIndex,
        (sheet) => ({
          ...sheet,
          isRow: action.payload,
        }),
      )
    }
    case "DONE": {
      return { ...initialState }
    }
    case "SECTION_VALUE": {
      return findApply(
        state,
        action.documentIndex,
        action.sheetIndex,
        (sheet) => {
          const updatedFields = { ...sheet.fields }

          let key: keyof DocumentFields
          for (key in updatedFields) {
            if (
              key !== action.payload.key &&
              updatedFields[key].index === action.payload.index
            ) {
              updatedFields[key] = {
                ...updatedFields[key],
                value: undefined,
                index: undefined,
              }
            }
          }

          updatedFields[action.payload.key] = {
            ...updatedFields[action.payload.key],
            value: action.payload.value,
            index: action.payload.index,
          }

          return {
            ...sheet,
            fields: updatedFields,
          }
        },
      )
    }
    case "REUSE_MAPPING": {
      return findApplyDoc(state, (prevDoc) => {
        const thisSheetFields = prevDoc.sheets[action.sheetIndex].fields
        return {
          ...prevDoc,
          sheets: prevDoc.sheets.map((sheet, sIdx) =>
            sIdx <= action.sheetIndex
              ? sheet
              : {
                  ...sheet,
                  fields: thisSheetFields,
                },
          ),
        }
      })
    }
    case "SET_RESPONSE_STYLE": {
      return findApply(
        state,
        action.documentIndex,
        action.sheetIndex,
        (sheet) => ({
          ...sheet,
          responseStyle: action.payload,
        }),
      )
    }
    case "SECTION_KIND": {
      return findApply(
        state,
        action.documentIndex,
        action.sheetIndex,
        (sheet) => ({
          ...sheet,
          fields: {
            ...sheet.fields,
            [action.payload.key]: {
              ...sheet.fields[action.payload.key],
              kind: action.payload.kind,
              value: undefined,
              index: undefined,
            },
          },
        }),
      )
    }
    case "FILE": {
      return {
        ...state,
        documents: state.documents.map((document, idx) => {
          if (idx === action.documentIndex) {
            return {
              ...document,
              fileContents: action.payload.fileContents,
              fileType: action.payload.fileType,
              fileName: action.payload.fileName,
            }
          }
          return document
        }),
      }
    }
    case "SHEET_NAME": {
      return findApply(
        state,
        action.documentIndex,
        action.sheetIndex,
        (sheet) => ({
          ...sheet,
          sheetName: action.payload,
        }),
      )
    }
    case "SHEET_INDEX": {
      return {
        ...state,
        documents: state.documents.map((document, idx) => {
          if (idx === action.documentIndex) {
            return {
              ...document,
              sheetIndex: action.payload,
            }
          }
          return document
        }),
      }
    }
    case "SET_VERBATIM_FROM_ANSWER_BANK": {
      return findApplyDoc(state, (prevDoc) => ({
        ...prevDoc,
        verbatimFromAnswerBank: action.value,
      }))
    }
    case "SET_SELECTED_TAGS": {
      return findApplyDoc(state, (prevDoc) => ({
        ...prevDoc,
        selectedTags: action.payload,
      }))
    }
    case "SET_TAG_OPERATOR": {
      return findApplyDoc(state, (prevDoc) => ({
        ...prevDoc,
        tagOperator: action.payload,
      }))
    }
    case "SET_LOCALE": {
      return findApplyDoc(state, (prevDoc) => ({
        ...prevDoc,
        locale: action.payload,
      }))
    }
  }
}
