import { AxiosError } from "axios"
import { useCallback, useState } from "react"

import {
  getAndCheckGoogleOauthCode,
  getGoogleAccessToken,
  getGoogleOauthTokenFromCode,
} from "../auth/google"

const useGoogleAccessToken = (requiredScopes: string[]) => {
  const [googleAccessToken, setGoogleAccessToken] = useState<string | null>(
    null,
  )

  const requiredScopesString = requiredScopes.join("|")
  const preloadAccessToken = useCallback(async (): Promise<string | null> => {
    // Check if the access token is expired.
    try {
      const {
        data: { accessToken },
      } = await getGoogleAccessToken({
        requiredScopes: requiredScopesString.split("|"),
      })
      setGoogleAccessToken(accessToken)
      return accessToken
    } catch (error) {
      if (
        error instanceof AxiosError &&
        ((error.response?.data ?? {}) as { code?: number }).code === 404
      ) {
        return null
      }
      throw error
    }
  }, [setGoogleAccessToken, requiredScopesString])

  const getAccessToken = useCallback(async (): Promise<string | null> => {
    if (googleAccessToken) {
      return googleAccessToken
    }

    // Try to get existing token.
    let accessToken: string | null
    if ((accessToken = await preloadAccessToken())) {
      return accessToken
    }

    const code = await getAndCheckGoogleOauthCode(requiredScopes)
    const { data: tokenData } = await getGoogleOauthTokenFromCode({
      code,
    })

    setGoogleAccessToken(tokenData.accessToken)
    return tokenData.accessToken
  }, [googleAccessToken, requiredScopes, preloadAccessToken])

  return {
    preloadAccessToken,
    getAccessToken,
  }
}

export default useGoogleAccessToken
