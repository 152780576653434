import type { GroupRole } from "../groups/types"
import type { NotificationPreferences } from "../notifications/types"
import type { MimeType } from "./mimetype"
import type { UserDocumentSourceKind } from "./userDocument"

export const USERS_COLLECTION = "users"
export const USER_DOCUMENTS_COLLECTION = "user_documents"
export const ANSWER_METADATA_COLLECTION = "answer_metadata"
export const QUESTIONNAIRE_INFO_COLLECTION = "questionnaire_info"
export const USER_DOCUMENTS_HISTORY_SUBCOLLECTION = "history"
export const INVITES_COLLECTION = "invites"
export const GROUPS_COLLECTION = "groups"
export const GROUP_MEMBERSHIP_SUBCOLLECTION = "members"
export const GROUP_TAGS_SUBCOLLECTION = "tags"
export const PIECES_SUBCOLLECTION = "pieces"
export const SUBSCRIPTIONS_COLLECTION = "subscriptions"

// From common/types.py
export enum UserFacingErrorType {
  UNKNOWN = "UNKNOWN",
  NOT_FOUND = "NOT_FOUND",
  INVALID_DOCUMENT = "INVALID_DOCUMENT",
  USER_HAS_NO_DOCUMENTS = "USER_HAS_NO_DOCUMENTS",
  INVALID_STATE_FOR_ACTION = "INVALID_STATE_FOR_ACTION",
}

export interface User {
  oid: string
  uid: string
  email: string
  displayName?: string
  photoURL?: string
  metadata: {
    // TODO(mgraczyk): Convert these to timestamps or dates.
    creationTime?: string
    lastSignInTime?: string
  }
  activeGroupOid: string
  roles: {
    [k: string]: GroupRole
  }

  banned?: boolean
  notificationPreferences?: NotificationPreferences
}

export interface InviteItem {
  oid: string
  creator_uid: string
  creator_email: string
  created_at: FirebaseFirestore.Timestamp
  invitee_email: string
  group_oid: string
  group_name: string
  status: "PENDING" | "ACCEPTED" | "CANCELED"
}

export interface AnswerMetadata {
  oid: string
  company_name: string | null
  global_custom_prompt?: string
}

export interface IndexableDocument {
  title: string
  source_kind: UserDocumentSourceKind
  url: string
  external_document_id: string
  mimetype: MimeType
}

// Claims on the user token.
// From functions/src/auth/types.ts
export interface QuiltFirebaseClaims {
  activeGroupOid: string
  quiltAdmin?: boolean
  roles: {
    [groupOid: string]: GroupRole
  }
  icomHash?: string
  isDisabled?: boolean
}
