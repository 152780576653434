import type { GroupRole } from "../../groups/types"

interface RoleInfo {
  role: GroupRole
  color: string
  name: string
  description: string
}
export const ROLE_INFOS: Record<GroupRole, RoleInfo> = {
  ADMIN: {
    role: "ADMIN",
    name: "Admin",
    color: "blue",
    description:
      "Can view and manage everything, including asking questions inviting new members",
  },
  MEMBER: {
    role: "MEMBER",
    name: "Member",
    color: "green",
    description:
      "Can ask questions, upload questionnaires, and manage content in the source documents and Answer Bank",
  },
  VIEWER: {
    role: "VIEWER",
    name: "Contributor",
    color: "cyan",
    description:
      "Can ask questions, upload and work on questionnaires, but cannot modify source documents or the Answer Bank",
  },
  CONTENT_MANAGER: {
    role: "CONTENT_MANAGER",
    name: "Content Manager",
    color: "gray",
    description:
      "Can manage source documents and answer bank, but cannot ask questions",
  },
}
