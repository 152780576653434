import { Popover } from "antd/lib"
import { useCallback, useState } from "react"

import SlackLogo from "../assets/img/slack_logo.svg"
import useErrorPopup from "../hooks/useErrorPopup"
import { removeSource } from "../sources/api"
import type { SlackSource } from "../sources/types"
import IntegrationBlock from "./IntegrationBlock"
import SlackPicker from "./SlackPicker"
import useSlackInstallation from "./useSlackInstallation"

interface Props {
  source: SlackSource | undefined
  loading: boolean
  notEnabled?: boolean
}

const SlackSourceIntegrationBlock: React.FC<Props> = ({
  source,
  loading,
  notEnabled,
}) => {
  const [pickerOpen, setPickerOpen] = useState(false)

  const { handleSuccess, handleError } = useErrorPopup()

  const { slackInstallation, slackLoading, connectSlack } =
    useSlackInstallation()

  const onEdit = useCallback(() => {
    setPickerOpen(true)
  }, [])

  const connectSource = useCallback(async () => {
    try {
      if (!slackInstallation) {
        await connectSlack()
      }
      onEdit()
    } catch (error) {
      handleError({ error, prefix: "Failed to connect Slack" })
    }
  }, [connectSlack, slackInstallation, onEdit, handleError])

  const hidePicker = useCallback(() => {
    setPickerOpen(false)
  }, [setPickerOpen])

  const onDisconnect = useCallback(async () => {
    if (!source) {
      return
    }
    try {
      await removeSource(source.oid)
      // TODO(mgraczyk): Handle slack uninstall.
      handleSuccess(
        "Successfully disconnected. Synced items will be removed in the background",
      )
    } catch (error) {
      handleError({ error, prefix: "Failed to disconnect Slack" })
    }
  }, [source, handleSuccess, handleError])

  return (
    <Popover
      content={<SlackPicker source={source} onClose={hidePicker} />}
      onOpenChange={setPickerOpen}
      open={pickerOpen}
      trigger="click"
    >
      <IntegrationBlock
        loading={loading || slackLoading}
        name="Slack Data Source"
        connected={!!source}
        onEdit={onEdit}
        onConnect={connectSource}
        onDisconnect={onDisconnect}
        logo={SlackLogo}
        notEnabled={notEnabled}
        disabledReason={
          source?.removal_requested
            ? "Removal requested"
            : pickerOpen
              ? "Use the form above to modify the source"
              : undefined
        }
      />
    </Popover>
  )
}
export default SlackSourceIntegrationBlock
