import { StrictMode } from "react"
import { IntercomProvider } from "react-use-intercom"

import AntdApp from "./components/AntdApp"
import AppSignInWrapper from "./components/AppSignInWrapper"
import PageViewObserver from "./components/PageViewObserver"
import { QuiltAuthProvider } from "./contexts/useQuiltAuthState"

function App(): React.ReactElement {
  return (
    <StrictMode>
      <PageViewObserver />
      <AntdApp>
        <QuiltAuthProvider>
          <IntercomProvider
            appId={import.meta.env.VITE_INTERCOM_APP_ID ?? ""}
            shouldInitialize={!!import.meta.env.VITE_INTERCOM_APP_ID}
            autoBoot={false}
          >
            <AppSignInWrapper />
          </IntercomProvider>
        </QuiltAuthProvider>
      </AntdApp>
    </StrictMode>
  )
}

export default App
