import type { MenuProps } from "antd"
import Button from "antd/es/button"
import Dropdown from "antd/es/dropdown"
import type { User as AuthUser } from "firebase/auth"
import { deleteDoc, doc, setDoc } from "firebase/firestore"
import { MoreHorizontal } from "lucide-react"
import { useCallback, useState } from "react"

import { db } from "../firebaseApp"
import useErrorPopup from "../hooks/useErrorPopup"
import type { Notification } from "../notifications/types"
import { USERS_COLLECTION } from "../types/common"

interface Props {
  user: AuthUser
  notification: Notification
}

const Menu: React.FC<Props> = ({ user, notification }) => {
  const { uid } = user
  const { oid } = notification
  const { handleError } = useErrorPopup()
  const [modifying, setModifying] = useState(false)

  const onRemove = useCallback(async () => {
    if (modifying) {
      return
    }

    setModifying(true)
    try {
      await deleteDoc(doc(db, USERS_COLLECTION, uid, "notifications", oid))
    } catch (error) {
      handleError({ error, prefix: "Couldn't remove notification" })
    } finally {
      setModifying(false)
    }
  }, [modifying, uid, oid, handleError])

  const markUnread = useCallback(async () => {
    if (modifying) {
      return
    }

    setModifying(true)
    try {
      await setDoc(
        doc(db, USERS_COLLECTION, uid, "notifications", oid),
        { acked: false },
        { merge: true },
      )
    } catch (error) {
      handleError({ error, prefix: "Couldn't remove notification" })
    } finally {
      setModifying(false)
    }
  }, [modifying, uid, oid, handleError])

  const items: MenuProps["items"] = [
    {
      label: "Remove",
      onClick: onRemove as () => void,
      key: "remove",
    },
    {
      label: "Mark as Unread",
      onClick: markUnread as () => void,
      key: "mark-unread",
    },
  ]

  return (
    <Dropdown menu={{ items }} trigger={["click"]}>
      <Button type="default" className="flex items-center" size="small">
        <MoreHorizontal />
      </Button>
    </Dropdown>
  )
}

export default Menu
