import {
  LockKeyholeIcon,
  MessageCircleQuestionIcon,
  ShoppingCartIcon,
  WrenchIcon,
} from "lucide-react"

import { sendAnalyticsEvent } from "../../analytics"

const QUESTIONS = [
  {
    icon: <MessageCircleQuestionIcon size="1em" />,
    title: "Ask about the company",
    text: "What does our company do?",
  },
  {
    icon: <ShoppingCartIcon size="1em" />,
    title: "Ask about products",
    text: "What products does our company offer?",
  },
  {
    icon: <LockKeyholeIcon size="1em" />,
    title: "Ask about security",
    text: "How does our company secure its data?",
  },
  {
    icon: <WrenchIcon size="1em" />,
    title: "Ask about product usage",
    text: "How would someone use our company's products?",
  },
]

interface ChatCTAProps {
  onClick: (question: string) => void
}

const ChatCTA: React.FC<ChatCTAProps> = ({ onClick }) => {
  return (
    <div className="mb-8 flex-1">
      <h2 className="mb-8 mt-32 text-center">
        Ask about anything in your Source Documents
      </h2>
      <div className="mx-auto grid w-[580px] grid-cols-2 gap-4">
        {QUESTIONS.map((question, idx) => (
          <div
            key={idx}
            className="rounder border-primary bg-purple-25 cursor-pointer rounded-md border p-4 hover:outline hover:outline-4 hover:outline-offset-1 hover:outline-[#E7DBFF]"
            onClick={() => {
              void sendAnalyticsEvent({
                event_type: "SELECT_SUGGESTED_QUESTION",
                surface: "WEB_CHAT",
                event_data: {
                  entity_type: "SUGGESTED_QUESTION",
                  question_text: question.text,
                },
              })
              onClick(question.text)
            }}
          >
            <div className="text-primary mb-2 flex items-center text-[16px] font-bold">
              {question.icon}&nbsp;{question.title}
            </div>
            <div className="text-[14px] text-purple-400">{question.text}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ChatCTA
