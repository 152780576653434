import Button from "antd/es/button"
import Divider from "antd/es/divider"
import Tooltip from "antd/es/tooltip"
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CheckIcon,
  SquareStackIcon,
  Undo2Icon,
} from "lucide-react"
import { useCallback, useContext, useMemo, useState } from "react"

import useAntdApp from "../../hooks/useAntdApp"
import { getColumnLetter } from "../../sheets/utils"
import AdvancedMenu from "./AdvancedMenu"
import Column from "./Column"
import SheetProgress from "./SheetProgress"
import WizardContext from "./context"
import type { AnyDocument, DocumentFields, Field } from "./state"
import { isActiveColumn } from "./uiUtils"
import { isValidSheet } from "./utils"

const MapFieldsSheetBody: React.FC<{
  columns: string[]
  fields: DocumentFields
  activeSheetRows: string[][]
}> = ({ columns, fields, activeSheetRows }) => {
  return (
    <tbody>
      {activeSheetRows.map((row, key) => (
        <tr key={key}>
          <td className="bg-gray-25 w-12 border border-l-0 border-gray-100 p-1 text-center">
            {key + 1}
          </td>
          {row.map((cell, i) => {
            return (
              <td
                key={i}
                valign="top"
                className={`w-48 border border-gray-100 p-1 ${
                  isActiveColumn(columns[i], fields)
                    ? "bg-purple-400 font-bold text-white"
                    : ""
                }`}
              >
                <div>{cell}</div>
              </td>
            )
          })}
        </tr>
      ))}
    </tbody>
  )
}

interface StepMapFieldsProps {
  onClose: () => void
  onCancel: () => void
  handleDocumentProcessing: (document: AnyDocument) => Promise<void>
  hideResponseControls: boolean
}

const MapFields: React.FC<StepMapFieldsProps> = ({
  onClose,
  onCancel,
  handleDocumentProcessing,
  hideResponseControls,
}) => {
  const { state, dispatch } = useContext(WizardContext)
  const { documentIndex, documents } = state
  const document = documents[documentIndex]
  const { sheetIndex, workbookData } = document
  if (workbookData === undefined || workbookData.sheetNames.length < 1) {
    throw Error("Missing workbook data")
  }
  const sheet = state.documents[documentIndex].sheets[sheetIndex]
  if (!sheet) {
    throw Error("Missing sheet: " + sheetIndex)
  }

  const { message: messageApi } = useAntdApp()
  const { sheetName, fields } = sheet
  const [error, setError] = useState<string | undefined>(undefined)
  const activeSheetRows = workbookData.sheetRows[sheetName]
  const [isSubmitting, setIsSubmitting] = useState(false)

  const numCols = activeSheetRows.length > 0 ? activeSheetRows[0].length : 0
  const columns = useMemo(() => {
    const columns: string[] = []
    for (let i = 0; i < numCols; i++) {
      columns.push(getColumnLetter(i + 1))
    }
    return columns
  }, [numCols])

  const onClickDone = useCallback(async () => {
    if (!document.sheets.some(isValidSheet)) {
      setError("Please select a primary question and answer")
      return
    }

    setIsSubmitting(true)
    try {
      // TODO(mgraczyk): Show a message saying answers will appear in the answer
      // bank, but only from the non-answer bank view and not in the
      // questionnaire assistant.
      await handleDocumentProcessing(document)
      if (sheetIndex + 1 === document.sheets.length) {
        void messageApi.success("All questionnaires uploaded.")
      } else {
        void messageApi.success("Questionnaire uploaded.")
      }
      if (documentIndex + 1 < state.documents.length) {
        dispatch({ type: "DOCUMENT_INDEX", payload: documentIndex + 1 })
      } else {
        dispatch({ type: "DONE" })
        onClose()
      }
    } catch (e) {
      // TODO(mgraczyk): Better error handling and state management.
      console.error(e)
      setError("Could not upload questionnaire, try again later.")
      return
    } finally {
      setIsSubmitting(false)
    }
  }, [
    state,
    sheetIndex,
    dispatch,
    document,
    messageApi,
    documentIndex,
    onClose,
    handleDocumentProcessing,
  ])

  const isLastSheet = sheetIndex === document.sheets.length - 1
  const isInvalid = !document.sheets.some(isValidSheet)
  const onSkip = useCallback(async () => {
    dispatch({
      type: "CLEAR_FIELDS",
      documentIndex,
      sheetIndex,
    })
    if (isLastSheet) {
      if (!isInvalid) {
        await onClickDone()
      }
    } else {
      dispatch({
        type: "SHEET_INDEX",
        documentIndex,
        payload: sheetIndex + 1,
      })
    }
  }, [documentIndex, sheetIndex, dispatch, isLastSheet, isInvalid, onClickDone])

  const sheetCompleted = isValidSheet(sheet)
  const fieldsEmpty = Object.values(fields).every(
    (field: Field) => field.value === undefined,
  )
  const continueTitle = isInvalid
    ? "Please select a primary question and answer"
    : ""

  const advancedForm = hideResponseControls ? null : (
    <AdvancedMenu
      className="mb-4"
      documentIndex={documentIndex}
      currentDocument={document}
      dispatch={dispatch}
    />
  )

  return (
    <>
      <div className="mb-2">
        <span className="text-semibold text-gray-800">
          Select a Question column and an Answer column.
        </span>{" "}
        <span className="text-gray-600">
          Or{" "}
          <strong
            className={isLastSheet ? "" : "cursor-pointer"}
            onClick={onSkip}
          >
            skip
          </strong>{" "}
          this sheet and Quilt will ignore it.
        </span>
      </div>
      {advancedForm}
      {error && <p className="text-red-500">{error}</p>}
      <div className="scrollbar-thumb-gray-300 scrollbar-track-gray-25 scrollbar-corner-gray-25 scrollbar-thin w-full grow overflow-auto border border-b-0 border-gray-100">
        <table className="min-w-full table-fixed">
          <thead className="sticky top-0">
            <tr>
              <th className="bg-gray-25 w-12 border-t-0 border-gray-100 text-gray-500">
                {" "}
              </th>
              {columns.map((column, idx) => (
                <Column
                  key={column}
                  column={column}
                  idx={idx}
                  documentIndex={documentIndex}
                  sheetIndex={sheetIndex}
                  sheet={sheet}
                  fields={fields}
                  dispatch={dispatch}
                  hideVerbositySelector={hideResponseControls}
                />
              ))}
            </tr>
          </thead>
          <MapFieldsSheetBody
            columns={columns}
            fields={fields}
            activeSheetRows={activeSheetRows}
          />
        </table>
      </div>
      <SheetProgress />
      <div>
        <Divider />
        <div className="flex justify-between">
          <div className="flex gap-2">
            <Button className="font-bold" onClick={onCancel}>
              Exit
            </Button>
            <Button
              onClick={() =>
                dispatch({ type: "CLEAR_FIELDS", documentIndex, sheetIndex })
              }
              disabled={fieldsEmpty}
              icon={<Undo2Icon />}
              className="font-bold"
            >
              Reset
            </Button>
            <Tooltip title="Reuse field mapping for all remaining sheets">
              <Button
                onClick={() =>
                  dispatch({
                    type: "REUSE_MAPPING",
                    sheetIndex,
                  })
                }
                disabled={fieldsEmpty}
                icon={<SquareStackIcon />}
                className="font-bold"
              >
                Reuse
              </Button>
            </Tooltip>
          </div>
          <div className="flex gap-2">
            {sheetIndex > 0 && (
              <Button
                onClick={() => {
                  dispatch({
                    type: "SHEET_INDEX",
                    documentIndex,
                    payload: Math.max(0, sheetIndex - 1),
                  })
                }}
                icon={<ArrowLeftIcon />}
                className="font-bold"
              >
                Back
              </Button>
            )}
            {!isLastSheet ? (
              <Button className="font-bold" onClick={onSkip}>
                Skip sheet
              </Button>
            ) : isLastSheet ? (
              <Button
                className="font-bold"
                disabled={isSubmitting || isInvalid}
                title={continueTitle}
                onClick={onSkip}
              >
                Skip sheet and be Done
              </Button>
            ) : null}
            {!isLastSheet ? (
              <Button
                type="primary"
                disabled={!sheetCompleted || isSubmitting}
                onClick={() => {
                  dispatch({
                    type: "SHEET_INDEX",
                    documentIndex,
                    payload: sheetIndex + 1,
                  })
                }}
                icon={<ArrowRightIcon />}
                title={continueTitle}
                className="font-bold"
              >
                Continue to the next sheet
              </Button>
            ) : (
              <Button
                type="primary"
                disabled={isInvalid || isSubmitting}
                onClick={onClickDone}
                icon={<CheckIcon />}
                title={continueTitle}
                className="font-bold"
              >
                {isSubmitting ? "Creating..." : "Done"}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default MapFields
