import Button from "antd/es/button"
import Popover from "antd/es/popover"
import Select from "antd/es/select"
import Space from "antd/es/space"
import { FilterIcon } from "lucide-react"
import { useCallback, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"

import type { StaticCardContent } from "../live/types"

export interface CardFilterState {
  creator: string | null
  updatedAfter: string | null
  updatedBefore: string | null
}

export interface UseCardFiltersResult {
  filterState: CardFilterState
  setFilterValue: (key: keyof CardFilterState, value: string | null) => void
  resetFilters: () => void
  activeFilterCount: number
}

export function useCardFilters(): UseCardFiltersResult {
  const [searchParams, setSearchParams] = useSearchParams()

  const filterState = useMemo<CardFilterState>(
    () => ({
      creator: searchParams.get("creator") || null,
      updatedAfter: searchParams.get("updatedAfter") || null,
      updatedBefore: searchParams.get("updatedBefore") || null,
    }),
    [searchParams],
  )

  const resetFilters = useCallback(() => {
    setSearchParams({})
  }, [setSearchParams])

  const setFilterValue = useCallback(
    (key: keyof CardFilterState, value: string | null) => {
      const newParams = new URLSearchParams(searchParams)
      if (value === null || value === undefined) {
        newParams.delete(key)
      } else {
        newParams.set(key, value)
      }
      setSearchParams(newParams)
    },
    [searchParams, setSearchParams],
  )

  const activeFilterCount = Object.values(filterState).filter(
    (value) => value !== null,
  ).length

  return { filterState, resetFilters, setFilterValue, activeFilterCount }
}

interface CardFilterPopoverProps {
  cards: StaticCardContent[] | undefined
  className?: string
}

const CardFilterPopover: React.FC<CardFilterPopoverProps> = ({
  cards,
  className,
}) => {
  const [isVisible, setIsVisible] = useState(false)
  const { filterState, setFilterValue, resetFilters, activeFilterCount } =
    useCardFilters()

  const uniqueCreatorUids = useMemo(() => {
    return [...new Set(cards?.map((card) => card.created_by.uid) || [])]
  }, [cards])

  type SelectOption = {
    value: string
    label: string
  }

  const creatorOptions: SelectOption[] = useMemo(() => {
    return uniqueCreatorUids.map((uid) => ({
      value: uid,
      label:
        cards?.find((card) => card.created_by.uid === uid)?.created_by.email ||
        "Unknown",
    }))
  }, [uniqueCreatorUids, cards])

  const filterContent = (
    <Space direction="vertical" className="w-60">
      <div>
        <div className="mt-2 font-semibold">Creator</div>
        <Select
          className="w-full"
          placeholder="Filter by creator"
          value={filterState.creator}
          onChange={(value: string | null) => setFilterValue("creator", value)}
          allowClear
          options={creatorOptions}
        />
      </div>

      <Button className="mt-4" onClick={resetFilters}>
        Clear All Filters
      </Button>
    </Space>
  )

  return (
    <Popover
      content={filterContent}
      title="Filter Options"
      trigger="click"
      open={isVisible}
      onOpenChange={setIsVisible}
    >
      <Button
        icon={<FilterIcon size="1em" />}
        type={activeFilterCount > 0 ? "primary" : "default"}
        className={className}
      >
        {activeFilterCount > 0
          ? `${activeFilterCount} ${activeFilterCount === 1 ? "filter" : "filters"}`
          : "Filter"}
      </Button>
    </Popover>
  )
}

export default CardFilterPopover
