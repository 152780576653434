import Skeleton from "antd/es/skeleton"

import type { GroupMembership } from "../groups/types"
import type { KnowledgeItem, KnowledgeItemHistory } from "./types"

const getUpdatedByEmail = (
  uid: string | undefined,
  groupMembers: GroupMembership[] | undefined,
  groupMembersLoading: boolean,
): React.ReactNode => {
  const unknownUser = "Unknown User"
  if (!uid) {
    return unknownUser
  }

  if (groupMembersLoading) {
    return (
      <Skeleton
        active
        className="w-36"
        title={false}
        paragraph={{ className: "m-0", rows: 1 }}
      />
    )
  }

  return (
    groupMembers?.find((member) => member.uid === uid)?.email ?? unknownUser
  )
}

export const HistoryLastUpdatedBy: React.FC<{
  item: KnowledgeItemHistory
  groupMembers: GroupMembership[] | undefined
  groupMembersLoading: boolean
}> = ({ item, groupMembers, groupMembersLoading }) => {
  if (item.actor) {
    return item.actor.email
  }

  return getUpdatedByEmail(
    !item.kind || (item.kind === "EDIT" && item.last_updated_by)
      ? item.last_updated_by
      : undefined,
    groupMembers,
    groupMembersLoading,
  )
}

export const LastUpdatedBy: React.FC<{
  item: KnowledgeItem
}> = ({ item }): string | undefined => {
  return item.last_edited_by?.email ?? item.creator.email
}
