import waveSVG from "../assets/img/background-wave.svg"

interface PropsType {
  children: React.ReactNode
}

const PageBackgroundContainer: React.FC<PropsType> = ({ children }) => {
  return (
    <div
      className="bg-light-purple h-full w-full bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: `url(${waveSVG})` }}
    >
      {children}
    </div>
  )
}

export default PageBackgroundContainer
