import { Link } from "react-router-dom"

export const AddConfluenceDocumentForm: React.FC = () => {
  return (
    <div className="text-lg text-gray-800">
      Choose the documents to sync between Confluence and Quilt by clicking
      &quot;Connect&quot; or &quot;Modify&quot; on{" "}
      <Link
        className="font-semibold hover:underline"
        to="/group/settings/integrations"
      >
        your integrations page.
      </Link>
    </div>
  )
}

export default AddConfluenceDocumentForm
