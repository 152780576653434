import { sendAnalyticsEvent } from "../../analytics"
import { createAnswerQuestionnaireJob } from "../../api"
import { type DocumentFileForUpload } from "../../documents/types"
import uploadUserFile from "../../files/uploadUserFile"

//TODO (ishan) maybe pull this api wrapper and the api.ts file into a jobs module
// so component doesnt have to import from the page module

export const createJob = async (docFile: DocumentFileForUpload) => {
  const { id: uploadId, uri: objectUri } = await uploadUserFile(
    docFile.fileContents,
    "answer_questionnaire_job_uploads",
    docFile.fileType,
  )

  await createAnswerQuestionnaireJob({
    idempotency_key: uploadId,
    question_answer_layouts: docFile.question_answer_layouts,
    internal_document_uri: objectUri,
    mimetype: docFile.fileType,
    original_filename: docFile.fileName,
    title: docFile.fileName,
  })

  void sendAnalyticsEvent({
    event_type: "SUBMIT_QUESTIONNAIRE",
    surface: "WEB_QUESTIONNAIRE_ASSISTANT",
    event_data: {
      entity_type: "ANSWER_QUESTIONNAIRE_JOB",
      entity_id: null,
    },
  })
}
