/* eslint-disable */
import { PureComponent, createRef } from "react"

interface Props {
  value: string
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>
  onKeyDown?: (e: any) => void
  onFocus?: (e: any) => void
  lineHeight: number
  disabled: boolean
  rows?: number

  id?: string
  className?: string
  name?: string
  placeholder?: string
  readOnly?: boolean
  maxRows?: number | undefined
  forwardedRef?: React.RefObject<HTMLTextAreaElement>
}

interface StateType {
  rows?: number
}

class AutoExpandingTextArea extends PureComponent<Props, StateType> {
  ref: React.RefObject<HTMLTextAreaElement>

  constructor(props: Props) {
    super(props)

    this.state = { rows: props.rows }
    this.ref = props.forwardedRef || createRef<HTMLTextAreaElement>()
  }

  override componentDidMount() {
    this._checkAndUpdateRows()
  }

  override componentDidUpdate(prevProps: Props) {
    if (prevProps.value !== this.props.value) {
      this._checkAndUpdateRows()
    }
  }

  static defaultProps = {
    lineHeight: 24,
    rows: 1,
  }

  _checkAndUpdateRows = () => {
    const { rows, maxRows, lineHeight } = this.props
    const { current: textarea } = this.ref
    if (!textarea) return

    const previousRows = textarea.rows
    textarea.rows = rows ?? AutoExpandingTextArea.defaultProps.rows
    const currentRows = (textarea.scrollHeight / lineHeight) | 0

    if (currentRows === previousRows) {
      textarea.rows = currentRows
    }
    if (maxRows) {
      if (currentRows >= maxRows) {
        textarea.rows = maxRows
        textarea.scrollTop = textarea.scrollHeight
      }

      this.setState({ rows: currentRows < maxRows ? currentRows : maxRows })
    } else {
      this.setState({ rows: currentRows })
    }
  }

  override render() {
    const {
      id,
      value,
      name,
      placeholder,
      onChange,
      onKeyDown,
      onFocus,
      lineHeight,
      disabled,
      readOnly,
      className,
    } = this.props
    return (
      <textarea
        id={id}
        rows={this.state.rows}
        style={{ lineHeight: lineHeight + "px" }}
        className={className ?? ""}
        ref={this.ref}
        value={value}
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        readOnly={readOnly}
      />
    )
  }
}

export default AutoExpandingTextArea
