import { AxiosError } from "axios"
import { useEffect, useState } from "react"

import { getRenderedConfluencePage } from "../../atlassian/api"
import LoadingSpinner from "../../components/LoadingSpinner"
import DocPreviewError from "./DocPreviewError"

interface Props {
  pageId: string
  pageUrl: string
}

const ConfluenceViewer: React.FC<Props> = ({ pageId, pageUrl }) => {
  const [docUrl, setDocUrl] = useState<string>()
  const [error, setError] = useState<string>()

  useEffect(() => {
    void (async () => {
      try {
        const pageHtml = await getRenderedConfluencePage({
          url: pageUrl,
          pageId,
        })
        const blob = new Blob([pageHtml], { type: "text/html" })
        const docUrl = URL.createObjectURL(blob)
        setDocUrl(docUrl)
      } catch (e) {
        if (
          e instanceof AxiosError &&
          (e.response?.data as { message: string } | undefined)?.message ===
            "account not linked"
        ) {
          setError(
            "You must link your Atlassian Confluence account to view this page.\nGo to your profile to link it.",
          )
        } else if (e instanceof Error) {
          setError(e.message)
        } else {
          setError("An unknown error occurred")
        }
      }
    })()
  }, [pageUrl, pageId])

  if (error) {
    return <DocPreviewError message={error} />
  }
  if (!docUrl) {
    return <LoadingSpinner />
  }

  return (
    <iframe
      src={docUrl}
      className="mx-auto h-[80vh] w-full overflow-y-scroll p-4"
    />
  )
}

export default ConfluenceViewer
