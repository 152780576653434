export async function playMp3FromArrayBuffer(
  arrayBuffer: ArrayBuffer,
): Promise<void> {
  return new Promise((resolve) => {
    // Create a Blob from the ArrayBuffer
    const blob = new Blob([arrayBuffer], { type: "audio/mp3" })
    const url = URL.createObjectURL(blob)
    const audio = new Audio(url)
    void audio.play()
    audio.onended = () => {
      URL.revokeObjectURL(url)
      resolve()
    }
  })
}
