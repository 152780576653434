// Should match common/locale.py
export type LocaleType =
  | "en_US"
  | "en_GB"
  | "de_DE"
  | "fr_FR"
  | "es_ES"
  | "pt_BR"

export const ALL_LOCALES = [
  { value: "en_US", label: "English (US)" },
  { value: "en_GB", label: "English (UK)" },
  { value: "de_DE", label: "Deutsch" },
  { value: "fr_FR", label: "Français" },
  { value: "es_ES", label: "Español" },
  { value: "pt_BR", label: "Português (Brasil)" },
]
