import Image from "antd/es/image"
import { useEffect } from "react"

import SlackLogo from "../assets/img/slack_logo.svg"

const SlackInstallError: React.FC = () => {
  // TODO(mgraczyk): Show a different message depending on error code.
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (window?.location !== undefined) {
        window.location.href = "/"
      }
    }, 8000)
    return (): void => clearTimeout(timeout)
  }, [])

  return (
    <div className="m-8">
      <div className="mb-8 flex items-center justify-center gap-2">
        <Image
          width={124}
          height={124}
          preview={false}
          src={SlackLogo}
          alt="Slack Logo"
        />
      </div>
      <h1 className="mb-4 text-center text-2xl font-bold">
        Sorry, linking Slack did not work
      </h1>
      <p className="text-center">
        An unknown error occurred. Please contact Quilt support or try again
        later.
      </p>
      <p className="text-center">
        We will redirect back to Quilt in a few seconds...
      </p>
    </div>
  )
}

export default SlackInstallError
