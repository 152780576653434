export const openUrlInPopup = async (url: string, target: string) => {
  const width = 500
  const height = 700
  const left = window.screen.width / 2 - width / 2
  const top = window.screen.height / 2 - height / 2
  const windowFeatures = `width=${width},height=${height},top=${top},left=${left},noreferrer"`
  const popup = window.open(url, target, windowFeatures)

  if (!popup) {
    throw new Error("Failed to open popup window")
  }

  return new Promise<void>((resolve /*reject*/) => {
    const interval = setInterval(() => {
      if (popup.closed) {
        clearInterval(interval)
        resolve()
      }
    }, 1000)
  })
}

export const openUrlInWindow = async (url: string) => {
  const win = window.open(url, "_blank", "noopener,noreferrer")
  if (!win) {
    throw new Error("Failed to open window")
  }

  return new Promise<void>((resolve /*reject*/) => {
    const interval = setInterval(() => {
      if (win.closed) {
        clearInterval(interval)
        resolve()
      }
    }, 1000)
  })
}
