import { useEffect, useState } from "react"

import LoadingSpinner from "../../components/LoadingSpinner"
import { getWebPageMetadata } from "../../frontendTools/api"
import DocNotSupported from "./DocNotSupported"
import DocPreviewError from "./DocPreviewError"

interface Props {
  href: string
}

const IframeExternalLink: React.FC<Props> = ({ href }) => {
  const [canIframe, setCanIframe] = useState<boolean>()
  const [error, setError] = useState<boolean>(false)

  useEffect(() => {
    const checkIframeSupport = async () => {
      try {
        const { data } = await getWebPageMetadata({
          url: href,
        })
        // TODO(iashris): Check for wildcard
        if (data.responseHeaders["x-frame-options"]) {
          // TODO(mgraczyk): We could proxy through our backend in these cases,
          // removing the x-frame-options header.
          setCanIframe(false)
        } else if (
          data.responseHeaders["content-security-policy"] &&
          data.responseHeaders["content-security-policy"].includes(
            "frame-ancestors",
          )
        ) {
          setCanIframe(false)
        } else {
          setCanIframe(true)
        }
      } catch (error) {
        console.error("Error checking iframe support", error)
        setCanIframe(false)
        setError(true)
      }
    }

    void checkIframeSupport()
  }, [href])

  return canIframe === undefined ? (
    <LoadingSpinner />
  ) : error ? (
    <DocPreviewError message="Failed to load document" />
  ) : canIframe ? (
    <div className="w-full grow p-4">
      <iframe
        src={href}
        className="h-full w-full grow border border-gray-200"
      />
    </div>
  ) : (
    <DocNotSupported documentUrl={href} />
  )
}

export default IframeExternalLink
