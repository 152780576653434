import { Link } from "react-router-dom"

interface Props {
  href: string
  id?: string
  className?: string
  children?: React.ReactNode
}

const SmartLink: React.FC<Props> = ({
  children,
  href,
  id = "",
  className = "",
}) => {
  if (href.startsWith("http")) {
    return (
      <a
        id={id}
        className={className}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    )
  }

  return (
    <Link id={id} to={href} className={className}>
      {children}
    </Link>
  )
}

export default SmartLink
