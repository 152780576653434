import { LogOutIcon } from "lucide-react"
import { useSignOut } from "react-firebase-hooks/auth"

import { auth } from "../firebaseApp"

const SignOut: React.FC = () => {
  const [signOut, loading, error] = useSignOut(auth)

  if (error) {
    return (
      <div>
        <p>Error: {error.message}</p>
      </div>
    )
  }
  if (loading) {
    return <p>Loading...</p>
  }

  const linkClass = "flex items-center hover:underline"

  return (
    <a onClick={signOut} className={linkClass} role="button">
      <LogOutIcon className="h-4 w-4" />
      <span className="ml-2">Log Out</span>
    </a>
  )
}

export default SignOut
