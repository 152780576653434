import { doc } from "firebase/firestore"
import { useEffect, useMemo, useState } from "react"
import { useDocumentDataOnce } from "react-firebase-hooks/firestore"

import { useActiveUserAuthorizationFromContext } from "../contexts/ActiveUserAuthorizationContext"
import { makeConverter } from "../dbUtils"
import { db } from "../firebaseApp"
import { getSlackChannelsApi } from "../slack/api"
import type { SlackChannel } from "../slack/apiTypes"
import { GROUPS_COLLECTION } from "../types/common"
import { uniqueByField } from "../utils"
import { INTEGRATION_METADATA_SUBCOLLECTION } from "./types"
import type { SlackIntegrationMetadata } from "./types"

const useSlackChannels = (): [
  SlackChannel[] | undefined,
  boolean,
  Error | undefined,
] => {
  const { activeGroupOid } = useActiveUserAuthorizationFromContext()
  const [queryChannels, setQueryChannels] = useState<SlackChannel[]>()
  const [queryLoading, setQueryLoading] = useState<boolean>(false)
  const [queryError, setQueryError] = useState<Error>()

  const [dbData, dbDataLoading, dbDataError] = useDocumentDataOnce(
    doc(
      db,
      GROUPS_COLLECTION,
      activeGroupOid,
      INTEGRATION_METADATA_SUBCOLLECTION,
      "slack",
    ).withConverter(makeConverter<SlackIntegrationMetadata>()),
  )

  // TODO(mgraczyk): Only load if db data doesn't work?
  useEffect(() => {
    void (async () => {
      setQueryLoading(true)
      try {
        const {
          data: { channels },
        } = await getSlackChannelsApi({})
        setQueryChannels(channels)
      } catch (error) {
        console.error("Error fetching data", error)
        if (error instanceof Error) {
          setQueryError(error)
        }
      } finally {
        setQueryLoading(false)
      }
    })()
  }, [activeGroupOid])

  const loading = dbDataLoading || queryLoading
  const error = dbDataError ?? queryError
  const channels = useMemo(
    () =>
      uniqueByField(
        [...(dbData?.channels ?? []), ...(queryChannels ?? [])],
        "id",
      ),
    [dbData, queryChannels],
  )
  return [channels, loading, error]
}

export default useSlackChannels
