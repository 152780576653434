import { useKeydown } from "../hooks/events"
import type { SlackInstallationMeta } from "../slack/apiTypes"
import SlackConfigurationForm from "./SlackConfigurationForm"
import useSlackChannels from "./useSlackChannels"

interface SlackConfigurationProps {
  onClose: () => void
  slackInstallation?: SlackInstallationMeta
  loading: boolean
  fetchSlackInstallation: () => Promise<void>
}
const SlackConfiguration: React.FC<SlackConfigurationProps> = ({
  onClose,
  loading,
  slackInstallation,
  fetchSlackInstallation,
}) => {
  const [channels, channelsLoading, channelsError] = useSlackChannels()

  useKeydown(
    27, // Escape
    onClose,
  )

  return (
    <SlackConfigurationForm
      slackInstallation={slackInstallation}
      channels={channels}
      refetchSlackInstallation={fetchSlackInstallation}
      onClose={onClose}
      error={channelsError ?? null}
      loading={channelsLoading || loading}
    />
  )
}
export default SlackConfiguration
