import type { CustomCellRendererProps } from "@ag-grid-community/react"
import Button from "antd/es/button"
import { MessageSquareMoreIcon } from "lucide-react"

import ConfidenceScore from "../ConfidenceScore"
import type { GridContext, RowData } from "./types"

interface CellWithTooltipProps
  extends CustomCellRendererProps<RowData, string, GridContext> {
  tooltipEnabled?: boolean
}

const CellWithTooltip: React.FC<CellWithTooltipProps> = ({
  data,
  node,
  context,
  column,
}) => {
  const columnName = column?.getId()
  const cellData = data?.[columnName!]
  if (!cellData) {
    return null
  }
  const rowIndex = node.rowIndex
  if (rowIndex === null) return null

  const answer = cellData.answer
  const confidence = answer?.confidence ?? 0

  return (
    <div>
      <span className="whitespace-pre-wrap">{cellData.rawContent}</span>
      &nbsp;
      <div className="absolute bottom-0 right-0 z-10 float-right ">
        <div className="bg-yellow-25 rounded-lg px-1">
          <ConfidenceScore score={confidence} />
        </div>
        <div
          className="rounded-md bg-yellow-500 px-1 text-xs"
          title={
            answer?.last_assigned_to
              ? `Assigned to ${answer.last_assigned_to.email}`
              : ""
          }
        >
          {answer?.last_assigned_to?.email ?? ""}
        </div>
      </div>
      {cellData.hasDiscussions && (
        <Button
          icon={<MessageSquareMoreIcon size={20} className="text-primary" />}
          className="absolute bottom-0 right-0 px-1"
          style={{
            zIndex: 12,
          }}
          onClick={context.openSidebar}
        ></Button>
      )}
    </div>
  )
}

export default CellWithTooltip
