export const FRONTEND_HOMEPAGE_COLLECTION = "frontend_homepage_metrics"
export const MEMBER_METRICS_SUBCOLLECTION = "member_metrics"

export interface OnboardingChecklist {
  document_uploaded?: boolean
  question_asked?: boolean
  questionnaire_uploaded?: boolean
  teammember_invited?: boolean
  extension_loaded?: boolean
}

export interface HomepageMetricDocument {
  oid: string
  updated_at: FirebaseFirestore.Timestamp
  metrics: Record<string, number>
  onboarding_checklist?: OnboardingChecklist
}
