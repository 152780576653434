import { type QuerySnapshot, collection, query } from "firebase/firestore"
import { useCollectionData } from "react-firebase-hooks/firestore"

import { useActiveUserAuthorizationFromContext } from "../../contexts/ActiveUserAuthorizationContext"
import { makeConverter } from "../../dbUtils"
import { db } from "../../firebaseApp"
import { SOURCES_COLLECTION, type Source } from "../../sources/types"
import { GROUPS_COLLECTION } from "../../types/common"

const useSources = (): [
  Source[] | undefined,
  boolean,
  Error | undefined,
  QuerySnapshot<Source> | undefined,
] => {
  const { activeGroupOid } = useActiveUserAuthorizationFromContext()
  return useCollectionData(
    query(
      collection(db, GROUPS_COLLECTION, activeGroupOid, SOURCES_COLLECTION),
    ).withConverter(makeConverter<Source>()),
  )
}

export default useSources
