export const GOOGLE_PROVIDER = "google.com"
export const NOTION_PROVIDER = "notion"
export const SLACK_PROVIDER = "slack"
export const GONG_PROVIDER = "gong"
export const ZOOM_PROVIDER = "zoom"
export const ATLASSIAN_PROVIDER = "atlassian"
export const MERGE_PROVIDER = "merge"

// Frontend view of the credentials, containing metadata with no secret values.
interface GoogleCredentialMetadata {
  provider: typeof GOOGLE_PROVIDER
  subProvider: "default"
  createdAt: FirebaseFirestore.Timestamp
}

interface NotionCredentialMetadata {
  provider: typeof NOTION_PROVIDER
  subProvider: "default"
  createdAt: FirebaseFirestore.Timestamp
  workspaceName: string
  workspaceIcon?: string
}

interface SlackCredentialMetadata {
  provider: typeof SLACK_PROVIDER
  subProvider: string
  createdAt: FirebaseFirestore.Timestamp
}

interface GongCredentialMetadata {
  provider: typeof GONG_PROVIDER
  subProvider: string
  createdAt: FirebaseFirestore.Timestamp
}

interface ZoomCredentialMetadata {
  provider: typeof ZOOM_PROVIDER
  subProvider: string
  createdAt: FirebaseFirestore.Timestamp
}

interface AtlassianCredentialMetadata {
  provider: typeof ATLASSIAN_PROVIDER
  subProvider: string
  createdAt: FirebaseFirestore.Timestamp
}

interface MergeCredentialMetadata {
  provider: typeof MERGE_PROVIDER
  subProvider: string
  createdAt: FirebaseFirestore.Timestamp
}

export type CredentialMetadata =
  | GoogleCredentialMetadata
  | NotionCredentialMetadata
  | SlackCredentialMetadata
  | GongCredentialMetadata
  | ZoomCredentialMetadata
  | AtlassianCredentialMetadata
  | MergeCredentialMetadata
