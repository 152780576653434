import Checkbox from "antd/es/checkbox"
import type { CheckboxChangeEvent } from "antd/es/checkbox"
import Divider from "antd/es/divider"
import Progress from "antd/es/progress"
import Skeleton from "antd/es/skeleton"
import type { User as AuthUser } from "firebase/auth"
import { type Query, collection, query, where } from "firebase/firestore"
import { CircleCheckIcon } from "lucide-react"
import { useCallback } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"

import Header from "../../components/Header"
import { useActiveUserAuthorizationFromContext } from "../../contexts/ActiveUserAuthorizationContext"
import { makeConverter } from "../../dbUtils"
import { db } from "../../firebaseApp"
import useSearchParamValue from "../../hooks/useSearchParamValue"
import { getInterpretableTimeString } from "../../timeUtils"
import { GROUPS_COLLECTION } from "../../types/common"
import type { AnswerQuestionnaireJob } from "../../types/jobs"
import { ANSWER_QUESTIONNAIRE_JOBS_COLLECTION } from "../../types/jobs"
import AddQuestionnaireWorkflowButton from "./AddQuestionnaireWorkflowButton"
import QuestionnaireCardMenu from "./QuestionnaireCardMenu"
import QuestionnaireError from "./QuestionnaireError"

interface CardProps {
  oid: string
  job: AnswerQuestionnaireJob
  title: string
  tags: {
    text: string
    icon: React.ReactNode
  }[]
  creator_email: string
}

const jobToCardProps = (job: AnswerQuestionnaireJob): CardProps => {
  return {
    oid: job.oid,
    job,
    title: job.title || job.original_filename || "Questionnaire",
    tags: [],
    creator_email: job.creator.email,
  }
}

const Dot: React.FC<{ className: string }> = ({ className }) => (
  <div className={"h-2 w-2 rounded-full " + className}></div>
)

const Count: React.FC<{ count: number }> = ({ count }) => (
  <div className="inline-block flex h-5 w-5 items-center justify-center rounded-full bg-gray-50 text-[10px]">
    {count}
  </div>
)

const isRemoving = (job: AnswerQuestionnaireJob): boolean =>
  !!job.removal_requested

const Card: React.FC<CardProps> = ({
  oid,
  job,
  tags,
  title,
  creator_email,
}) => {
  const numQuestions = job.num_questions
  const numAnswersAttempted = job.num_answers_attempted
  let numAnsweredSection: React.ReactNode
  if (numQuestions === null) {
    numAnsweredSection = (
      <div className="text-sm text-gray-500">loading questions...</div>
    )
  } else if (numQuestions === 0) {
    numAnsweredSection = (
      <div className="text-sm text-gray-500">No questions</div>
    )
  } else {
    const percentComplete = 100 * (numAnswersAttempted / (numQuestions || 1))
    numAnsweredSection = (
      <div className="max-w-80">
        <Progress
          percent={percentComplete}
          format={(p) =>
            p === 100 ? (
              <CircleCheckIcon fill="#52c41a" color="white" size="18" />
            ) : (
              (p ?? 0).toFixed(2) + "%"
            )
          }
        />
        <div className="text-sm text-gray-500">{`${numAnswersAttempted}/${numQuestions} questions processed`}</div>
      </div>
    )
  }

  const state = job.state
  return (
    <div className="gb-gray-100 my-2 rounded-md border-2 bg-white p-4">
      <div className="flex flex-row justify-between">
        <div className="w-full">
          <div className="text-sm text-gray-500">
            Added {getInterpretableTimeString(job.created_at)}
          </div>
          <div className="text-sm text-gray-500">{creator_email}</div>
          <h3 className="m-0 break-all">{title}</h3>
          {numAnsweredSection}
          <QuestionnaireError error={job.error_type} />
        </div>
        <div>
          <QuestionnaireCardMenu
            groupOid={job.group_oid}
            oid={oid}
            exportedSourceDocumentOid={job.exported_source_document_oid}
            canReview={state === "REVIEWING"}
            canDownload={state === "REVIEWING" || state === "DONE"}
            isCompleted={state === "DONE"}
            disabled={false}
          />
        </div>
      </div>

      {tags.length > 0 && <Divider className="my-3" />}
      <div className="flex flex-wrap gap-1">
        {tags.map(({ text, icon }, idx) => (
          <div
            key={idx}
            className="flex items-center gap-1 rounded bg-gray-50 px-4 py-2 text-[12px] font-bold text-gray-500"
          >
            {icon}
            <span>{text}</span>
          </div>
        ))}
      </div>
    </div>
  )
}

interface ColumnProps {
  title: string
  dotColorClass: string
  backgroundColorClass: string
  items: CardProps[] | undefined
}
const Column: React.FC<ColumnProps> = ({
  title,
  dotColorClass,
  backgroundColorClass,
  items,
}) => {
  const numItems = items?.length ?? 0

  return (
    <div
      className={
        "min-w-[200px] flex-grow basis-0 rounded-lg p-4 " + backgroundColorClass
      }
    >
      <h3 className="flex-start mt-0 flex items-center gap-2">
        <Dot className={dotColorClass} />
        <span>{title}</span>
        {numItems > 0 && <Count count={numItems} />}
      </h3>
      {items == undefined ? (
        <div className="gb-gray-100 my-2 rounded-md border-2 bg-white p-4">
          <Skeleton loading />
        </div>
      ) : (
        items.map((cardProps) => <Card key={cardProps.oid} {...cardProps} />)
      )}
    </div>
  )
}

const getQuery = (
  user: AuthUser,
  activeGroupOid: string,
  showAll: boolean,
): Query<AnswerQuestionnaireJob> => {
  const colRef = collection(
    db,
    GROUPS_COLLECTION,
    activeGroupOid,
    ANSWER_QUESTIONNAIRE_JOBS_COLLECTION,
  ).withConverter(makeConverter<AnswerQuestionnaireJob>())

  if (showAll) {
    return colRef
  } else {
    return query(colRef, where("creator.uid", "==", user.uid))
  }
}

const QuestionnaireAssistantPage: React.FC<{ user: AuthUser }> = ({ user }) => {
  const { activeGroupOid, hasPerm } = useActiveUserAuthorizationFromContext()
  const [showAllParam, setShowAll] = useSearchParamValue("showAll")
  const showAll = hasPerm("admin") && !!showAllParam

  const jobsQuery = getQuery(user, activeGroupOid, showAll)
  const [
    answerQuestionnaireJobs,
    answerQuestionnaireJobsLoading,
    answerQuestionnaireJobsError,
  ] = useCollectionData(jobsQuery)

  const onChange = useCallback(
    (e: CheckboxChangeEvent) => {
      setShowAll(e.target.checked ? "1" : null)
    },
    [setShowAll],
  )

  let bodyContent: React.ReactNode
  if (answerQuestionnaireJobsError) {
    console.error(answerQuestionnaireJobsError)
    bodyContent = (
      <div className="flex w-full justify-center">
        <div>Error loading questionnaires</div>
      </div>
    )
  } else {
    let runsProcessing: CardProps[] | undefined
    let runsReadyForReview: CardProps[] | undefined
    let runsCompleted: CardProps[] | undefined

    if (
      answerQuestionnaireJobsLoading ||
      answerQuestionnaireJobs === undefined
    ) {
      runsProcessing = undefined
      runsReadyForReview = undefined
      runsCompleted = undefined
    } else {
      const sortedJobs = answerQuestionnaireJobs.sort((a, b) =>
        b.created_at._compareTo(a.created_at),
      )
      runsProcessing = sortedJobs
        .filter(
          (job) =>
            !isRemoving(job) &&
            job.state !== "REVIEWING" &&
            job.state !== "DONE",
        )
        .map(jobToCardProps)
      runsReadyForReview = sortedJobs
        .filter((job) => !isRemoving(job) && job.state === "REVIEWING")
        .map(jobToCardProps)
      runsCompleted = sortedJobs
        .filter((job) => !isRemoving(job) && job.state === "DONE")
        .map(jobToCardProps)
    }

    bodyContent = (
      <div className="p-8">
        {hasPerm("admin") && (
          <div className="mb-2">
            <Checkbox onChange={onChange} checked={showAll}>
              Show All Questionnaires
            </Checkbox>
          </div>
        )}
        <div className="flex min-h-[230px] w-full gap-2">
          <Column
            title="Processing"
            backgroundColorClass="bg-gray-25"
            dotColorClass="bg-gray-400"
            items={runsProcessing}
          />
          <Column
            title="Ready for Review"
            backgroundColorClass="bg-yellow-25"
            dotColorClass="bg-yellow-500"
            items={runsReadyForReview}
          />
          <Column
            title="Completed"
            backgroundColorClass="bg-teal-25"
            dotColorClass="bg-teal-500"
            items={runsCompleted}
          />
        </div>
      </div>
    )
  }

  return (
    <div className="h-full pb-4">
      <Header
        title="Questionnaire Assistant"
        subtitle="We automatically fill out your questionnaires"
      >
        <div
          id="questionnaire-assistant-add-button"
          className="flex items-center justify-end gap-4"
        >
          <AddQuestionnaireWorkflowButton />
        </div>
      </Header>
      {bodyContent}
    </div>
  )
}

export default QuestionnaireAssistantPage
