import Button from "antd/es/button"
import Form from "antd/es/form"
import Input from "antd/es/input"
import Popover from "antd/es/popover"
import Space from "antd/es/space"
import Typography from "antd/es/typography"
import { PlusIcon, TrashIcon } from "lucide-react"
import { useCallback, useState } from "react"

import { VOID_FUNCTION } from "../../constants"
import { createGroupSSO, deleteGroupSSO } from "../../groups/api"
import type { Group } from "../../groups/types"
import { useKeydown } from "../../hooks/events"
import useErrorPopup from "../../hooks/useErrorPopup"

interface OIDCFormData {
  clientId: string
  clientSecret: string
  issuer: string
}

interface CreateOIDCFormProps {
  group: Group
  disabled?: boolean
  formData?: OIDCFormData
  onCancel: () => void
  allowRemoval?: boolean
}

const CreateOIDCForm: React.FC<CreateOIDCFormProps> = ({
  group,
  disabled,
  formData,
  onCancel,
  allowRemoval,
}) => {
  const { handleSuccess, handleError } = useErrorPopup()
  const [submitting, setSubmitting] = useState<boolean>(false)

  const onFinish = useCallback(
    async (formData: OIDCFormData) => {
      setSubmitting(true)
      try {
        await createGroupSSO({
          kind: "OIDC",
          groupOid: group.oid,
          clientId: formData.clientId,
          clientSecret: formData.clientSecret,
          issuer: formData.issuer,
        })
        handleSuccess("Created OIDC SSO configuration")
      } catch (error) {
        handleError({ error, prefix: "Couldn't create OIDC SSO configuration" })
      } finally {
        setSubmitting(false)
      }
    },
    [group.oid, handleSuccess, handleError],
  )
  const onDelete = useCallback(async () => {
    setSubmitting(true)
    try {
      await deleteGroupSSO({
        kind: "OIDC",
        groupOid: group.oid,
      })
      handleSuccess("Deleted OIDC SSO configuration")
    } catch (error) {
      handleError({ error, prefix: "Couldn't delete OIDC SSO configuration" })
    } finally {
      setSubmitting(false)
    }
  }, [group.oid, handleSuccess, handleError])

  const initialValues: OIDCFormData = formData ?? {
    clientId: "",
    clientSecret: "",
    issuer: "",
  }

  return (
    <Form
      autoComplete="off"
      initialValues={initialValues}
      onFinish={onFinish}
      disabled={disabled || submitting}
      layout="vertical"
      style={{ width: 600 }}
    >
      <Form.Item label="Client ID" name="clientId" rules={[{ required: true }]}>
        <Input autoComplete="off" />
      </Form.Item>
      <Form.Item
        label="Client Secret"
        name="clientSecret"
        rules={[{ required: true }]}
      >
        <Input autoComplete="off" />
      </Form.Item>
      <Form.Item
        label="Issuer (ie, Okta Domain)"
        name="issuer"
        rules={[
          { required: true },
          { type: "url" },
          { type: "string", min: 4 },
        ]}
      >
        <Input placeholder="https://..." />
      </Form.Item>
      {!disabled && (
        <Form.Item>
          <Space>
            <Button type="primary" onClick={onCancel}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              {submitting ? "Submitting..." : "Submit"}
            </Button>
          </Space>
        </Form.Item>
      )}
      {allowRemoval && (
        <Button
          type="default"
          danger
          title="Delete OIDC Configuration"
          className="flex h-9 items-center justify-center"
          disabled={submitting}
          onClick={onDelete}
        >
          <TrashIcon />
          &nbsp;{submitting ? "Deleting..." : "Delete OIDC SSO"}
        </Button>
      )}
    </Form>
  )
}

const CreateOIDCButton: React.FC<{
  group: Group
}> = ({ group }) => {
  const [open, setOpen] = useState(false)
  const hide = useCallback(() => {
    setOpen(false)
  }, [setOpen])
  useKeydown(27 /*escape*/, hide)

  return (
    <Popover
      content={<CreateOIDCForm group={group} onCancel={hide} />}
      trigger="click"
      placement="bottomLeft"
      open={open}
      onOpenChange={setOpen}
    >
      <Button
        type="primary"
        title="Add OIDC SSO Configuration"
        className="flex h-9 items-center justify-center"
      >
        <PlusIcon />
        &nbsp;Add OIDC SSO
      </Button>
    </Popover>
  )
}

interface Props {
  group: Group
}

const SSOSettingsForm: React.FC<Props> = ({ group }) => {
  const oidc = group.auth_config?.oidc
  const oidcFormData = oidc
    ? {
        clientId: oidc.client_id,
        clientSecret: "*********",
        issuer: oidc.issuer,
      }
    : undefined

  const oidcSection = oidc ? (
    <div>
      <Typography.Title level={4}>OIDC SSO Configuration</Typography.Title>
      <CreateOIDCForm
        group={group}
        disabled
        formData={oidcFormData}
        onCancel={VOID_FUNCTION}
        allowRemoval
      />
    </div>
  ) : (
    <div>
      <Typography.Title level={4}>OIDC SSO Not Configured</Typography.Title>
      <CreateOIDCButton group={group} />
    </div>
  )

  return oidcSection
}

export default SSOSettingsForm
