import App from "antd/es/app"
import ConfigProvider from "antd/es/config-provider"
import message from "antd/es/message"

// Set default alert message configurtion.
message.config({ duration: 10 })

const antdTheme = {
  components: {
    Popover: {
      // Higher than tooltip.
      zIndexPopup: 1080,
    },
    Tooltip: {
      zIndexPopup: 1070,
    },
    Layout: {
      bodyBg: "#ffffff",
      headerBg: "#ffffff",
    },
    Upload: {
      colorFillAlter: "none",
    },
    Mentions: {
      zIndexPopup: 1090,
    },
    Form: {
      itemMarginBottom: 20,
    },
  },
  token: {
    colorPrimary: "#7e5bed",
    colorLink: "#7e5bed",
    colorLinkHover: "#6549be",
    //colorPrimaryBg: "#ffffff",
  },
}

type Props = {
  children: string | JSX.Element | JSX.Element[]
}

const AntdApp: React.FC<Props> = ({ children }) => {
  return (
    <ConfigProvider theme={antdTheme}>
      <App className="h-full">{children}</App>
    </ConfigProvider>
  )
}

export default AntdApp
