import { httpsCallable } from "../serverUtils"

export const getMeterSummary = httpsCallable<
  { meterId: string; startTimeSeconds: number; endTimeSeconds: number },
  { count: number }
>("getMeterSummary")

export const createCheckoutSession = httpsCallable<
  {
    priceId: string
    mode: "subscription"
    stripeCustomerId: string
    groupOid: string
  },
  { url: string }
>("createStripeCheckoutSession")
