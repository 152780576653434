function scrollTo(
  element: HTMLElement,
  startPos: number,
  endPos: number,
): void {
  const growthRate = 1.4 // TODO(mgraczyk): Needs to depend on the distance.
  let speed = 0.5
  let i = 0

  const slowdownCutoff = 0.7 * Math.abs(endPos - startPos)

  const animateScroll = function () {
    if (i > 1000) {
      // Prevent infinite loop.
      return
    }
    i += 1

    const currentPos = element.scrollTop
    if (Math.abs(currentPos - startPos) < slowdownCutoff) {
      speed = Math.min(20, speed * growthRate)
    } else {
      speed = Math.max(0.9, speed / growthRate)
    }
    const afterMovePos =
      endPos > currentPos
        ? Math.min(endPos, currentPos + speed)
        : Math.max(endPos, currentPos - speed)

    if (Math.abs(afterMovePos - endPos) < 0.5) {
      // Close enough, be done and avoid rounding problems.
      element.scrollTop = endPos
    } else {
      element.scrollTop = afterMovePos
      requestAnimationFrame(animateScroll)
    }
  }

  animateScroll()
}

export const scrollToAlignTop = (
  parent: HTMLElement,
  child: HTMLElement,
): void => {
  const parentTop = parent.getBoundingClientRect().top
  const clientTop = child.getBoundingClientRect().top

  const scrollDifference = clientTop - parentTop
  const startPos = parent.scrollTop
  const endPos = startPos + scrollDifference

  scrollTo(parent, startPos, endPos)
}
