import type { MenuDividerType, MenuItemType } from "antd/es/menu/interface"
import type { User as AuthUser } from "firebase/auth"
import {
  ArrowLeftToLine,
  ArrowRightToLine,
  CircleHelpIcon,
  FilesIcon,
  GraduationCapIcon,
  HomeIcon,
  InboxIcon,
  ListIcon,
  MessageSquareMoreIcon,
  RadioTowerIcon,
  SheetIcon,
} from "lucide-react"
import type { NavigateFunction } from "react-router-dom"

import type { QuiltFlags } from "../groups/types"
import HelpButton from "./HelpButton"
import SidebarUserIconMenu from "./SidebarUserIconMenu"

type NavItem = (MenuItemType | MenuDividerType) & {
  key: string
  label?: React.ReactNode
  path?: string
  icon?: React.ReactNode
  children?: NavItem[]
}

export const getNavItems = (
  user: AuthUser,
  navigate: NavigateFunction,
  flags: QuiltFlags,
  isQuiltAdmin: boolean,
): NavItem[] => {
  return [
    {
      key: "home",
      path: "",
      icon: <HomeIcon />,
      label: "Home",
    },
    {
      key: "source-documents",
      icon: <FilesIcon />,
      label: "Source Documents",
    },
    {
      key: "knowledge-items",
      icon: <ListIcon />,
      label: "Answer Bank",
    },
    {
      key: "",
      style: {
        marginTop: "0.5em",
        marginBottom: "0.5em",
      },
      type: "divider" as const,
    },
    {
      key: "chat",
      icon: <MessageSquareMoreIcon />,
      label: "Knowledge Assistant",
    },
    {
      key: "questionnaire-assistant",
      icon: <SheetIcon />,
      label: "Questionnaire Assistant",
    },
    {
      key: "live-assistant",
      icon: <RadioTowerIcon />,
      style: {
        display: flags.showLiveAssistant ? undefined : "none",
      },
      label: "Live Assistant",
    },
    {
      key: "coaching-assistant",
      icon: <GraduationCapIcon />,
      style: {
        display: flags.showLiveCoaching ? undefined : "none",
      },
      label: "Coaching Assistant",
    },
    {
      key: "admin",
      className: isQuiltAdmin ? "" : "!hidden",
      icon: <span className="lucide pl-1 !text-xl">✞</span>,
      label: "Quilt Admin Tools",
    },
  ].map((item: NavItem) => ({
    ...item,
    onClick: item.key ? () => navigate(item.path ?? item.key) : undefined,
  }))
}

export const getBottomNavItems = (
  authUser: AuthUser,
  navigate: NavigateFunction,
  flags: QuiltFlags,
  collapsed: boolean,
  setCollapsed: (collapsed: boolean) => void,
): NavItem[] => {
  return [
    {
      key: "sider-trigger",
      onClick: () => setCollapsed(!collapsed),
      icon: collapsed ? (
        <ArrowRightToLine size="18px" />
      ) : (
        <ArrowLeftToLine size="18px" />
      ),
      label: collapsed ? "" : "Collapse",
      title: collapsed ? "Expand Sidebar" : undefined,
    },
    {
      key: "tasks",
      icon: <InboxIcon />,
      label: collapsed ? "" : "Tasks",
      title: collapsed ? "Tasks" : undefined,
      onClick: () => navigate("assignments"),
    },
    {
      key: "help-button",
      icon: (
        <HelpButton
          user={authUser}
          className="flex h-full cursor-pointer flex-row items-center"
        >
          <CircleHelpIcon />
        </HelpButton>
      ),
      label: collapsed ? "" : "Get Help",
      title: collapsed ? "Get Help" : undefined,
    },
    {
      key: "user",
      style: { height: "4em", paddingLeft: "16px", display: "flex" },
      icon: (
        <SidebarUserIconMenu
          label={collapsed ? "" : "Settings & Profile"}
          flags={flags}
        />
      ),
      title: "Settings & Profile",
    },
  ]
}

export const getNarrowNavItems = (
  authUser: AuthUser,
  navigate: NavigateFunction,
  flags: QuiltFlags,
  isQuiltAdmin: boolean,
): NavItem[] => {
  return [
    ...getNavItems(authUser, navigate, flags, isQuiltAdmin),
    {
      key: "",
      style: {
        marginTop: "0.5em",
        marginBottom: "0.5em",
      },
      type: "divider" as const,
    },
    {
      key: "tasks",
      icon: <InboxIcon />,
      label: "Tasks",
      onClick: () => navigate("assignments"),
    },
    {
      key: "help-button",
      icon: (
        <HelpButton
          user={authUser}
          className="flex h-full cursor-pointer flex-row items-center"
        >
          <CircleHelpIcon />
          <span className="ml-1 text-[14px]">Get Help</span>
        </HelpButton>
      ),
    },
    {
      key: "user",
      icon: (
        <div className="flex items-center">
          <SidebarUserIconMenu label="" flags={flags} />
          <span className="ml-1 text-[14px]">Settings &amp; Profile</span>
        </div>
      ),
    },
  ]
}
