import { useEffect, useRef, useState } from "react"
import { useDebounceCallback, useResizeObserver } from "usehooks-ts"

const DEFAULT_HEIGHT = 200

// Get a ref and height to measure the height of a component, for virtual lists.
const useComponentHeight = (): [React.RefObject<HTMLDivElement>, number] => {
  const [result, setResult] = useState<number>(DEFAULT_HEIGHT)
  const ref = useRef<HTMLDivElement>(null)

  const onResize = useDebounceCallback(() => {
    const container = ref.current
    if (!container) {
      setResult(DEFAULT_HEIGHT)
      return
    }
    const { height } = container.getBoundingClientRect()
    setResult(height)
  }, 250)

  useResizeObserver({
    ref: { current: ref.current },
    onResize,
  })

  // Call once on load.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(onResize, [])

  return [ref, result]
}
export default useComponentHeight
