import { useCallback, useMemo } from "react"
import { useSearchParams } from "react-router-dom"

function parseUrlParamArray<T extends string = string>(
  value: string | null,
): T[] {
  if (!value) {
    return []
  }
  return value.split(",").filter(Boolean) as T[]
}

export interface DocumentFilterState {
  tags: string[]
  creator: string | null
  source: string | null
  assignedTo: string | null
  sourceKind: string | null
}

export interface UseFiltersResult {
  filterState: DocumentFilterState
  setFilterValue: (
    key: keyof DocumentFilterState,
    value: string | string[] | null,
  ) => void
  resetFilters: () => void
  activeFilterCount: number
}

export function useDocumentFilters(): UseFiltersResult {
  const [searchParams, setSearchParams] = useSearchParams()

  const filterState = useMemo<DocumentFilterState>(
    () => ({
      tags: parseUrlParamArray(searchParams.get("tags")),
      creator: searchParams.get("creator") || null,
      source: searchParams.get("source") || null,
      assignedTo: searchParams.get("assignedTo") || null,
      sourceKind: searchParams.get("sourceKind") || null,
    }),
    [searchParams],
  )

  const resetFilters = useCallback(() => {
    setSearchParams({})
  }, [setSearchParams])

  const setFilterValue = useCallback(
    (
      key: keyof DocumentFilterState,
      value: string | string[] | null | undefined,
    ) => {
      const newParams = new URLSearchParams(searchParams)
      if (
        value === null ||
        value === undefined ||
        (Array.isArray(value) && value.length === 0)
      ) {
        newParams.delete(key)
      } else if (Array.isArray(value)) {
        // TODO(mgraczyk): Use ordinary urlparam array instead of comma-separated string.
        newParams.set(key, value.join(","))
      } else {
        newParams.set(key, value)
      }
      setSearchParams(newParams)
    },
    [searchParams, setSearchParams],
  )

  const activeFilterCount = Object.values(filterState).filter((value) =>
    Array.isArray(value) ? value.length > 0 : value !== null,
  ).length

  return { filterState, resetFilters, setFilterValue, activeFilterCount }
}
