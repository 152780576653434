import { useCallback } from "react"
import { useSearchParams } from "react-router-dom"

const useSearchParamValue = (
  param: string,
): [string | null, (value: string | null) => void] => {
  const [searchParams, setSearchParams] = useSearchParams()

  const setSearchParamValue = useCallback(
    (value: string | null) => {
      const newSearchParams = new URLSearchParams(searchParams)
      if (value === null) {
        newSearchParams.delete(param)
      } else {
        newSearchParams.set(param, value)
      }
      setSearchParams(newSearchParams)
    },
    [param, searchParams, setSearchParams],
  )

  return [searchParams.get(param), setSearchParamValue] as const
}

export default useSearchParamValue
