import { httpsCallable } from "../serverUtils"
import type {
  DeleteAllChatMessagesRequest,
  SaveChatMessageAnswerRequest,
  SaveChatMessageAnswerResult,
} from "./types"

// Saves the chat message to the answer bank, updating the answer if it already exists.
export const saveChatMessageAnswer = httpsCallable<
  SaveChatMessageAnswerRequest,
  SaveChatMessageAnswerResult
>("saveChatMessageAnswer")

export const deleteAllChatMessages = httpsCallable<
  DeleteAllChatMessagesRequest,
  void
>("deleteAllChatMessages")
