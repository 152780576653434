import type { LucideIcon } from "lucide-react"
import { Building2, UserIcon, UsersIcon } from "lucide-react"

import type { Subscription } from "./types"
import {
  getStripeBasePriceId,
  getStripeBaseProductId,
  getStripeTeamPriceId,
  getStripeTeamProductId,
} from "./utils"

export type QuiltPlanId = "TEAM" | "BASIC" | "ENTERPRISE"

export interface PlanDetails {
  id: QuiltPlanId
  name: string
  description: string
  features: string[]
  icon: LucideIcon
  support: string
}

export const BASE_PLAN_DETAILS: PlanDetails = {
  id: "BASIC",
  name: "Individual",
  description: "Get started and knock out some questionnaires quickly",
  features: [
    "Automated knowledge management",
    "Interactive questionnaire assistant",
    "Web-based chat assistant",
  ],
  support: "Email and Intercom",
  icon: UserIcon,
}

export const TEAM_PLAN_DETAILS: PlanDetails = {
  id: "TEAM",
  name: "Team",
  description: "Drive efficient workflows off of your company’s knowledge",
  features: [
    "Everything in Individual",
    "Slack chat assistant",
    "Unlimited usage for questionnaire assistant",
  ],
  support: "Slack and custom implementation",
  icon: UsersIcon,
}

export const ENTERPRISE_PLAN_DETAILS: PlanDetails = {
  id: "ENTERPRISE",
  name: "Enterprise",
  description: "Up-level efficiency and performance across the organization",
  features: [
    "Everything in Team",
    "Intelligent meeting assistant",
    "3rd Party Integrations (Notion, Confluence, Slack, Zoom, Chorus, Gong)",
    "SSO + SCIM for authentication",
  ],
  support: "Dedicated CSM and custom enablement",
  icon: Building2,
}

export const LIVE_PRODUCT_PLAN_DETAILS = [
  BASE_PLAN_DETAILS,
  TEAM_PLAN_DETAILS,
  ENTERPRISE_PLAN_DETAILS,
]

export const getQuiltPlanDetailsBy = <K extends keyof PlanDetails>(
  searchKey: K,
  value: PlanDetails[K],
) => {
  const plan = LIVE_PRODUCT_PLAN_DETAILS.find(
    (plan) => plan[searchKey] === value,
  )
  if (!plan) {
    throw new Error(
      `No Quilt Plan Details found for ${searchKey}=${value?.toString()}`,
    )
  }
  return plan
}

export const getQuiltPlanDetailsByStripePriceId = (priceId: string) => {
  return getQuiltPlanDetailsByStripeId(priceId)
}

export const getQuiltPlanDetailsByStripeProductId = (productId: string) => {
  return getQuiltPlanDetailsByStripeId(productId)
}

const getQuiltPlanDetailsByStripeId = (id: string) => {
  if (id === getStripeBasePriceId() || id === getStripeBaseProductId()) {
    return BASE_PLAN_DETAILS
  } else if (id === getStripeTeamPriceId() || id === getStripeTeamProductId()) {
    return TEAM_PLAN_DETAILS
  }
  throw new Error(`Unknown Stripe ID=${id}`)
}

export const getStripePriceIdForPlan = (quiltPlanId: QuiltPlanId) => {
  if (quiltPlanId === BASE_PLAN_DETAILS.id) {
    return getStripeBasePriceId()
  } else if (quiltPlanId === TEAM_PLAN_DETAILS.id) {
    return getStripeTeamPriceId()
  } else {
    throw new Error("Enterprise Plan not yet supported")
  }
}

export type PlanAndStatus = {
  status: "active" | "freeTrial" | "expired"
  plan?: PlanDetails
}

export const getPlanAndStatusFromSubscriptions = (
  currentSubscriptions?: Subscription[],
): PlanAndStatus => {
  if (!currentSubscriptions || currentSubscriptions.length === 0) {
    return {
      status: "expired",
    }
  }

  const { stripeProductId } = currentSubscriptions[0]
  if (stripeProductId) {
    return {
      plan: getQuiltPlanDetailsByStripeProductId(stripeProductId),
      status: "active",
    }
  }

  return {
    plan: BASE_PLAN_DETAILS,
    status: "freeTrial",
  }
}

export const getPlanOverviewText = (planAndStatus: PlanAndStatus) => {
  if (planAndStatus.status === "expired") {
    return "Your plan is expired. "
  }
  return `You are on ${planAndStatus.status === "freeTrial" ? "a Free Trial of" : ""} the
    ${planAndStatus.plan?.name} plan. `
}

export const getStripeProductIdForPlanId = (
  planId: Omit<QuiltPlanId, "ENTERPRISE">,
) => {
  switch (planId) {
    case BASE_PLAN_DETAILS.id:
      return getStripeBaseProductId()
    case TEAM_PLAN_DETAILS.id:
      return getStripeTeamProductId()
    default:
      throw new Error(`Unknown planId=${planId as string}`)
  }
}
