import { isVerbatimFromAnswerBank } from "../../hooks/useEnrichedReference"
import type { AnswerReference } from "../../types/answerer"
import ChatReference from "./ChatReference"

interface ChatReferenceListProps {
  references: AnswerReference[]
  size: "small" | "large"
  loadEagerly?: boolean
  cardClassName?: string
}

const ChatReferenceList: React.FC<ChatReferenceListProps> = ({
  references,
  size,
  loadEagerly,
  cardClassName,
}) => {
  if (!references || references.length === 0) {
    return null
  }

  const className =
    references.length === 1
      ? ""
      : size === "large"
        ? "auto-cols-auto grid-cols-2 md:grid lg:grid-cols-3"
        : "auto-cols-auto 2xl:grid 2xl:grid-cols-2"

  return (
    <div className={"flex flex-col gap-4 " + className}>
      {references.length === 0 ? (
        <div className="mt-4 grow">No references to display</div>
      ) : (
        references.map((reference, idx) => (
          <ChatReference
            key={idx}
            idx={idx}
            reference={reference}
            loading={false}
            loadKnowledgeItemEagerly={loadEagerly}
            className={cardClassName}
            isVerbatimFromAnswerBank={isVerbatimFromAnswerBank(references)}
          />
        ))
      )}
    </div>
  )
}

export default ChatReferenceList
