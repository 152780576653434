import Image from "antd/es/image"
import { useEffect } from "react"
import { useParams } from "react-router"

import QuiltLogo from "../assets/img/logo_only.svg"
import SlackLogo from "../assets/img/slack_logo.svg"
import ExternalLink from "./ExternalLink"

const SlackInstallSuccess: React.FC = () => {
  const { teamId, appId } = useParams<{
    teamId: string
    appId: string
  }>()

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (window?.location !== undefined) {
        window.location.href = `slack://app?team=${teamId}&id=${appId}`
      }
    }, 3000)
    return (): void => clearTimeout(timeout)
  }, [teamId, appId])

  return (
    <div className="m-8">
      <div className="mb-8 flex items-center justify-center gap-2">
        <Image
          width={124}
          height={124}
          preview={false}
          src={SlackLogo}
          alt="Slack Logo"
        />
        <span className="text-large mx-4">➕</span>
        <Image
          width={124}
          height={124}
          preview={false}
          src={QuiltLogo}
          alt="Quilt Logo"
        />
      </div>
      <h1 className="mb-4 text-center text-2xl font-bold">
        Quilt has been added to your workspace!
      </h1>
      <p className="text-center">
        Redirecting to the Quilt Slack App... click{" "}
        <div className="inline-block">
          <ExternalLink
            className="text-blue-500 underline"
            href={`slack://app?team=${teamId}&id=${appId}`}
          >
            here
          </ExternalLink>
        </div>{" "}
        if you are not redirected. If you use the browser version of Slack,
        click{" "}
        <div className="inline-block">
          <ExternalLink
            className="text-blue-500 underline"
            href={`https://app.slack.com/client/${teamId}`}
          >
            this link
          </ExternalLink>
        </div>{" "}
        instead.
      </p>
    </div>
  )
}

export default SlackInstallSuccess
