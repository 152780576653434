import Button from "antd/es/button"
import Input from "antd/es/input"
import { SendHorizontalIcon } from "lucide-react"
import { useCallback, useEffect, useState } from "react"

const { TextArea } = Input

// TODO(mgraczyk): Pass chat sessionId.
interface ChatSendFormProps {
  id?: string
  className?: string
  loading: boolean
  // Changes to defaultValue will change the rendered value.
  defaultValue?: string
  sendChatMessage: (message: string) => void
}

const ChatSendForm: React.FC<ChatSendFormProps> = ({
  id,
  className = "",
  loading,
  defaultValue = "",
  sendChatMessage,
}) => {
  const [input, setInput] = useState<string>(defaultValue)

  useEffect(() => {
    setInput(defaultValue)
  }, [defaultValue])

  const onSubmitForm = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      const trimmedInput = input.trim()
      if (!trimmedInput) {
        return
      }
      setInput("")
      sendChatMessage(trimmedInput)
    },
    [input, sendChatMessage],
  )

  const inputKeyHandler = useCallback(
    (e: React.KeyboardEvent<HTMLFormElement>) => {
      if (e.key === "Enter" && !e.shiftKey) {
        onSubmitForm(e)
      }
    },
    [onSubmitForm],
  )

  return (
    <form
      id={id}
      className={"relative w-full " + className}
      onSubmit={onSubmitForm}
      onKeyDown={inputKeyHandler}
    >
      <TextArea
        placeholder="Type your question..."
        value={input}
        autoFocus
        autoSize={{ minRows: 2, maxRows: 12 }}
        className="min-h-16 w-full resize-none rounded-md border pr-12"
        onChange={({ target: { value } }) => setInput(value)}
        disabled={loading}
      />
      <Button
        className="absolute bottom-[14px] right-[14px] px-2"
        type="primary"
        htmlType="submit"
        disabled={loading || !input.trim()}
      >
        <SendHorizontalIcon />
      </Button>
    </form>
  )
}

export default ChatSendForm
