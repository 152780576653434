import Form from "antd/es/form"
import Input from "antd/es/input"
import { AxiosError } from "axios"
import {
  OAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithRedirect,
} from "firebase/auth"
import { LockIcon } from "lucide-react"
import { useCallback, useState } from "react"
import { useSearchParams } from "react-router-dom"

import { sendEventsAfterSignIn } from "../analytics/signin"
import SignInButton from "../components/SignInButton"
import { auth } from "../firebaseApp"
import { getSSOConfig } from "../groups/api"

const getSSOErrorMessage = (error: unknown): string | undefined => {
  if (!error) {
    return
  }

  if (
    error instanceof Error &&
    error.message.includes("auth/popup-closed-by-user")
  ) {
    return
  }

  if (
    error instanceof Error &&
    error.message.includes("auth/operation-not-allowed")
  ) {
    return "SSO is not enabled for this account. Please sign in using another method or contact your IT department."
  }

  if (error instanceof AxiosError && error.response?.status === 404) {
    return "SSO is not enabled for this account. Please sign in using another method or contact your IT department."
  }

  console.error(error)
  return "Error signing in, try again later and contact Quilt via slack or support@quilt.app"
}

interface SSOFormData {
  email: string
  password?: string
}

interface Props {
  ssoButtonText: string
  setErrorString: (errorString: string | undefined) => void
}

const SSOForm: React.FC<Props> = ({ ssoButtonText, setErrorString }) => {
  const [searchParams] = useSearchParams()
  const [needsPassword, setNeedsPassword] = useState<boolean>(
    !!searchParams.get("showPassword"),
  )

  const signinWithPassword = useCallback(
    async (formData: SSOFormData) => {
      if (!formData.password) {
        if (formData.password === "") {
          setErrorString("Please enter your password")
        }
        setNeedsPassword(true)
        return
      }
      try {
        const result = await signInWithEmailAndPassword(
          auth,
          formData.email,
          formData.password,
        )
        sendEventsAfterSignIn(result)
      } catch (error) {
        console.error(error)
        setErrorString(
          "Error signing in, try again later and contact Quilt via slack or support@quilt.app",
        )
      }
    },
    [setErrorString],
  )

  const onSubmitSSO = useCallback(
    async (formData: SSOFormData) => {
      if (!formData.email) {
        setErrorString("Please enter your email address.")
        return
      }
      if (needsPassword) {
        return await signinWithPassword(formData)
      }

      try {
        const { providerId } = (
          await getSSOConfig({
            // TODO(mgraczyk): Automatically initiate signin when the url contains
            // the necessary parameters.
            //issuer: searchParams.get("iss") ?? undefined,
            //clientId: searchParams.get("clientId") ?? undefined,
            email: formData.email,
          })
        ).data

        if (providerId === "password") {
          return await signinWithPassword(formData)
        }

        const provider = new OAuthProvider(providerId)
        // HACK: Redirect does not work with localhost:
        const isLocalhost =
          window.location.origin.startsWith("http://localhost:")
        const result = await (isLocalhost
          ? signInWithPopup(auth, provider)
          : signInWithRedirect(auth, provider))

        sendEventsAfterSignIn(result)
        console.log(result)
      } catch (error) {
        setErrorString(getSSOErrorMessage(error as Error))
      }
    },
    [setErrorString, signinWithPassword, needsPassword],
  )

  return (
    <Form
      className="m-0 w-full p-0"
      initialValues={{ email: searchParams.get("email") ?? "" }}
      onFinish={onSubmitSSO}
    >
      <Form.Item name="email">
        <Input
          type="email"
          placeholder="Enter your email address..."
          className="mb-2 p-2"
        />
      </Form.Item>
      {needsPassword && (
        <Form.Item name="password">
          <Input
            type="password"
            placeholder="Enter your password..."
            className="mb-2 p-2"
          />
        </Form.Item>
      )}
      <SignInButton
        logo={<LockIcon className="text-yellow-800" size={20} />}
        loading={false}
        buttonText={ssoButtonText}
      />
    </Form>
  )
}

export default SSOForm
