import { EMPTY_ARRAY } from "../../constants"
import { type DocumentFile } from "../../documents/types"
import type { LocaleType } from "../../types/locale"
import type { MimeType } from "../../types/mimetype"
import { ResponseStyle } from "../../types/sheets"

export interface WorkbookData {
  sheetRows: Record<string, string[][]>
  sheetNames: string[]
}

export interface Field {
  kind: "name" | "number"
  value: string | number | undefined
  index: number | undefined
}

export interface DocumentFields {
  questionId: Field
  primaryQuestion: Field
  secondaryQuestion: Field
  answerCategorical: Field
  answerExplanation: Field
}

export interface DocumentSheet {
  fields: DocumentFields
  responseStyle: ResponseStyle
  colNames: string[]
  rowNames: string[]
  sectionNames: string[]
  isRow: boolean
  sheetName: string
  dirty: boolean
}

interface SheetsDocument {
  sheetIndex: number
  workbookData: WorkbookData | undefined
  sheets: DocumentSheet[]
  mimeType: MimeType | null
  verbatimFromAnswerBank?: boolean
  selectedTags?: string[]
  tagOperator: "AND" | "OR"
  locale: LocaleType
}

export interface FileDocument extends SheetsDocument {
  kind: "FILE"
  fileContents?: DocumentFile["fileContents"]
  // TODO(mgraczyk): Replace with mimetype.
  fileType?: DocumentFile["fileType"]
  fileName?: DocumentFile["fileName"]
}

export interface GoogleDriveDocument extends SheetsDocument {
  kind: "GOOGLE_DRIVE"
  title?: string
  external_document_id: string
  url?: string
}

export type AnyDocument = FileDocument | GoogleDriveDocument

export const isFileDocument = (
  document: AnyDocument,
): document is FileDocument => document.kind === "FILE"

export const isGoogleDriveDocument = (
  document: AnyDocument,
): document is GoogleDriveDocument => document.kind === "GOOGLE_DRIVE"

export interface State {
  documentIndex: number
  totalDocuments: number
  documents: AnyDocument[]
}

export const initialDocumentSheetState: Omit<DocumentSheet, "sheetName"> = {
  fields: {
    questionId: {
      kind: "name",
      value: undefined,
      index: undefined,
    },
    primaryQuestion: {
      kind: "name",
      value: undefined,
      index: undefined,
    },
    secondaryQuestion: {
      kind: "name",
      value: undefined,
      index: undefined,
    },
    answerCategorical: {
      kind: "name",
      value: undefined,
      index: undefined,
    },
    answerExplanation: {
      kind: "name",
      value: undefined,
      index: undefined,
    },
  },
  responseStyle: ResponseStyle.DEFAULT,
  colNames: EMPTY_ARRAY,
  rowNames: EMPTY_ARRAY,
  isRow: false,
  sectionNames: EMPTY_ARRAY,
  dirty: false,
}

const initialDocumentState: SheetsDocument = {
  sheetIndex: 0,
  workbookData: undefined,
  sheets: EMPTY_ARRAY,
  mimeType: null,
  tagOperator: "OR",
  locale: "en_US",
}

export const initialFileDocumentState: FileDocument = {
  ...initialDocumentState,
  kind: "FILE",
  fileContents: undefined,
  fileType: undefined,
  fileName: undefined,
}

export const initialGoogleDriveDocumentState = {
  ...initialDocumentState,
  kind: "GOOGLE_DRIVE" as const,
  title: undefined,
  url: undefined,
}

export const initialState: State = {
  documentIndex: 0,
  totalDocuments: 0,
  documents: EMPTY_ARRAY,
}
