export const TAGCOLORS = [
  "gray",
  "red",
  "orange",
  "yellow",
  "lime",
  "green",
  "teal",
  "cyan",
  "blue",
  "indigo",
  "violet",
  "purple",
  "fuchsia",
  "pink",
] as const

export type TagColor = (typeof TAGCOLORS)[number]

export interface GroupTag {
  oid: string
  name: string
  color: TagColor
  created_at: FirebaseFirestore.Timestamp
  updated_at: FirebaseFirestore.Timestamp
}

export type CreateGroupTagArgs = Pick<GroupTag, "name" | "color"> & {
  group_oid: string
}

export type EditGroupTagArgs = Pick<GroupTag, "oid" | "name" | "color"> & {
  group_oid: string
  updated_at?: FirebaseFirestore.Timestamp
}

export type RemoveGroupTagArgs = Pick<GroupTag, "oid"> & {
  group_oid: string
}
