import { useEffect, useState } from "react"

import { getDocumentById } from "../../dbUtils"
import type { KnowledgeItem } from "../../knowledge/types"
import { type AnswerReference, AnswerReferenceKind } from "../../types/answerer"
import {
  PIECES_SUBCOLLECTION,
  USER_DOCUMENTS_COLLECTION,
} from "../../types/common"
import type { UserDocument } from "../../types/userDocument"
import type { UserDocumentPiece } from "../../types/userDocument"
import { getReferenceProps } from "./getReferenceProps"

export const getReferenceTagColorClass = (reference: AnswerReference) => {
  // NOTE: Make sure this matches getReferenceTagColorClass.
  return reference.kind === AnswerReferenceKind.KNOWLEDGE_ITEM
    ? "bg-blue-50 text-blue-600 hover:bg-blue-200 hover:text-blue-500"
    : "bg-purple-50 text-purple-500 hover:bg-purple-200 hover:text-purple-500"
}

export const getReferenceColorClass = (reference: AnswerReference) => {
  return reference.kind === AnswerReferenceKind.KNOWLEDGE_ITEM
    ? "bg-blue-50 text-blue-600 hover:bg-blue-200 hover:text-blue-500"
    : "bg-purple-50 text-purple-500 hover:bg-purple-200 hover:text-purple-500"
}

export const isVerbatimFromAnswerBank = (
  references: AnswerReference[],
): boolean => {
  return (
    references.length === 1 &&
    references[0].kind === AnswerReferenceKind.KNOWLEDGE_ITEM
  )
}

const fetchPieceAndSwallowError = async (
  document_oid: string,
  piece_oid: string | null | undefined,
): Promise<UserDocumentPiece | undefined> => {
  if (!piece_oid) {
    return
  }
  // Check for : as a workaround for pieces with IDs like <document_oid>:<piece_index>
  // TODO(mgraczyk): Remove this after fixing typesense index.
  if (piece_oid.includes(":")) {
    return undefined
  }

  try {
    return await getDocumentById<UserDocumentPiece>(
      USER_DOCUMENTS_COLLECTION,
      document_oid,
      PIECES_SUBCOLLECTION,
      piece_oid,
    )
  } catch (e) {
    // Ignore
    console.error(e)
    return undefined
  }
}

export const useEnrichedReference = (
  reference: AnswerReference,
  designVersion: "v2" | "popover",
  loadEagerly?: boolean,
) => {
  const [knowledgeItem, setKnowledgeItem] = useState<KnowledgeItem>()
  const [userDocument, setUserDocument] = useState<UserDocument>()
  const [piece, setPiece] = useState<UserDocumentPiece>()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    void (async () => {
      if (knowledgeItem || piece || !loadEagerly || error) {
        return
      }
      try {
        setIsLoading(true)
        if (reference.knowledge_item_oid) {
          const result = await getDocumentById<KnowledgeItem>(
            USER_DOCUMENTS_COLLECTION,
            reference.knowledge_item_oid,
          )
          setKnowledgeItem(result)
        } else if (reference.document_oid) {
          const [userDocument, piece] = await Promise.all([
            getDocumentById<UserDocument>(
              USER_DOCUMENTS_COLLECTION,
              reference.document_oid,
            ),
            fetchPieceAndSwallowError(
              reference.document_oid,
              reference.piece_oid,
            ),
          ])
          setUserDocument(userDocument)
          setPiece(piece)
        }
      } catch (e) {
        console.error(e)
        setError(e as Error)
      } finally {
        setIsLoading(false)
      }
    })()
  }, [
    reference.document_oid,
    reference.piece_oid,
    reference.knowledge_item_oid,
    piece,
    knowledgeItem,
    loadEagerly,
    error,
  ])

  const enrichedReference = getReferenceProps(
    reference,
    designVersion,
    knowledgeItem,
    userDocument,
    piece,
  )

  return { enrichedReference, isLoading, error }
}
