import type { Answer } from "../types/answerer"

export const insertReferenceMarkup = (
  primaryAnswer: string,
  answer: Answer,
): string => {
  const { primary_reference_spans: spans } = answer
  if (!spans || spans.length === 0) return primaryAnswer

  // Insert a superscript number after each reference span. Make sure it's not
  // included when copying the string.

  // Sort by end index.
  spans.sort((a, b) => a.e - b.e)

  const unicodeSuperscriptDigits = [
    " ⁰",
    " ¹",
    " ²",
    " ³",
    " ⁴",
    " ⁵",
    " ⁶",
    " ⁷",
    " ⁸",
    " ⁹",
  ]
  let result = ""
  let currPos = 0
  for (const { i, e } of spans) {
    result += primaryAnswer.slice(currPos, e) + unicodeSuperscriptDigits[i + 1]
    currPos = e
  }
  result += primaryAnswer.slice(currPos)
  return result
}
