import Button from "antd/es/button"
import { doc } from "firebase/firestore"
import { useEffect } from "react"
import { useDocumentData } from "react-firebase-hooks/firestore"
import { useParams } from "react-router-dom"

import { useActiveUserAuthorizationFromContext } from "../contexts/ActiveUserAuthorizationContext"
import { makeConverter } from "../dbUtils"
import { db } from "../firebaseApp"
import { useAcceptInvite } from "../hooks/useAcceptInvite"
import type { InviteItem } from "../types/common"
import { INVITES_COLLECTION } from "../types/common"
import Header from "./Header"
import LoadingSpinner from "./LoadingSpinner"
import NotFoundPage from "./NotFoundPage"

const inviteConverter = makeConverter<InviteItem>()

const AcceptInvitePage: React.FC = () => {
  const { id: inviteId = "" } = useParams<{ id: string }>()

  const { authUser } = useActiveUserAuthorizationFromContext()
  const inviteRef = doc(db, INVITES_COLLECTION, inviteId).withConverter(
    inviteConverter,
  )
  const [invite, inviteLoading] = useDocumentData(inviteRef)

  const isInvitee = invite?.invitee_email === authUser.email

  const { loading: isLoadingAccept, handleAcceptInvite } = useAcceptInvite()

  useEffect(() => {
    if (!inviteId) {
      return
    }
  }, [inviteId])

  if (inviteLoading) {
    return <LoadingSpinner />
  }

  if (!invite || !isInvitee) {
    return <NotFoundPage />
  }

  let inviteBody: React.ReactNode

  if (invite.status === "PENDING") {
    inviteBody = (
      <div>
        <h3>
          {invite.creator_email} has invited you to join{" "}
          <strong>{invite.group_name}</strong>.
        </h3>
        <Button
          type="primary"
          onClick={() => handleAcceptInvite(inviteId)}
          disabled={isLoadingAccept}
        >
          Accept
        </Button>
      </div>
    )
  } else if (invite.status === "ACCEPTED") {
    inviteBody = (
      <div>
        <h3>
          This invite has been accepted. You can new view the group on the
          groups page
        </h3>
        <Button type="primary" href="/groups">
          View Groups
        </Button>
      </div>
    )
  } else {
    inviteBody = (
      <div>
        <h3>This invite has been canceled.</h3>
      </div>
    )
  }
  return (
    <>
      <Header title="Accept Invite" />
      <div className="m-8">{inviteBody}</div>
    </>
  )
}

export default AcceptInvitePage
