import Button from "antd/es/button"
import Drawer from "antd/es/drawer"
import type { User as AuthUser } from "firebase/auth"
import { PanelLeft } from "lucide-react"
import { useCallback, useState } from "react"

import ChatSendForm from "../../components/Chatv2/ChatSendForm"
import Header from "../../components/Header"
import { ChatProvider, useChat } from "../../hooks/useChat"
import { UrlPathParamChatSessionsProvider } from "../../hooks/useChat/urlSessionsProvider"
import useErrorPopup from "../../hooks/useErrorPopup"
import ChatSessionComponent from "./ChatSessionComponent"
import SessionsManager from "./SessionsManager"

interface ChatComponentProps {
  user: AuthUser
}

const ChatComponent: React.FC<ChatComponentProps> = ({ user }) => {
  const { createNewQuestionMessage, messagesLoading } = useChat()
  const { handleError } = useErrorPopup()
  const [chatHistoryOpen, setChatHistoryOpen] = useState<boolean>(false)

  const toggleChatHistoryOpen = useCallback(
    () => setChatHistoryOpen((prev) => !prev),
    [setChatHistoryOpen],
  )

  const sendChatMessage = useCallback(
    async (questionText: string) => {
      try {
        await createNewQuestionMessage(questionText)
      } catch (error) {
        handleError({ error, prefix: "Unable to send message" })
      }
    },
    [createNewQuestionMessage, handleError],
  )

  const onSubmitCTAQuestion = useCallback(
    async (question: string) => {
      // TODO(mgraczyk): Add sessionId here.
      await sendChatMessage(question)
    },
    [sendChatMessage],
  )

  return (
    <>
      <Header
        title="Knowledge Assistant"
        subtitle="Chat with your Knowledge Assistant"
      >
        <Button icon={<PanelLeft />} onClick={toggleChatHistoryOpen}>
          History
        </Button>
      </Header>

      <div className="mx-auto flex w-full max-w-[1600px] grow flex-col overflow-y-hidden">
        <ChatSessionComponent
          user={user}
          onSubmitCTAQuestion={onSubmitCTAQuestion}
        />
        <ChatSendForm
          id="chat-container"
          className="p-2"
          sendChatMessage={sendChatMessage}
          loading={messagesLoading}
        />
      </div>
      <Drawer
        open={chatHistoryOpen}
        onClose={() => setChatHistoryOpen(false)}
        title="Chat History"
      >
        <SessionsManager onSessionChange={() => setChatHistoryOpen(false)} />
      </Drawer>
    </>
  )
}

const ChatPage = ({ user }: { user: AuthUser }) => {
  return (
    <UrlPathParamChatSessionsProvider sourceKind="WEBAPP">
      <ChatProvider>
        <ChatComponent user={user} />
      </ChatProvider>
    </UrlPathParamChatSessionsProvider>
  )
}

export default ChatPage
