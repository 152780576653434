import { collection, query, where } from "firebase/firestore"
import { useCollectionData } from "react-firebase-hooks/firestore"

import {
  type PlanAndStatus,
  getPlanAndStatusFromSubscriptions,
} from "../billing/planDetails"
import type { Subscription } from "../billing/types"
import { makeConverter } from "../dbUtils"
import { db } from "../firebaseApp"
import { SUBSCRIPTIONS_COLLECTION } from "../types/common"

export const useActiveSubscription = (
  activeGroupOid: string,
): [PlanAndStatus, Subscription[] | undefined, boolean, Error | undefined] => {
  const subscriptionQuery = query(
    collection(db, SUBSCRIPTIONS_COLLECTION).withConverter(
      makeConverter<Subscription>(),
    ),
    where("groupOid", "==", activeGroupOid),
    where("isActive", "==", true),
  )

  const [subscriptions, subscriptionsLoading, subscriptionsError] =
    useCollectionData(subscriptionQuery)

  return [
    getPlanAndStatusFromSubscriptions(subscriptions),
    subscriptions,
    subscriptionsLoading,
    subscriptionsError,
  ]
}
