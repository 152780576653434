import Button from "antd/es/button"
import Dropdown from "antd/es/dropdown"
import Skeleton from "antd/es/skeleton"
import Typography from "antd/es/typography"
import { MoreHorizontal } from "lucide-react"
import { useState } from "react"
import { useSearchParams } from "react-router-dom"

import { deleteDiscussion, resolveDiscussion } from "../../discussions/api"
import type {
  Discussion,
  KnowledgeDiscussionProps,
  SheetDiscussionProps,
  UserDocumentDiscussionProps,
} from "../../discussions/types"
import { getErrorMessage } from "../../errors"
import { useGroupMembers } from "../../hooks/useGroupMembers"
import type { WorksheetRange } from "../../types/sheets"
import { toHumanReadableString } from "../../utils"
import FeedTitleCTA from "../QuestionnaireReview/FeedTitleCTA"
import TitleCTA from "../QuestionnaireReview/TitleCTA"
import DiscussionComponent from "./Discussion"

interface SharedProps {
  group_oid: string
  discussions?: Discussion[]
  discussionsError?: Error
  hideTitle?: boolean
}

type Props =
  | (SharedProps & KnowledgeDiscussionProps)
  | (SharedProps & UserDocumentDiscussionProps)
  | (SharedProps &
      SheetDiscussionProps & {
        useFeedCTA?: boolean
        onClickLocation: (location: WorksheetRange) => void
      })

const Discussions: React.FC<Props> = (props) => {
  const { group_oid, discussions, discussionsError, hideTitle, ...args } = props
  const [isDeleting, setIsDeleting] = useState(false)
  const [isResolving, setIsResolving] = useState(false)
  const [searchParams] = useSearchParams()

  const focusDiscussionOid = searchParams.get("discussion_oid")
  const [members, membersLoading] = useGroupMembers(group_oid)

  if (membersLoading) {
    return <Skeleton loading active />
  }

  const handleDeleteThread = async (discussion_oid: string) => {
    setIsDeleting(true)
    try {
      await deleteDiscussion({
        group_oid,
        discussion_oid,
      })
    } catch (error) {
      console.error("Error deleting discussion:", error)
    } finally {
      setIsDeleting(false)
    }
  }

  const handleResolveThread = async (
    discussion_oid: string,
    e: React.MouseEvent<HTMLElement>,
  ) => {
    e.stopPropagation()
    setIsResolving(true)
    try {
      await resolveDiscussion({
        group_oid,
        discussion_oid,
      })
    } catch (error) {
      console.error("Error resolving discussion:", error)
    } finally {
      setIsResolving(false)
    }
  }

  const renderDiscussion = (discussion: Discussion) => {
    return (
      <div
        key={discussion.oid}
        className={`mb-4 rounded-lg p-4 ${discussion.oid === focusDiscussionOid ? "bg-purple-100" : "bg-gray-25"}`}
      >
        <div className="flex items-center justify-between">
          <div>
            {args.kind === "SHEET" &&
              (discussion.kind === "SHEET" || !discussion.kind) &&
              (args.useFeedCTA ? (
                <FeedTitleCTA location={discussion.location} />
              ) : (
                <TitleCTA
                  location={discussion.location}
                  onClickLocation={args.onClickLocation}
                />
              ))}
            {discussion.resolution ? (
              <div className="text-xs text-gray-500">
                Resolved on{" "}
                {toHumanReadableString(discussion.resolution.timestamp)} by{" "}
                {discussion.assignment?.email}
              </div>
            ) : discussion.assignment ? (
              <div>
                <div className="text-xs text-gray-500">
                  Assigned to {discussion.assignment.email}
                </div>
                <Button
                  onClick={(e) => handleResolveThread(discussion.oid, e)}
                  type="primary"
                  size="small"
                  className="mt-2 text-xs"
                  disabled={isResolving}
                  loading={isResolving}
                >
                  Mark as resolved
                </Button>
              </div>
            ) : null}
          </div>
          <Dropdown
            menu={{
              items: [
                {
                  label: "Delete",
                  key: "delete",
                  onClick: () => void handleDeleteThread(discussion.oid),
                  disabled: isDeleting,
                },
              ],
            }}
          >
            <Button
              icon={<MoreHorizontal size={14} />}
              type="text"
              className="text-xs"
            />
          </Dropdown>
        </div>
        <div className="mt-2">
          <Skeleton active loading={isDeleting} avatar={false} title={false}>
            {discussion.deleted ? (
              <i className="text-xs text-gray-400">
                This discussion has been deleted
              </i>
            ) : (
              <DiscussionComponent
                group_oid={group_oid}
                members={members}
                discussion={discussion}
                {...args}
              />
            )}
          </Skeleton>
        </div>
      </div>
    )
  }

  return (
    <>
      {!hideTitle && <h3 className="mt-4">Comments</h3>}
      {discussionsError ? (
        <Typography.Text className="text-red-500">
          {getErrorMessage({
            error: discussionsError,
            prefix: "Unable to fetch discussions",
          })}
        </Typography.Text>
      ) : (
        <div className="pb-4">
          {discussions && discussions.length > 0
            ? discussions.map(renderDiscussion)
            : null}
          <div className="bg-gray-25 rounded-lg p-4">
            <DiscussionComponent
              group_oid={group_oid}
              members={members}
              {...args}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default Discussions
