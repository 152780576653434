import Empty from "antd/es/empty"
import Skeleton from "antd/es/skeleton"
import { collection, limit, orderBy, query } from "firebase/firestore"
import { useCollectionData } from "react-firebase-hooks/firestore"

import emptyEventsImage from "../../assets/img/empty_icons/source_document.svg"
import MarkdownEditor from "../../components/MarkdownEditor"
import { VOID_FUNCTION } from "../../constants"
import { makeConverter } from "../../dbUtils"
import { db } from "../../firebaseApp"
import {
  LIVE_ASSISTED_CALLS_COLLECTION,
  LIVE_ASSISTED_CALLS_LIVE_NOTES_SUBCOLLECTION,
} from "../../live/db"
import type { LiveNotes } from "../../live/types"

interface Props {
  callOid: string
}

// Get the LiveNotes for the callOid using firestore hooks
const Notetaker: React.FC<Props> = ({ callOid }) => {
  const notesQuery = query(
    collection(
      db,
      LIVE_ASSISTED_CALLS_COLLECTION,
      callOid,
      LIVE_ASSISTED_CALLS_LIVE_NOTES_SUBCOLLECTION,
    ).withConverter(makeConverter<LiveNotes>()),
    orderBy("created_at", "desc"),
    limit(1),
  )

  const [liveNotes, liveNotesLoading] = useCollectionData(notesQuery)
  const latestLiveNote = liveNotes?.[0]

  return (
    <div className="w-full grow overflow-y-scroll rounded-b-lg border border-t-0 p-3">
      <Skeleton title={false} loading={liveNotesLoading} active>
        {latestLiveNote ? (
          <MarkdownEditor
            onChange={VOID_FUNCTION}
            className=""
            readOnly
            initialContent={latestLiveNote.content}
          />
        ) : (
          <Empty
            image={emptyEventsImage}
            description="No live notes yet, start the call to take notes"
            className="m-auto flex flex-col items-center"
          />
        )}
      </Skeleton>
    </div>
  )
}

export default Notetaker
