import type { User as AuthUser } from "firebase/auth"
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useAuthState } from "react-firebase-hooks/auth"

import { auth } from "../firebaseApp"
import type { QuiltFirebaseClaims } from "../types/common"

interface QuiltAuthContextType {
  authUser: AuthUser | null | undefined
  claims: QuiltFirebaseClaims | undefined
  loading: boolean
  error: Error | undefined
  forceRefreshAuthUser: (user: AuthUser | null) => Promise<void>
}

const QuiltAuthContext = createContext<QuiltAuthContextType>({
  authUser: undefined,
  claims: undefined,
  loading: false,
  error: undefined,
  forceRefreshAuthUser: async () => {},
})

const QuiltAuthProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [user, userLoading, error] = useAuthState(auth)
  const [claimsLoading, setClaimsLoading] = useState<boolean>(true)
  const [claimsMap, setClaimsMap] = useState<
    Record<string, QuiltFirebaseClaims>
  >({})

  const refreshAuthUser = useCallback(
    async (user: AuthUser | null, force: boolean = false) => {
      if (!user) {
        setClaimsMap({})
        setClaimsLoading(false)
        return
      }

      // Check if refresh is required.
      setClaimsLoading(true)
      try {
        console.log("Refreshing user claims")
        const tokenResult = await user.getIdTokenResult(force)
        setClaimsMap({
          [user.uid]: tokenResult.claims as unknown as QuiltFirebaseClaims,
        })
      } catch (error) {
        console.error("Error fetching claims", error)
      } finally {
        setClaimsLoading(false)
      }
    },
    [],
  )

  useEffect(() => {
    return auth.onAuthStateChanged(async (user: AuthUser | null) => {
      await refreshAuthUser(user)
    })
  }, [refreshAuthUser])

  const claims = claimsMap[user?.uid ?? ""]
  const loading = userLoading || claimsLoading
  const value = useMemo(
    () => ({
      authUser: claims ? user : null,
      loading,
      error,
      claims,
      forceRefreshAuthUser: (user: AuthUser | null) =>
        refreshAuthUser(user, true),
    }),
    [user, loading, error, claims, refreshAuthUser],
  )

  return (
    <QuiltAuthContext.Provider value={value}>
      {children}
    </QuiltAuthContext.Provider>
  )
}

const useQuiltAuthState = (): QuiltAuthContextType => {
  return useContext(QuiltAuthContext)
}

export { QuiltAuthProvider, useQuiltAuthState }
