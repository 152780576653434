import type { User as AuthUser } from "firebase/auth"

import AddDocumentPickerButton from "../components/AddDocumentPickerButton"
import Header from "../components/Header"
import KnowledgeItemsManager from "../components/KnowledgeItemsManager"

const KnowledgeItemsTable: React.FC<{ user: AuthUser }> = ({ user }) => {
  return (
    <>
      <Header
        title="Answer Bank"
        subtitle="Previously answered questions we use to answer new questions"
      >
        <AddDocumentPickerButton
          buttonId="knowledge-items-actions"
          isCompletedQuestionnaireActions
        />
      </Header>
      <KnowledgeItemsManager user={user} isUserDocumentMode={false} />
    </>
  )
}

export default KnowledgeItemsTable
