import { useParams } from "react-router"

import Header from "../components/Header"
import LiveNotesTemplateEditor from "../live/LiveNotesTemplateEditor"

const LiveNotesTemplateEditorPage: React.FC = () => {
  const { liveNotesTemplateOid } = useParams<{
    liveNotesTemplateOid: string
  }>()
  if (!liveNotesTemplateOid) {
    throw new Error("liveNotesTemplateOid is required")
  }

  return (
    <>
      <Header
        title="Live Notes Template Editor"
        breadcrumbs={[
          {
            title: "Live Assistant",
            href: "/live-assistant",
          },
          {
            title: "Notes Templates",
            href: "/live-assistant/live-notes-templates",
          },
          {
            title: liveNotesTemplateOid,
            href: `/live-assistant/live-notes-templates/${liveNotesTemplateOid}`,
          },
        ]}
      />
      <LiveNotesTemplateEditor liveNotesTemplateOid={liveNotesTemplateOid} />
    </>
  )
}

export default LiveNotesTemplateEditorPage
