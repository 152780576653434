const strToUniqueNum = (str: string) =>
  Math.abs(str.split("").reduce((a, c) => (a << 5) - a + c.charCodeAt(0), 0))

export const getBackgroundColor = (seed: string) => {
  const colors = [
    "bg-red-500",
    "bg-purple-500",
    "bg-blue-500",
    "bg-green-500",
    "bg-gray-500",
    "bg-orange-500",
    "bg-pink-500",
  ]
  const uniqueNum = strToUniqueNum(seed)
  return colors[uniqueNum % colors.length]
}

export const getInitials = (name: string) => {
  return name[0].toUpperCase()
}
