import type { UserCredential } from "firebase/auth"

import { sendAnalyticsEvent } from "./index"

export const sendEventsAfterSignIn = (
  credential: UserCredential | undefined,
): void => {
  if (!credential) {
    return
  }
  sendAnalyticsEvent({
    event_type: "SIGNIN",
    surface: "WEB_SIGNUP_PAGE",
    event_data: {
      email: credential.user.email ?? "unknown",
    },
  })
}
