import Button from "antd/es/button"
import type { User as AuthUser } from "firebase/auth"
import { collection, doc, orderBy, query, where } from "firebase/firestore"
import { ListIcon } from "lucide-react"
import { useCallback, useState } from "react"
import {
  useCollectionData,
  useDocumentData,
} from "react-firebase-hooks/firestore"
import { useNavigate, useParams } from "react-router"
import { Link, useSearchParams } from "react-router-dom"

import Header from "../components/Header"
import LoadingSpinner from "../components/LoadingSpinner"
import NotFoundPage from "../components/NotFoundPage"
import { getConfirmation } from "../components/confirmAndExecute"
import { useActiveUserAuthorizationFromContext } from "../contexts/ActiveUserAuthorizationContext"
import { makeConverter } from "../dbUtils"
import { db } from "../firebaseApp"
import { deleteUserDocumentsApi } from "../knowledge/api"
import {
  USER_DOCUMENTS_COLLECTION,
  USER_DOCUMENTS_HISTORY_SUBCOLLECTION,
} from "../types/common"
import { sleep } from "../utils"
import KnowledgeItemForm from "./KnowledgeItemForm"
import { knowledgeItemHistoryConverter } from "./db"
import type { KnowledgeItem } from "./types"

interface Props {
  user: AuthUser
}

const KnowledgeItemPage: React.FC<Props> = () => {
  const { activeGroupOid } = useActiveUserAuthorizationFromContext()

  const [searchParams] = useSearchParams()

  const discussionOid = searchParams.get("discussion_oid")

  const { docOid = "" } = useParams<{ docOid: string }>()
  const docRef = doc(db, USER_DOCUMENTS_COLLECTION, docOid).withConverter(
    makeConverter<KnowledgeItem>(),
  )
  const [item, loading, error] = useDocumentData(docRef)

  const [removing, setRemoving] = useState<boolean>(false)
  const [removed, setRemoved] = useState<boolean>(false)
  const [sidebarOpen, setSidebarOpen] = useState(!!discussionOid)
  const navigate = useNavigate()

  const historyColRef = collection(
    db,
    USER_DOCUMENTS_COLLECTION,
    docOid,
    USER_DOCUMENTS_HISTORY_SUBCOLLECTION,
  ).withConverter(knowledgeItemHistoryConverter)

  const [history, historyLoading] = useCollectionData(
    query(
      historyColRef,
      where("group_oid", "==", activeGroupOid),
      orderBy("updated_at", "desc"),
    ),
  )

  const removeUserDocument = useCallback(async () => {
    if (!(await getConfirmation("This will permanently remove the item."))) {
      return
    }

    setRemoving(true)
    try {
      await deleteUserDocumentsApi({ oids: [docOid] })
      setRemoved(true)
      await sleep(3000)
      navigate("/knowledge-items")
    } finally {
      setRemoving(false)
    }
  }, [docOid, navigate])

  if (removed) {
    return (
      <div>
        <div className="m-4">Answer Bank item has been removed</div>
        <div>
          Redirecting to <Link to="/knowledge-items">your Answer Bank</Link> in
          a few seconds...
        </div>
      </div>
    )
  }

  if (loading || historyLoading) {
    return (
      <span className="mt-24">
        <LoadingSpinner />
      </span>
    )
  }

  if (!item) {
    // TODO(mgraczyk): Show better error message (404, etc)
    if (error) {
      if (error.code === "permission-denied") {
        return <NotFoundPage />
      }

      return (
        <span className="m-4">
          Failed to load Answer Bank item: {JSON.stringify(error)}
        </span>
      )
    } else {
      return <span className="m-4">Failed to load Answer Bank item</span>
    }
  }

  return (
    <>
      <Header
        title="Edit Answer Bank Item"
        breadcrumbs={[
          {
            title: "Answer Bank",
            href: "/knowledge-items",
          },
          {
            title: `${docOid}`,
            href: `/knowledge-items/${docOid}`,
          },
        ]}
      >
        <div className="flex items-center">
          <Button onClick={removeUserDocument} className="font-bold" danger>
            Remove
          </Button>
          <Button
            icon={<ListIcon />}
            className="ml-4"
            onClick={() => setSidebarOpen(true)}
          />
        </div>
      </Header>

      <div className="flex-1 overflow-hidden">
        <KnowledgeItemForm
          item={item}
          removing={removing}
          history={history}
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
      </div>
    </>
  )
}

export default KnowledgeItemPage
