import { functionsAxiosInstance } from "../api"
import type { GetRenderedConfluencePageRequest } from "./types"

export const getRenderedConfluencePage = async (
  request: GetRenderedConfluencePageRequest,
): Promise<string> => {
  const params = new URLSearchParams(
    request as unknown as Record<string, string>,
  )
  return (
    await functionsAxiosInstance.get<string>(
      `/v1/getRenderedConfluencePage?${params.toString()}`,
    )
  ).data
}
