import { CircleAlertIcon } from "lucide-react"

import { getFrontendUrl } from "../../constants"
import type { UserFacingError } from "../../errors"
import { UserFacingErrorType } from "../../exceptions/userFacingErrorTypes"
import { type Size, getSpaceClassName } from "./../chatShared"

const ChatMessageErrorComponent = ({
  error,
  size,
}: {
  error: UserFacingError
  size: Size
}) => {
  const spaceClassName = getSpaceClassName(size)
  const iconSize = size === "small" ? "1.5rem" : "4rem"
  return (
    <div className={"mt-4 flex items-center " + spaceClassName}>
      <CircleAlertIcon size={iconSize} className="text-red-500" />
      <span className="w-full resize-none text-pretty">
        {error.error === UserFacingErrorType.USER_HAS_NO_DOCUMENTS ? (
          <>
            Please upload at least one item to
            <a
              href={`${getFrontendUrl()}/source-documents`}
              className="text-purple-500 hover:text-purple-600"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              Source Documents{" "}
            </a>
            or
            <a
              href={`${getFrontendUrl()}/knowledge-items`}
              className="text-purple-500 hover:text-purple-600"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              Answer Bank{" "}
            </a>
            to get an answer.
          </>
        ) : (
          "Unable to answer at this time. Please try again later."
        )}
      </span>
    </div>
  )
}

export default ChatMessageErrorComponent
