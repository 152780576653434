import Button from "antd/es/button"
import Form from "antd/es/form"
import Input from "antd/es/input"
import Popover from "antd/es/popover"
import Select from "antd/es/select"
import { useCallback, useState } from "react"

import { adminCreateGroupTag } from "../../groups/api"
import type { Group } from "../../groups/types"
import { useKeydown } from "../../hooks/events"
import useErrorPopup from "../../hooks/useErrorPopup"
import Tag, { TAGCOLORS_CLASSMAP } from "../../tags/Tag"
import { TAGCOLORS, type TagColor } from "../../tags/types"
import { capitalizeFirstLetter } from "../../utils"

interface AddTagFormProps {
  onClose: () => void
  group: Group
}

const AddTagForm: React.FC<AddTagFormProps> = ({ onClose, group }) => {
  const [name, setName] = useState<string>("")
  const [color, setColor] = useState<TagColor>()
  const [isSubmitting, setSubmitting] = useState<boolean>(false)

  useKeydown(27 /* Escape */, onClose)

  const { handleSuccess, handleError } = useErrorPopup()

  const onSubmit = async () => {
    if (!name || !color) {
      return
    }
    setSubmitting(true)
    try {
      await adminCreateGroupTag({
        name: name,
        color: color,
        group_oid: group.oid,
      })
      setName("")
      setColor(undefined)
      onClose()
      handleSuccess("Tag added successfully!")
    } catch (error) {
      handleError({ error, prefix: "Couldn't add tag" })
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <>
      <Form onFinish={onSubmit} layout="inline">
        <Form.Item
          name="name"
          rules={[{ required: true, message: "Please input the tag name" }]}
        >
          <Input
            placeholder="Enter tag name"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
        </Form.Item>
        <Form.Item
          name="color"
          rules={[{ required: true, message: "Please select the tag color!" }]}
          className="w-40"
        >
          <Select
            placeholder="Select tag color"
            onChange={(value) => setColor(value)}
            value={color}
          >
            {TAGCOLORS.map((color) => (
              <Select.Option key={color} value={color}>
                <div className="flex items-center">
                  <div
                    className={`mr-2 h-3 w-3 rounded-full ${TAGCOLORS_CLASSMAP[color]}`}
                  ></div>{" "}
                  {capitalizeFirstLetter(color)}
                </div>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={isSubmitting}
            disabled={isSubmitting || !name || !color}
          >
            Add
          </Button>
        </Form.Item>
      </Form>
      {color && name && (
        <>
          <div className="mt-4 text-sm text-slate-500">Preview</div>
          <Tag tag={{ color, name }} />
        </>
      )}
    </>
  )
}

interface AddTagButtonProps {
  group: Group
}

const AddTagButton: React.FC<AddTagButtonProps> = ({ group }) => {
  const [open, setOpen] = useState(false)

  const hide = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  return (
    <Popover
      content={<AddTagForm onClose={hide} group={group} />}
      trigger="click"
      placement="bottomLeft"
      open={open}
      onOpenChange={setOpen}
    >
      <Button type="primary" title="Add Tag">
        Add Tag
      </Button>
    </Popover>
  )
}

export default AddTagButton
