import { Card } from "antd"
import Button from "antd/es/button"
import Empty from "antd/es/empty"
import Skeleton from "antd/es/skeleton"
import { collection } from "firebase/firestore"
import { ArrowRight, PlusIcon } from "lucide-react"
import { useMemo } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { Link } from "react-router-dom"

import Header from "../components/Header"
import MarkdownRenderer from "../components/MarkdownRenderer"
import { useActiveUserAuthorizationFromContext } from "../contexts/ActiveUserAuthorizationContext"
import { makeConverter } from "../dbUtils"
import { db } from "../firebaseApp"
import CardFilterPopover, { useCardFilters } from "../live/CardFilterPopover"
import type { StaticCardContent } from "../live/types"
import { STATIC_CARD_CONTENT_COLLECTION } from "../live/types"
import { GROUPS_COLLECTION } from "../types/common"

const CardSkeleton: React.FC = () => {
  return (
    <Card
      classNames={{ body: "!p-0" }}
      className="mb-4 max-w-[1200px] overflow-hidden border border-gray-100"
    >
      <div className="block p-4">
        <div className="flex items-center justify-between rounded-md">
          <div className="mb-2 text-xs font-bold uppercase text-gray-400">
            Condition Prompt
          </div>
          <ArrowRight size={20} className="text-purple-400 opacity-0" />
        </div>
        <Skeleton active title={false} paragraph={{ rows: 1 }} />
      </div>
      <div className="p-4">
        <div className="mb-2 text-xs font-bold uppercase text-gray-400">
          Card Content
        </div>
        <div className="group relative mb-2 min-h-12 w-full rounded border border-gray-50 bg-white p-3">
          <Skeleton active paragraph={{ rows: 2 }} />
        </div>

        <div className="mt-4 flex items-center justify-end gap-4 text-sm text-gray-500">
          <Skeleton.Button active size="small" style={{ width: 200 }} />
          <span>•</span>
          <Skeleton.Button active size="small" style={{ width: 150 }} />
        </div>
      </div>
    </Card>
  )
}
const CardPreview: React.FC<{ card: StaticCardContent; className: string }> = ({
  card,
  className,
}) => {
  return (
    <Card
      classNames={{ body: "!p-0" }}
      className={`max-w-[1200px] overflow-hidden border border-gray-100 ${className}`}
    >
      <Link to={card.oid} className="group block p-4 hover:bg-purple-50">
        <div className="flex items-center justify-between rounded-md">
          <div className="mb-2 text-xs font-bold uppercase text-gray-400 group-hover:text-purple-500">
            Condition Prompt
          </div>
          <ArrowRight
            size={20}
            className="text-purple-400 opacity-0 transition-all duration-200 group-hover:opacity-100"
          />
        </div>
        <div>{card.condition_prompt}</div>
      </Link>
      <div className="p-4">
        <div className="mb-2 text-xs font-bold uppercase text-gray-400 transition-colors duration-200 group-hover:text-purple-500">
          Card Content
        </div>
        <div className="group relative mb-2 min-h-12 w-full rounded border border-gray-50 bg-white p-3">
          <MarkdownRenderer content={card.content} className="w-full" />
        </div>

        <div className="mt-4 flex items-center justify-end gap-4 text-sm text-gray-500">
          <span>Created by: {card.created_by.email}</span>
          <span>•</span>
          <span>Updated: {card.updated_at.toDate().toLocaleDateString()}</span>
        </div>
      </div>
    </Card>
  )
}

interface CardsListProps {
  className?: string
}

const CardsList: React.FC<CardsListProps> = ({ className = "" }) => {
  const { activeGroupOid } = useActiveUserAuthorizationFromContext()
  const [cards, cardsLoading] = useCollectionData(
    collection(
      db,
      GROUPS_COLLECTION,
      activeGroupOid,
      STATIC_CARD_CONTENT_COLLECTION,
    ).withConverter(makeConverter<StaticCardContent>()),
  )

  const { filterState } = useCardFilters()

  const filteredCards = useMemo(() => {
    if (!cards) return []

    return cards.filter((card) => {
      if (filterState.creator && card.created_by.uid !== filterState.creator) {
        return false
      }
      return true
    })
  }, [cards, filterState])

  return (
    <div
      className={`flex h-full w-full grow flex-col overflow-y-hidden p-8 ${className}`}
    >
      {cardsLoading ? (
        <div>
          <CardSkeleton />
          <CardSkeleton />
          <CardSkeleton />
          <CardSkeleton />
        </div>
      ) : cards?.length === 0 ? (
        <Empty description="No cards yet, click Add Card to create one" />
      ) : (
        <div className="flex h-full flex-col">
          {/* Fixed header section */}
          <div className="flex-none">
            <CardFilterPopover cards={cards} className="mb-4" />
          </div>

          {/* Scrollable cards section */}
          <div className="flex-1 grow overflow-y-auto">
            <div className="flex flex-col">
              {filteredCards.map((card) => (
                <CardPreview className="mb-4" key={card.oid} card={card} />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const LiveStaticCardsPage: React.FC = () => {
  return (
    <>
      <Header
        title="Live Cards"
        breadcrumbs={[
          {
            title: "Live Assistant",
            href: "/live-assistant",
          },
          {
            title: "Cards",
            href: "/live-assistant/cards",
          },
        ]}
      >
        <Link to="/live-assistant/cards/new">
          <Button icon={<PlusIcon />} type="primary">
            Add Card
          </Button>
        </Link>
      </Header>
      <CardsList />
    </>
  )
}

export default LiveStaticCardsPage
