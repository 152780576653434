import { useEffect, useState } from "react"

import type { AnswerReferenceKind } from "../types/answerer"
import type { ReferenceSearchHit } from "./types"
import useReferencesSearch from "./useReferencesSearch"

interface Result {
  loading: boolean
  hasSearchQuery: boolean
  searchResults: ReferenceSearchHit[] | undefined
  setQuery: (query: string) => void
}

const useSearchResults = (docType: AnswerReferenceKind | "ALL"): Result => {
  const { loading, searchFunction } = useReferencesSearch(docType)
  const [searchResults, setSearchResults] = useState<
    ReferenceSearchHit[] | undefined
  >()
  const [query, setQuery] = useState<string | undefined>()

  useEffect(() => {
    void (async () => {
      if (loading) {
        setSearchResults(undefined)
        return
      }
      if (!query) {
        setSearchResults(undefined)
        return
      }
      setSearchResults(await searchFunction(query))
    })()
  }, [loading, query, searchFunction])

  return {
    loading,
    hasSearchQuery: !!query,
    searchResults,
    setQuery,
  }
}

export default useSearchResults
