import QuiltLogo from "/src/assets/img/logo_only.svg"
import type { IntegrationName } from "carbon-connect"
import { createRoot } from "react-dom/client"
import { useAuthState } from "react-firebase-hooks/auth"

import { getCarbonAccessToken } from "./carbon/api"
import LoadingSpinner from "./components/LoadingSpinner"
import { PRIVACY_URL, TERMS_URL } from "./constants"
import { auth } from "./firebaseApp"
import "./renderCarbonConnect.css"
import type { UserDocumentSourceKind } from "./types/userDocument"

// WARNING: This component needs to be rendered in an iframe or separate page
export const renderCarbonConnect = async () => {
  const carbonConnect = await import("carbon-connect")
  const { IntegrationName, CarbonConnect } = carbonConnect

  const CarbonConnectDataWrapper: React.FC = () => {
    const [user, userLoading, userError] = useAuthState(auth)

    const queryParams = new URLSearchParams(window.location.search)
    const integrationNameParam = queryParams.get("integrationName")

    const CARBON_INTEGRATION_QUILT_SOURCE_MAPPING: Partial<
      Record<keyof typeof IntegrationName, UserDocumentSourceKind>
    > = {
      [IntegrationName.ONEDRIVE]: "CARBON_ONEDRIVE",
      [IntegrationName.SHAREPOINT]: "CARBON_SHAREPOINT",
      [IntegrationName.CONFLUENCE]: "CARBON_CONFLUENCE",
      [IntegrationName.NOTION]: "CARBON_NOTION",
      [IntegrationName.SLACK]: "CARBON_SLACK",
      [IntegrationName.SALESFORCE]: "CARBON_SALESFORCE",
    }

    const ENTRY_POINT_BY_INTEGRATION_NAME: Partial<
      Record<keyof typeof IntegrationName, IntegrationName>
    > = {
      [IntegrationName.CONFLUENCE]: IntegrationName.CONFLUENCE,
      [IntegrationName.NOTION]: IntegrationName.NOTION,
      [IntegrationName.ONEDRIVE]: IntegrationName.ONEDRIVE,
      [IntegrationName.SHAREPOINT]: IntegrationName.SHAREPOINT,
      [IntegrationName.SALESFORCE]: IntegrationName.SALESFORCE,
    }

    const integrationName =
      integrationNameParam &&
      integrationNameParam in CARBON_INTEGRATION_QUILT_SOURCE_MAPPING
        ? (integrationNameParam as keyof typeof IntegrationName)
        : undefined

    if (integrationName === undefined) {
      return <div className="cc-text-red-500">Invalid integration name</div>
    }

    if (userLoading) {
      return <LoadingSpinner />
    }

    if (userError || !user) {
      return <div className="cc-text-red-500">Error loading user</div>
    }

    return (
      <CarbonConnect
        orgName="Quilt"
        brandIcon={QuiltLogo}
        tags={{
          creator_uid: user.uid,
          creator_email: user.email || "",
        }}
        tokenFetcher={async () => {
          const accessTokenResponse = (await getCarbonAccessToken()).data
          return {
            access_token: accessTokenResponse,
          }
        }}
        onSuccess={(data) => {
          console.log("CarbonConnect success", data)
          setTimeout(() => {
            window.close()
          }, 2000)
        }}
        enabledIntegrations={[
          {
            id: IntegrationName[integrationName],
            useCarbonFilePicker: true,
            syncFilesOnConnection: false,
            enableAutoSync: true,
            skipEmbeddingGeneration: true,
          },
        ]}
        entryPoint={
          ENTRY_POINT_BY_INTEGRATION_NAME[integrationName] ?? "INTEGRATION_LIST"
        }
        theme="light"
        open
        alwaysOpen
        privacyPolicyURL={PRIVACY_URL}
        tosURL={TERMS_URL}
      />
    )
  }

  createRoot(document.getElementById("root")!).render(
    <CarbonConnectDataWrapper />,
  )
}
