export const getStripeBaseProductId = () => {
  const stripeBaseProductId = import.meta.env.VITE_STRIPE_BASE_PRODUCT_ID
  if (!stripeBaseProductId) {
    throw new Error("Env var VITE_STRIPE_BASE_PRODUCT_ID must be set")
  }
  return stripeBaseProductId
}

export const getStripeTeamProductId = () => {
  const stripeTeamProductId = import.meta.env.VITE_STRIPE_TEAM_PRODUCT_ID
  if (!stripeTeamProductId) {
    throw new Error("Env var VITE_STRIPE_TEAM_PRODUCT_ID must be set")
  }
  return stripeTeamProductId
}

export const getStripeBasePriceId = () => {
  const stripeBasePriceId = import.meta.env.VITE_STRIPE_BASE_PRICE_ID
  if (!stripeBasePriceId) {
    throw new Error("Env var VITE_STRIPE_BASE_PRICE_ID must be set")
  }
  return stripeBasePriceId
}

export const getStripeTeamPriceId = () => {
  const stripeTeamPriceId = import.meta.env.VITE_STRIPE_TEAM_PRICE_ID
  if (!stripeTeamPriceId) {
    throw new Error("Env var VITE_STRIPE_TEAM_PRICE_ID must be set")
  }
  return stripeTeamPriceId
}
