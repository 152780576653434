import { useContext } from "react"

import type { ChatContextType } from "./provider"
import { ChatContext, ChatProvider } from "./provider"
import {
  ChatStaticSessionsProvider,
  LocalStorageChatSessionsProvider,
  WebPortalStaticSessionsProvider,
} from "./sessionsProvider"

const useChat = (): ChatContextType => {
  return useContext(ChatContext)
}

export {
  useChat,
  ChatProvider,
  type ChatContextType,
  LocalStorageChatSessionsProvider,
  ChatStaticSessionsProvider,
  WebPortalStaticSessionsProvider,
}
