import React from "react"

export const CommentWithMentions: React.FC<{
  comment: string
  className?: string
}> = ({ comment, className = "" }) => {
  const words = comment.split(" ")

  return (
    <div className={className}>
      {words.map((word, index) => {
        const isLastWord = index === words.length - 1
        const mentionElement = word.startsWith("@") ? (
          <span key={index} className="font-semibold text-blue-500">
            {word}
          </span>
        ) : (
          <span key={index}>{word}</span>
        )

        return (
          <React.Fragment key={index}>
            {mentionElement}
            {!isLastWord && " "}
          </React.Fragment>
        )
      })}
    </div>
  )
}
