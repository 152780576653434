import { Info } from "lucide-react"

import { getFrontendUrl } from "../../constants"
import type { KnowledgeItem } from "../../knowledge/types"
import { type AnswerReference } from "../../types/answerer"
import type { UserDocument } from "../../types/userDocument"
import { type UserDocumentPiece } from "../../types/userDocument"
import { toHumanReadableString } from "../../utils"

interface ReferenceProps {
  path: string
  contentUrl: string
  title: string
  subtitle: string | null
  content?: React.ReactNode
}

const getKnowledgeItemReferenceProps = (
  knowledgeItem: KnowledgeItem,
  designVersion: "v2" | "popover",
): ReferenceProps => {
  const path = `/knowledge-items/${knowledgeItem.oid}`
  return {
    path,
    contentUrl: getFrontendUrl() + path,
    title: knowledgeItem.content.question.primary,
    subtitle: knowledgeItem.content.question.secondary ?? "",
    content: (
      <div>
        <div>{knowledgeItem.content.answer.primary}</div>
        {knowledgeItem.content.answer.secondary && (
          <div className="text-sm text-slate-500">
            {knowledgeItem.content.answer.secondary}
          </div>
        )}
        {designVersion === "v2" ? (
          <div className="mt-2 flex items-start text-sm text-slate-500">
            <div>
              {`Added by ${knowledgeItem.creator.email} (last updated ${toHumanReadableString(knowledgeItem.updated_at)})`}
            </div>
          </div>
        ) : (
          <>
            <hr className="my-2 border-b border-gray-100" />
            <div className="flex items-start text-sm text-slate-500">
              <div>
                <Info size={16} className="mr-2 mt-1" />
              </div>
              <div>
                Added by {knowledgeItem.creator.email}
                <br />
                Last updated {toHumanReadableString(knowledgeItem.updated_at)}
              </div>
            </div>
          </>
        )}
      </div>
    ),
  }
}

const getUserDocumentPagePath = (
  userDocument: UserDocument,
  piece: UserDocumentPiece,
): string => {
  return `/source-documents/${userDocument.oid}?pieceOid=${piece.oid}`
}

const getUserDocumentContentUrl = (
  userDocument: UserDocument,
  piece: UserDocumentPiece,
): string => {
  // TODO(mgraczyk): Store an external URL instead.
  if (userDocument.source_kind === "GOOGLE_DRIVE") {
    return `https://drive.google.com/file/d/${userDocument.external_document_id}`
  }
  if (userDocument.source_kind === "PUBLIC_WEB_PAGE") {
    const externalUrl = userDocument.external_document_id
    if (!externalUrl.startsWith("http")) {
      return `https://${externalUrl}`
    } else {
      return externalUrl
    }
  }

  return `${getFrontendUrl()}${getUserDocumentPagePath(userDocument, piece)}`
}

const getPieceReferenceProps = (
  userDocument: UserDocument,
  piece: UserDocumentPiece,
  designVersion: "v2" | "popover",
): ReferenceProps => {
  let content: React.ReactNode
  if (piece.kind === "RAW_TEXT" || !piece.kind) {
    content = (
      <div>
        <div>{piece.content}</div>
        {designVersion === "v2" ? (
          <div className="mt-2 flex items-start text-sm text-slate-500">
            <div>Added on {toHumanReadableString(userDocument.created_at)}</div>
          </div>
        ) : (
          <>
            <hr className="my-2 border-b border-gray-100" />
            <div className="flex items-start text-sm text-slate-500">
              <div>
                <Info size={16} className="mr-2 mt-1" />
              </div>
              <div>
                Added on {toHumanReadableString(userDocument.created_at)}
              </div>
            </div>
          </>
        )}
      </div>
    )
  } else {
    // TODO(mgraczyk): Handle AnsweredQuestionDocumentPiece
    content = "Click for details."
  }

  return {
    path: getUserDocumentPagePath(userDocument, piece),
    contentUrl: getUserDocumentContentUrl(userDocument, piece),
    title: userDocument.title ?? "Source Document",
    subtitle: "",
    content,
  }
}

const getFallbackSourceDocumentReferenceProps = (
  answerReference: AnswerReference,
  userDocument: UserDocument | undefined,
) => {
  let path = `/source-documents/${userDocument?.oid ?? answerReference.document_oid}`
  const piece_oid = answerReference.piece_oid
  if (piece_oid) {
    path += `?pieceOid=${piece_oid}`
  }
  return {
    path,
    title: userDocument?.title ?? "Source Document",
    subtitle: "",
    contentUrl: getFrontendUrl() + path,
    content: answerReference.content,
  }
}

export const getReferenceProps = (
  answerReference: AnswerReference,
  designVersion: "v2" | "popover",
  knowledgeItem?: KnowledgeItem,
  userDocument?: UserDocument,
  piece?: UserDocumentPiece,
): ReferenceProps => {
  if (knowledgeItem) {
    return getKnowledgeItemReferenceProps(knowledgeItem, designVersion)
  } else if (userDocument && piece) {
    return getPieceReferenceProps(userDocument, piece, designVersion)
  } else if (answerReference.knowledge_item_oid) {
    const path = `/knowledge-items/${answerReference.knowledge_item_oid}`
    return {
      path,
      title: "Answer Bank Reference",
      subtitle: "",
      contentUrl: getFrontendUrl() + path,
    }
  } else if (answerReference.document_oid) {
    return getFallbackSourceDocumentReferenceProps(
      answerReference,
      userDocument,
    )
  } else {
    console.error("Invalid reference: ", answerReference)
    return {
      path: "",
      title: "Source Document",
      subtitle: "",
      contentUrl: answerReference.reference_url,
    }
  }
}
