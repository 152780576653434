import "./LoadingSpinner.css"

const LoadingSpinner: React.FC<{ className?: string }> = ({
  className = "",
}) => {
  return (
    <div
      className={
        "align-center flex h-full items-center justify-center " + className
      }
    >
      <div className="loading-spinner__dot-carousel"></div>
    </div>
  )
}
export default LoadingSpinner
