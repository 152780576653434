import Button from "antd/es/button"
import Popover from "antd/es/popover"
import Select from "antd/es/select"
import { VoteIcon } from "lucide-react"
import { useCallback, useState } from "react"

import { useActiveUserAuthorizationFromContext } from "../contexts/ActiveUserAuthorizationContext"
import { useKeydown } from "../hooks/events"
import useErrorPopup from "../hooks/useErrorPopup"
import { useGroupMembers } from "../hooks/useGroupMembers"
import { assignKnowledgeItems, unassignKnowledgeItems } from "../knowledge/api"

interface Props {
  onClose: () => void
  oids: string[]
  onFinish: () => void
  isUserDocument: boolean
}

const BulkAssignForm: React.FC<Props> = ({
  onClose,
  oids,
  onFinish,
  isUserDocument,
}) => {
  const [selectedMember, setSelectedMember] = useState<string | undefined>()
  const [message, setMessage] = useState<string>("")
  const [assigning, setAssigning] = useState<boolean>(false)

  const { activeGroupOid } = useActiveUserAuthorizationFromContext()
  const [members, loading] = useGroupMembers(activeGroupOid)
  const { handleSuccess, handleError } = useErrorPopup()

  useKeydown(27 /*escape*/, onClose)

  const member = members?.find((m) => m.uid === selectedMember)
  const onSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      if (!member) {
        return // we have the submit button disabled if there is no mention
      }

      setAssigning(true)
      try {
        await assignKnowledgeItems({
          requests: oids.map((oid) => ({
            oid,
            kind: "ASSIGN",
            message,
            assign_to: {
              uid: member.uid,
              email: member.email,
            },
          })),
        })
        handleSuccess(
          oids.length === 1
            ? `Assigned ${isUserDocument ? "user document" : "knowledge item"}`
            : `Assigned ${isUserDocument ? "user documents" : "knowledge items"}`,
        )
        setMessage("")
        onFinish()
        onClose()
      } catch (error) {
        handleError({ error, prefix: "Couldn't assign knowledge items" })
      } finally {
        setAssigning(false)
      }
    },
    [
      handleSuccess,
      handleError,
      message,
      onClose,
      oids,
      member,
      onFinish,
      isUserDocument,
    ],
  )

  return (
    <form className="w-[400px] text-sm" action="" onSubmit={onSubmit}>
      <Select
        placeholder={loading ? "loading..." : "Select the assignee..."}
        value={selectedMember}
        onChange={setSelectedMember}
        className="w-full"
        loading={loading}
      >
        {members?.map((member) => (
          <Select.Option key={member.uid} value={member.uid}>
            {member.email}
          </Select.Option>
        ))}
      </Select>
      <Button
        disabled={assigning || !selectedMember || loading}
        className="mt-2"
        type="primary"
        htmlType="submit"
      >
        {assigning ? "Assigning..." : "Assign"}
      </Button>
    </form>
  )
}

type BulkAssignButtonProps = Omit<Props, "onClose">
const BulkAssignButton: React.FC<BulkAssignButtonProps> = ({
  oids,
  onFinish,
  isUserDocument,
}) => {
  const [open, setOpen] = useState(false)
  const [unassigning, setUnassigning] = useState(false)
  const { handleSuccess, handleError } = useErrorPopup()
  const hide = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const unAssign = useCallback(async () => {
    setUnassigning(true)
    try {
      await unassignKnowledgeItems({
        requests: oids.map((oid) => ({
          oid,
          kind: "UNASSIGN",
        })),
      })
      handleSuccess(
        oids.length === 1
          ? `Unassigned ${isUserDocument ? "user document" : "knowledge item"}`
          : `Unassigned ${isUserDocument ? "user documents" : "knowledge items"}`,
      )
      onFinish()
    } catch (error) {
      handleError({ error, prefix: "Couldn't unassign knowledge items" })
    } finally {
      setUnassigning(false)
    }
  }, [handleSuccess, handleError, oids, onFinish, isUserDocument])
  return (
    <>
      <Popover
        content={
          <BulkAssignForm
            oids={oids}
            onClose={hide}
            onFinish={onFinish}
            isUserDocument={isUserDocument}
          />
        }
        trigger="click"
        placement="bottomLeft"
        open={open}
        onOpenChange={setOpen}
      >
        <Button
          type="primary"
          size="small"
          icon={<VoteIcon size={14} />}
          title="Assign"
          disabled={unassigning}
          className="flex items-center justify-center"
        >
          <span className="text-[12px] font-bold">Assign</span>
        </Button>
      </Popover>
      <Button
        type="primary"
        size="small"
        icon={<VoteIcon size={14} />}
        title="Assign"
        disabled={unassigning}
        loading={unassigning}
        className="flex items-center justify-center"
        onClick={unAssign}
      >
        <span className="text-[12px] font-bold">Unassign</span>
      </Button>
    </>
  )
}

export default BulkAssignButton
