import { type AuthError } from "firebase/auth"
import { useCallback, useEffect } from "react"
import {
  useSignInWithGoogle,
  useSignInWithMicrosoft,
} from "react-firebase-hooks/auth"

import { sendEventsAfterSignIn } from "../analytics/signin"
import GoogleLetterLogo from "../components/GoogleLetterLogo"
import MicrosoftLetterLogo from "../components/MicrosoftLetterLogo"
import SignInButton from "../components/SignInButton"
import { GOOGLE_EMAIL_OAUTH_SCOPE } from "../constants"
import { auth } from "../firebaseApp"

const getErrorMessage = (error: unknown): string | undefined => {
  if (!error) {
    return
  }

  // TODO(mgraczyk): For some reason Firebase can't see the window close
  // event, so it just times out and sets this value. Would be nice to react
  // faster.
  if (
    error instanceof Error &&
    error.message.includes("auth/popup-closed-by-user")
  ) {
    // User closed, ignore error.
    return
  }

  if (
    error instanceof Error &&
    error.message.includes("auth/account-exists-with-different-credential")
  ) {
    const asAuthError = error as AuthError & {
      customData?: { _tokenResponse?: { providerId: string } }
    }
    if (asAuthError?.customData?._tokenResponse?.providerId === "google.com") {
      return "Please sign in with your Microsoft account instead"
    } else {
      return "You previously signed in with Google, you may need to use Google instead"
    }
    return
  }

  if (error instanceof Error && error.message.includes("non-work email")) {
    return "Quilt only supports work emails. Please sign in with your work email or contact us for help."
  }

  return "Error signing in, try again later and contact Quilt via slack or support@quilt.app"
}

interface Props {
  actionText: string
  setErrorString: (error: string) => void
}

const SocialLoginForm: React.FC<Props> = ({ actionText, setErrorString }) => {
  const [signInWithGoogle, , googleLoading, googleError] =
    useSignInWithGoogle(auth)
  const [signInWithMicrosoft, , microsoftLoading, microsoftError] =
    useSignInWithMicrosoft(auth)

  const errorStr = getErrorMessage(googleError ?? microsoftError)

  useEffect(() => {
    if (errorStr) {
      setErrorString(errorStr)
    }
  }, [errorStr, setErrorString])

  const onClickGoogleSignIn = useCallback(async () => {
    const requiredScopes: string[] = [GOOGLE_EMAIL_OAUTH_SCOPE]
    const result = await signInWithGoogle(requiredScopes)
    sendEventsAfterSignIn(result)
  }, [signInWithGoogle])

  const onClickMicrosoftSignIn = useCallback(async () => {
    const requiredScopes: string[] = ["User.Read", "email", "openid", "profile"]
    const result = await signInWithMicrosoft(requiredScopes)
    sendEventsAfterSignIn(result)
  }, [signInWithMicrosoft])

  const googleText = `${actionText} with Google`
  const microsoftText = `${actionText} with Microsoft`
  return (
    <>
      <SignInButton
        logo={<GoogleLetterLogo />}
        loading={googleLoading}
        buttonText={googleText}
        onClick={onClickGoogleSignIn}
      />
      <SignInButton
        logo={<MicrosoftLetterLogo />}
        loading={microsoftLoading}
        buttonText={microsoftText}
        onClick={onClickMicrosoftSignIn}
      />
    </>
  )
}

export default SocialLoginForm
