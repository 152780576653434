import Button from "antd/es/button"
import Checkbox from "antd/es/checkbox"
import Form from "antd/es/form"
import Result from "antd/es/result"
import { doc } from "firebase/firestore"
import { useCallback, useState } from "react"
import { useDocumentData } from "react-firebase-hooks/firestore"

import { withHeader } from "../components/Header"
import LoadingSpinner from "../components/LoadingSpinner"
import { useActiveUserAuthorizationFromContext } from "../contexts/ActiveUserAuthorizationContext"
import { makeConverter } from "../dbUtils"
import { db } from "../firebaseApp"
import useErrorPopup from "../hooks/useErrorPopup"
import { updateNotificationPreferences } from "../notifications/api"
import type {
  Notification,
  NotificationPreferences,
} from "../notifications/types"
import { USERS_COLLECTION, type User } from "../types/common"

const NOTIFICATION_INFOS: { kind: Notification["kind"]; label: string }[] = [
  {
    kind: "QUESTIONNAIRE_JOB_COMPLETED",
    label: "Questionnaire Assistant finished processing",
  },
  {
    kind: "DISCUSSION_ASSIGNED",
    label: "You were assigned a discussion",
  },
]

const NotificationPreferencesForm: React.FC = () => {
  const { authUser } = useActiveUserAuthorizationFromContext()
  const [form] = Form.useForm<NotificationPreferences>()
  const [userData, loading, loadError] = useDocumentData(
    doc(db, USERS_COLLECTION, authUser.uid).withConverter(
      makeConverter<User>(),
    ),
  )
  const [saving, setSaving] = useState<boolean>(false)
  const { handleSuccess, handleError } = useErrorPopup()
  const disabledValues = Form.useWatch("disabled", form)

  const onSave = useCallback(
    async (preferences: NotificationPreferences) => {
      try {
        setSaving(true)
        await updateNotificationPreferences(preferences)
        handleSuccess("Saved Notification Preferences")
      } catch (error) {
        handleError({
          error,
          prefix: "Could not save notification preferences",
        })
      } finally {
        setSaving(false)
      }
    },
    [handleSuccess, handleError],
  )

  const onReset = useCallback(() => {
    form.resetFields()
  }, [form])

  if (loading) {
    return <LoadingSpinner />
  }

  if (loadError) {
    return (
      <Result
        status="error"
        title="Failed to load notification preferences"
        subTitle="Please check your internet connection or contact support@quilt.app"
      />
    )
  }

  const notificationPreferencesFromFirestore =
    userData?.notificationPreferences || {}

  return (
    <Form
      className="px-8 pt-10"
      form={form}
      onFinish={onSave}
      initialValues={notificationPreferencesFromFirestore}
      style={{ maxWidth: 600 }}
    >
      <div>
        <h4 className="text-gray-600">
          We will show a notification on Quilt when these things happen
        </h4>
        {NOTIFICATION_INFOS.map(({ kind, label }) => (
          <Form.Item
            key={kind}
            className="bg-gray-25 mb-2 rounded-lg p-2"
            name={["disabled", kind]}
            valuePropName="checked"
            getValueProps={(value) => ({ checked: !value })}
            getValueFromEvent={(e: React.ChangeEvent<HTMLInputElement>) =>
              !e.target.checked
            }
          >
            <Checkbox>{label}</Checkbox>
          </Form.Item>
        ))}
      </div>
      <div>
        <h4 className="text-gray-600">
          We will also send you an email when these things happen
        </h4>
        {NOTIFICATION_INFOS.map(({ kind, label }) => (
          <Form.Item
            key={kind}
            className="bg-gray-25 mb-2 rounded-lg p-2"
            name={["emailDisabled", kind]}
            valuePropName="checked"
            getValueProps={(value) => ({ checked: !value })}
            getValueFromEvent={(e: React.ChangeEvent<HTMLInputElement>) =>
              !e.target.checked
            }
          >
            <Checkbox disabled={!!disabledValues?.[kind]}>{label}</Checkbox>
          </Form.Item>
        ))}
      </div>
      <Form.Item>
        <Button
          type="primary"
          className="w-20 font-bold"
          disabled={saving}
          htmlType="submit"
        >
          {saving ? "Saving..." : "Save"}
        </Button>
        <Button htmlType="button" className="ml-2 font-bold" onClick={onReset}>
          Reset
        </Button>
      </Form.Item>
    </Form>
  )
}

export default withHeader({
  title: "Notification Preferences",
  breadcrumbs: [
    { href: "/notifications", title: "Notifications" },
    { href: "/profile/notification-preferences", title: "Preferences" },
  ],
})(NotificationPreferencesForm)
