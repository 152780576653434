import Alert from "antd/es/alert"

import documentGraphic from "../../assets/img/empty_icons/source_document.svg"

const DocPreviewError: React.FC<{ message: string }> = ({ message }) => {
  return (
    <div className="m-8 flex flex-col items-center justify-center">
      <img
        src={documentGraphic}
        alt="Document with Quilt Logo"
        className="w-[100px]"
      />
      <div className="my-4 text-lg font-semibold text-gray-600">
        There was an issue previewing this document
      </div>
      <Alert message={message} type="error" />
    </div>
  )
}

export default DocPreviewError
