export enum MimeType {
  UNKNOWN = "UNKNOWN",
  CSV = "text/csv",
  DOC = "application/msword",
  DOCX = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  GOOGLE_DOC = "application/vnd.google-apps.document",
  GOOGLE_SHEETS = "application/vnd.google-apps.spreadsheet",
  GOOGLE_SLIDES = "application/vnd.google-apps.presentation",
  HTML = "text/html",
  ODP = "application/vnd.oasis.opendocument.presentation",
  ODS = "application/vnd.oasis.opendocument.spreadsheet",
  ODT = "application/vnd.oasis.opendocument.text",
  PDF = "application/pdf",
  PPTX = "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  RTF = "application/rtf",
  TXT = "text/plain",
  XLS = "application/vnd.ms-excel",
  XLSX = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  XLSM = "application/vnd.ms-excel.sheet.macroenabled.12",
  NOTION_PAGE = "application/notion.page",
  MARKDOWN = "text/markdown",
  CONFLUENCE_PAGE = "application/confluence.page",
  KNOWLEDGE_ITEM = "application/quilt.knowledge_item",
  GONG_CALL = "application/gong.call",
  SLACK_THREAD = "application/slack.thread",
}

export const mimeTypeReversed = Object.fromEntries(
  Object.entries(MimeType).map((e) => [e[1], e[0]]),
)

export const isMimetypeString = (str: string): str is MimeType =>
  str in mimeTypeReversed ? true : false

export const QUESTIONNAIRE_ASSISTANT_SHEET_MIMETYPES = [
  MimeType.XLSX,
  MimeType.XLSM,
  MimeType.CSV,
]
export type QuestionnaireAssistantSheetMimeType =
  (typeof QUESTIONNAIRE_ASSISTANT_SHEET_MIMETYPES)[number]

export const EXCEL_SUPPORTED_MIMETYPES = [
  MimeType.XLSX,
  MimeType.XLSM,
  MimeType.XLS,
]
export type ExcelMimeType = (typeof EXCEL_SUPPORTED_MIMETYPES)[number]

export const QUESTIONNAIRE_SUPPORTED_MIMETYPES = [
  // TODO(mgraczyk): Add support for CSV and ODS everywhere.
  MimeType.CSV,
  MimeType.GOOGLE_SHEETS,
  ...EXCEL_SUPPORTED_MIMETYPES,
]

export const SOURCE_DOCUMENT_SUPPORTED_MIMETYPES = [
  MimeType.PDF,
  MimeType.DOCX,
  MimeType.PPTX,
  MimeType.TXT,
  MimeType.MARKDOWN,
  MimeType.GOOGLE_DOC,
  MimeType.GOOGLE_SLIDES,
  // TODO(mgraczyk): Include questionnaires here until we add a control to the
  // UI that decides whether or not the file is a completed questionnaire.
  ...QUESTIONNAIRE_SUPPORTED_MIMETYPES,
  // TODO(mgraczyk): HTML, etc,
]

export const EXTENSION_TO_MIME_TYPE: Record<string, MimeType> = {
  csv: MimeType.CSV,
  doc: MimeType.DOC,
  docx: MimeType.DOCX,
  html: MimeType.HTML,
  md: MimeType.MARKDOWN,
  markdown: MimeType.MARKDOWN,
  odp: MimeType.ODP,
  ods: MimeType.ODS,
  odt: MimeType.ODT,
  pdf: MimeType.PDF,
  pptx: MimeType.PPTX,
  rtf: MimeType.RTF,
  txt: MimeType.TXT,
  xls: MimeType.XLS,
  xlsx: MimeType.XLSX,
  xlsm: MimeType.XLSM,
}

export const getFileExtension = (filename: string): string => {
  const parts = filename.toLowerCase().split(".")
  return parts.length > 1 ? parts[parts.length - 1] : ""
}

export const getMimeTypeFromFilename = (filename: string): MimeType => {
  const extension = getFileExtension(filename)
  return EXTENSION_TO_MIME_TYPE[extension] || MimeType.UNKNOWN
}
