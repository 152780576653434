import Button from "antd/es/button"
import Checkbox from "antd/es/checkbox"
import Empty from "antd/es/empty"
import Input from "antd/es/input"
import { CheckIcon, LinkIcon, SearchIcon, XIcon } from "lucide-react"
import VirtualList from "rc-virtual-list"
import { useCallback, useMemo, useState } from "react"

import { useActiveUserAuthorizationFromContext } from "../../contexts/ActiveUserAuthorizationContext"
import useComponentHeight from "../../hooks/useComponentHeight"
import useErrorPopup from "../../hooks/useErrorPopup"
import { useFeedFilters } from "../../hooks/useQuestionnaireFeedFilters"
import type { AnswerQuestionnaireJob } from "../../types/jobs"
import { sleep } from "../../utils"
import AssignQuestionnaireAnswerButton from "../AssignQuestionnaireAnswerForm"
import BulkCommentButton from "../BulkCommentButton"
import FeedFilterPopover from "./FeedFilterPopover"
import QuestionnaireReviewFeedCard from "./QuestionnaireReviewFeedCard"
import type { AnswerWithDiscussion } from "./types"
import { reviewAnswer } from "./utils"

interface QuestionnaireReviewFeedProps {
  answers: AnswerWithDiscussion[]
  job: AnswerQuestionnaireJob
  discussionsError?: Error
}

const isApproved = (answer: AnswerWithDiscussion) =>
  !!answer.last_reviewed_by &&
  answer.last_reviewed_by.uid === answer.last_assigned_to?.uid

const QuestionnaireReviewFeed: React.FC<QuestionnaireReviewFeedProps> = ({
  answers,
  job,
  discussionsError,
}) => {
  const {
    filterAnswers,
    filterState,
    resetFilters,
    setFilterValue,
    activeFilterCount,
  } = useFeedFilters()
  const [searchTerm, setSearchTerm] = useState("")
  const [selectedAnswerOids, setSelectedAnswerOids] = useState<Set<string>>(
    new Set(),
  )
  const { handleError, handleSuccess } = useErrorPopup()
  const [approving, setApproving] = useState(false)
  const { authUser } = useActiveUserAuthorizationFromContext()

  const filteredAnswers = useMemo(() => {
    const searchLower = searchTerm.toLowerCase()
    return filterAnswers(answers).filter((a) => {
      return (
        a.primary_question.text.toLowerCase().includes(searchLower) ||
        a.primary_answer.text.toLowerCase().includes(searchLower)
      )
    })
  }, [answers, filterAnswers, searchTerm])

  const selectedAnswers = useMemo(
    () => filteredAnswers.filter((a) => selectedAnswerOids.has(a.oid)),
    [filteredAnswers, selectedAnswerOids],
  )

  const assignedTo = useMemo(() => {
    if (selectedAnswers.length === 0) return undefined

    const firstAssignedTo = selectedAnswers[0]?.last_assigned_to

    return selectedAnswers.every(
      (a) => a.last_assigned_to?.uid === firstAssignedTo?.uid,
    )
      ? firstAssignedTo
      : undefined
  }, [selectedAnswers])

  const filteredAnswerIndices = useMemo(() => {
    const result = new Map<string, number>()
    for (const [i, a] of filteredAnswers.entries()) {
      result.set(a.oid, i)
    }
    return result
  }, [filteredAnswers])

  const handleSelect = useCallback(
    (answer: AnswerWithDiscussion, event: React.MouseEvent) => {
      const index = filteredAnswerIndices.get(answer.oid)
      if (index === undefined) return

      setSelectedAnswerOids((prev) => {
        const newSet = new Set(prev)
        if (event.shiftKey && prev.size > 0) {
          document.getSelection?.()?.removeAllRanges()
          const lastSelectedIndex = filteredAnswers.findIndex((a) =>
            prev.has(a.oid),
          )
          if (lastSelectedIndex !== -1) {
            const start = Math.min(lastSelectedIndex, index)
            const end = Math.max(lastSelectedIndex, index)
            for (let i = start; i <= end; i++) {
              newSet.add(filteredAnswers[i].oid)
            }
          } else {
            newSet.add(answer.oid)
          }
        } else {
          if (newSet.has(answer.oid)) {
            newSet.delete(answer.oid)
          } else {
            newSet.add(answer.oid)
          }
        }
        return newSet
      })
    },
    [filteredAnswers, filteredAnswerIndices],
  )

  const handleSelectAll = useCallback(() => {
    setSelectedAnswerOids((prev) =>
      prev.size === 0 ? new Set(filteredAnswers.map((a) => a.oid)) : new Set(),
    )
  }, [filteredAnswers])

  const handleApproveAll = useCallback(async () => {
    try {
      setApproving(true)

      await reviewAnswer("REVIEW", job.oid, selectedAnswers)
      handleSuccess("Selected answers approved successfully!")
      setSelectedAnswerOids(new Set())
    } catch (error) {
      handleError({ error, prefix: "Couldn't approve selected answers" })
    } finally {
      setApproving(false)
    }
  }, [selectedAnswers, job.oid, handleSuccess, handleError])

  const handleUnapproveAll = useCallback(async () => {
    setApproving(true)
    try {
      await reviewAnswer("UNREVIEW", job.oid, selectedAnswers)
      handleSuccess("Selected answers unapproved successfully!")
      setSelectedAnswerOids(new Set())
    } catch (error) {
      handleError({ error, prefix: "Couldn't unapprove selected answers" })
    }
    setApproving(false)
  }, [selectedAnswers, job.oid, handleSuccess, handleError])

  const filterBySelection = useCallback(async () => {
    // TODO(mgraczyk): Remove?
    const answerOids = Array.from(selectedAnswerOids)
    setFilterValue("oid", answerOids)
    // Wait for the filter to be applied.
    await sleep(1)
    await navigator.clipboard.writeText(window.location.href)
    handleSuccess("Copied shareable link to clipboard!")
  }, [selectedAnswerOids, handleSuccess, setFilterValue])

  const answerLocations = useMemo(
    () =>
      Array.from(selectedAnswerOids).map(
        (oid) =>
          filteredAnswers.find((a) => a.oid === oid)?.primary_answer.location,
      ),
    [selectedAnswerOids, filteredAnswers],
  )

  const [listRef, listHeight] = useComponentHeight()

  const canChangeOwnership = selectedAnswers.every((answer) => {
    return (
      job.creator.uid === authUser.uid ||
      answer?.last_assigned_to?.uid === authUser.uid
    )
  })
  const numApprovedAnswers = filteredAnswers.filter(isApproved).length
  const changing = approving

  return (
    <div className="flex w-full flex-col">
      <div className="border-gray-25 w-full border-b bg-white pb-4">
        <div className="my-4 mr-6 flex items-center">
          <Input
            placeholder="Search questions and answers"
            prefix={<SearchIcon />}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="mr-2 w-full"
          />
          <FeedFilterPopover
            answers={answers}
            setFilterValue={setFilterValue}
            filterState={filterState}
            resetFilters={resetFilters}
            activeFilterCount={activeFilterCount}
          />
        </div>
        <div className="flex items-center">
          <div className="flex grow flex-wrap items-center gap-y-2">
            <Checkbox
              checked={
                filteredAnswers.length > 0 &&
                selectedAnswerOids.size === filteredAnswers.length
              }
              indeterminate={
                selectedAnswerOids.size > 0 &&
                selectedAnswerOids.size < filteredAnswers.length
              }
              onChange={handleSelectAll}
            >
              Select All
            </Checkbox>
            <span className="ml-4 mr-4 min-w-[6rem] max-w-[6rem] text-gray-600">
              {selectedAnswerOids.size} selected
            </span>

            <Button
              size="small"
              type="primary"
              icon={<CheckIcon />}
              onClick={handleApproveAll}
              disabled={
                changing ||
                selectedAnswerOids.size === 0 ||
                selectedAnswers.every(isApproved)
              }
              className="mr-2"
            >
              Approve
            </Button>
            <Button
              size="small"
              icon={<XIcon />}
              onClick={handleUnapproveAll}
              disabled={
                changing ||
                selectedAnswerOids.size === 0 ||
                selectedAnswers.every((a) => !isApproved(a))
              }
              className="mr-2"
            >
              Unapprove
            </Button>
            <BulkCommentButton
              small
              jobOid={job.oid}
              answerLocations={answerLocations}
            />
            {canChangeOwnership && (
              <AssignQuestionnaireAnswerButton
                jobOid={job.oid}
                answerOids={Array.from(selectedAnswerOids)}
                assignedTo={assignedTo}
                small
              />
            )}
            <Button
              size="small"
              icon={<LinkIcon />}
              onClick={filterBySelection}
              disabled={changing || selectedAnswerOids.size === 0}
              className="ml-2"
            >
              Link
            </Button>
          </div>

          <div className="ml-auto mr-4 flex items-center">
            <span className="mr-2 font-semibold text-gray-700">
              {numApprovedAnswers} / {filteredAnswers.length} approved
            </span>
          </div>
        </div>
      </div>
      <div ref={listRef} className="grow overflow-y-hidden px-2">
        {filteredAnswers.length > 0 ? (
          <VirtualList
            data={filteredAnswers}
            itemKey="oid"
            height={listHeight}
            itemHeight={180}
          >
            {(answer: AnswerWithDiscussion) => (
              <div>
                <QuestionnaireReviewFeedCard
                  answer={answer}
                  job={job}
                  discussionsError={discussionsError}
                  focused={filterState.oid.includes(answer.oid)}
                  isSelected={selectedAnswerOids.has(answer.oid)}
                  onSelect={handleSelect}
                  loading={approving && selectedAnswerOids.has(answer.oid)}
                />
              </div>
            )}
          </VirtualList>
        ) : (
          <Empty
            className="m-auto flex h-96 flex-col items-center justify-center rounded-md border"
            description={
              activeFilterCount > 0
                ? "No answers match the selected filters"
                : "No answers available"
            }
          />
        )}
      </div>
    </div>
  )
}

export default QuestionnaireReviewFeed
