import Button from "antd/es/button"
import Result from "antd/es/result"
import { useNavigate } from "react-router-dom"

import url404Image from "../assets/img/quilt_404_sticker.jpeg"
import Header from "./Header"

interface Props {
  noHeader?: boolean
}

const NotFoundPage: React.FC<Props> = ({ noHeader = false }) => {
  const navigate = useNavigate()
  const goHome = () => {
    navigate("/")
  }

  const body = (
    <Result
      icon={
        <img
          className="m-auto h-[256px] w-[256px]"
          src={url404Image}
          alt="Not Found - 404"
          width={1024}
          height={1024}
        />
      }
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button type="primary" onClick={goHome}>
          Go Home
        </Button>
      }
    />
  )

  if (noHeader) {
    return body
  }

  return (
    <>
      <Header title="Not Found"></Header>
      {body}
    </>
  )
}

export default NotFoundPage
