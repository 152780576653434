import { Circle } from "lucide-react"

const LiveBadge: React.FC<{ isLive: boolean }> = ({ isLive }) => {
  const className = isLive
    ? "animate-pulse bg-red-500 text-white"
    : "bg-black/5 text-black/35"
  return (
    <div
      className={
        "flex h-8 w-16 items-center justify-center rounded " + className
      }
    >
      <Circle
        size={10}
        fill={isLive ? "white" : "darkGray"}
        color="darkGray"
        strokeWidth={3}
        className="mr-1"
      />
      Live
    </div>
  )
}

export default LiveBadge
