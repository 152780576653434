import Skeleton from "antd/es/skeleton"
import {
  CircleCheckIcon,
  CircleHelpIcon,
  CircleXIcon,
  StarIcon,
  XIcon,
} from "lucide-react"
import { forwardRef } from "react"

import ChatReferenceList from "../components/ChatReferenceList"
import CopyToClipboardButton from "../components/CopyToClipboardButton"
import MarkdownRenderer from "../components/MarkdownRenderer"
import TextWithBulletPoints from "../components/TextWithBulletPoints"
import type { Settings } from "./LiveSettingsButton"
import type {
  AnsweredQuestionUICard,
  AutoSearchUICard,
  CardDetail,
  LiveUICard,
  StaticContentUICard,
} from "./types"

const messageContainerClassName = "flex mb-1 pr-[3.5rem] items-start"
const iconClassName = "shrink-0 w-[20px] mr-1"
const textClassName = "p-1 pl-2 rounded-xl"

const UNANSWERED_MESSAGE = "Unable to answer."

const AnsweredQuestionCardView: React.FC<{
  message: AnsweredQuestionUICard
  showCardDetail?: (cardDetail: CardDetail) => void
}> = ({ message, showCardDetail }) => {
  const { question, answer_state, error_message, answer } = message
  const questionElement = (
    <div
      className={messageContainerClassName + " cursor-pointer"}
      onClick={showCardDetail ? () => showCardDetail(message) : undefined}
    >
      <CircleHelpIcon className={`${iconClassName} text-gray-300`} />
      <span className={textClassName + " font-bold text-gray-600"}>
        {question}
      </span>
    </div>
  )

  if (answer_state === "PENDING") {
    return (
      <>
        {questionElement}
        <Skeleton title={false} loading active />
      </>
    )
  } else if (answer_state === "ERROR") {
    return (
      <>
        {questionElement}
        <div className={messageContainerClassName}>
          <CircleXIcon className={`${iconClassName} text-red-300`} />
          <span className={textClassName + " font-semibold text-red-300"}>
            {error_message ?? UNANSWERED_MESSAGE}
          </span>
        </div>
      </>
    )
  } else {
    return (
      <>
        {questionElement}
        {answer && (
          <>
            <div className={messageContainerClassName}>
              <CircleCheckIcon className={`${iconClassName} text-green-400`} />
              <TextWithBulletPoints text={answer.primary_answer} />
            </div>
            <div className="invisible mt-4 flex items-end justify-end group-hover:visible">
              {/* TODO(mgraczyk,arie): Show all references after filtering on backend. */}
              <ChatReferenceList references={answer.references.slice(0, 6)} />
            </div>
          </>
        )}
      </>
    )
  }
}

const AutoSearchCardView: React.FC<{
  message: AutoSearchUICard
}> = ({ message: { query, answer_state, error_message, results } }) => {
  const topPart = (
    <div className={messageContainerClassName}>
      <CircleHelpIcon className={`${iconClassName} text-gray-300`} />
      <span className={textClassName + " font-bold text-gray-600"}>
        {query}
      </span>
    </div>
  )

  if (answer_state === "PENDING") {
    return (
      <>
        {topPart}
        <Skeleton title={false} loading active />
      </>
    )
  } else if (answer_state === "ERROR") {
    return (
      <>
        {topPart}
        <div className={messageContainerClassName}>
          <CircleXIcon className={`${iconClassName} text-red-300`} />
          <span className={textClassName + " font-semibold text-red-300"}>
            {error_message ?? UNANSWERED_MESSAGE}
          </span>
        </div>
      </>
    )
  } else {
    return (
      <>
        {topPart}
        {results && results.length > 0 && (
          <div className={messageContainerClassName}>
            <CircleCheckIcon className={`${iconClassName} text-green-400`} />
            <ul className="m-0 rounded-xl pl-6">
              {results.slice(0, 3).map((r, i) => (
                <li key={i}>
                  <a
                    href={r.link}
                    target="_blank"
                    className="font-semibold text-purple-800"
                  >
                    {r.title}
                  </a>
                  <div className="text-gray-600">{r.snippet}</div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </>
    )
  }
}

const StaticContentCardView: React.FC<{
  message: StaticContentUICard
}> = ({ message: { body } }) => {
  return <MarkdownRenderer content={body.content} className="pr-[3.5rem]" />
}

interface Props {
  message: LiveUICard
  onDismissCard: (card: LiveUICard) => Promise<void>
  onTogglePinCard: (card: LiveUICard) => Promise<void>
  onMarkCardSeen?: (card: LiveUICard) => Promise<void>
  showCardDetail?: (cardDetail: CardDetail) => void
  className?: string
  settings?: Settings
  callOid?: string
}

const LiveCard: React.ForwardRefRenderFunction<HTMLDivElement, Props> = (
  {
    message,
    onTogglePinCard,
    onDismissCard,
    onMarkCardSeen,
    showCardDetail,
    className = "",
    settings,
    callOid,
  },
  ref,
) => {
  let inner: JSX.Element | null
  if (message.kind === "ANSWERED_QUESTION") {
    inner = (
      <AnsweredQuestionCardView
        message={message}
        showCardDetail={showCardDetail}
      />
    )
  } else if (message.kind === "AUTO_SEARCH") {
    inner = <AutoSearchCardView message={message} />
  } else if (message.kind === "STATIC_CONTENT") {
    inner = <StaticContentCardView message={message} />
  } else {
    inner = null
  }

  // TODO(mgraczyk): Call onMarkCardSeen
  void onMarkCardSeen

  if (!inner) {
    return null
  }

  const debugElement = settings?.showDebug ? (
    <>
      <div className="flex items-center rounded bg-red-300 p-2 px-4 text-sm">
        <span className="font-semibold">Copy Card URN</span>:
        <CopyToClipboardButton
          text={`${callOid}:${message.oid}`}
          customCopyTooltip="Copy Card URN"
        />
      </div>
    </>
  ) : null

  inner = (
    <>
      <div className="absolute right-[10px] top-[10px] z-10 flex shrink-0 select-none items-start bg-white opacity-80">
        <StarIcon
          size="26"
          fill={message.pinned ? "#fde047" : "none"}
          className={
            "mr-1 mt-[1px] rounded-lg text-gray-400 hover:cursor-pointer hover:bg-yellow-100 " +
            (message.pinned ? "text-yellow-600" : "")
          }
          onClick={() => onTogglePinCard(message)}
        />
        <XIcon
          size="30"
          className="text-gray-400 hover:cursor-pointer hover:text-gray-900"
          onClick={() => onDismissCard(message)}
        />
      </div>
      {inner}
      {debugElement}
    </>
  )

  const cardClassName =
    "relative group mb-2 w-full rounded border border-gray-50 bg-white p-3 "
  return (
    <div ref={ref} className={cardClassName + className}>
      {inner}
    </div>
  )
}

export default forwardRef(LiveCard)
