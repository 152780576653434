import LoadingSpinner from "./LoadingSpinner"

interface SignInButtonProps {
  logo: React.ReactNode
  loading: boolean
  buttonText: string
  onClick?: () => void
  // If provided, the button will be an anchor tag with this href and has full width instead of fixed.
  href?: string
}

const SignInButton: React.FC<SignInButtonProps> = ({
  logo,
  loading,
  buttonText,
  onClick,
  href,
}) => {
  const content = (
    <>
      <div className="mr-4 h-9 w-9 rounded bg-white p-2 disabled:opacity-35">
        {logo}
      </div>
      <span className="grow overflow-hidden text-ellipsis text-left disabled:opacity-35">
        {loading ? <LoadingSpinner /> : buttonText}
      </span>
      <span className="hidden">{buttonText}</span>
    </>
  )
  const className = `bg-primary text-bold flex h-[52px] cursor-pointer flex-row flex-nowrap items-center overflow-hidden whitespace-nowrap rounded-lg px-3 py-0 text-center text-lg font-bold text-white outline-none transition-colors transition-shadow hover:text-white hover:opacity-90 disabled:cursor-default disabled:bg-purple-100 [&:not(:disabled)]:hover:shadow-md w-full`

  if (href) {
    return (
      <a className={className} target="_blank" rel="noreferrer" href={href}>
        {content}
      </a>
    )
  } else {
    return (
      <button className={className} onClick={onClick} disabled={loading}>
        {content}
      </button>
    )
  }
}

export default SignInButton
