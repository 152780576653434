import { CheckIcon } from "lucide-react"

import { getInterpretableTimeString } from "../../timeUtils"
import UserAvatar from "../UserAvatar"
import type { AnswerWithDiscussion } from "./types"

interface FeedFooterBadgeProps {
  answer: AnswerWithDiscussion
}

const FeedFooterBadge: React.FC<FeedFooterBadgeProps> = ({ answer }) => {
  const owner = answer.last_assigned_to
  if (!owner) return null

  return (
    <div>
      <div className="mb-2 font-bold">Owner</div>
      <div className="flex items-center">
        <UserAvatar name={owner.email} size="xs" />
        <div className="ml-2">
          <div className="font-semibold">{owner.email}</div>
          {answer.last_reviewed_by &&
          owner.uid === answer.last_reviewed_by.uid ? (
            <div className="flex">
              <CheckIcon className="text-green-400" size={18} />
              Approved{" "}
              {getInterpretableTimeString(answer.last_reviewed_by.created_at)}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default FeedFooterBadge
