import Button from "antd/es/button"
import List from "antd/es/list"
import type { User as AuthUser } from "firebase/auth"
import { collection, limit, orderBy, query, where } from "firebase/firestore"
import { useCallback } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { Link } from "react-router-dom"
import { useMap } from "usehooks-ts"

import ExternalLink from "../components/ExternalLink"
import { makeConverter } from "../dbUtils"
import { db } from "../firebaseApp"
import useErrorPopup from "../hooks/useErrorPopup"
import { getInterpretableTimeString } from "../timeUtils"
import { deleteLiveAssistedCall } from "./api"
import { LIVE_ASSISTED_CALLS_COLLECTION } from "./db"
import type { LiveAssistedCall } from "./types"

interface Props {
  user: AuthUser
}

const LiveAssistedCallItem: React.FC<{
  call: LiveAssistedCall
  removeCall: (call: LiveAssistedCall) => void
  removing: boolean | undefined
}> = ({ call, removing, removeCall }) => {
  let meetingSpecificContent: React.ReactNode
  if (call.meeting_type === "ZOOM") {
    meetingSpecificContent = (
      <>
        <div className="grow">
          <div>
            Join URL: <ExternalLink href={call.join_url} />
          </div>
          <div>{getInterpretableTimeString(call.created_at, true)}</div>
        </div>
        <div>Bot state: {call.bot_state}</div>
      </>
    )
  } else {
    const isSim = call.meeting_type === "SIMULATION"
    meetingSpecificContent = (
      <Link
        className="grow p-2"
        to={
          isSim
            ? `/live-assistant-simulation/${call.oid}`
            : `/live-assistant/${call.oid}`
        }
      >
        <div>
          {call?.title ??
            (call.meeting_type === "SIMULATION"
              ? "Go to simulation"
              : "Go to call")}
        </div>
        <div>{getInterpretableTimeString(call.created_at, true)}</div>
      </Link>
    )
  }

  return (
    <div className="flex items-center hover:bg-gray-50">
      {meetingSpecificContent}
      <div className="ml-2 p-2">
        <Button
          type="default"
          danger
          disabled={removing}
          onClick={() => removeCall(call)}
        >
          Delete
        </Button>
      </div>
    </div>
  )
}

const LiveAssistedCallsList: React.FC<Props> = ({ user }) => {
  const { handleError } = useErrorPopup()
  const [calls, loading, error] = useCollectionData(
    query(
      collection(db, LIVE_ASSISTED_CALLS_COLLECTION).withConverter(
        makeConverter<LiveAssistedCall>(),
      ),
      where("creator_uid", "==", user.uid),
      orderBy("created_at", "desc"),
      limit(200),
    ),
  )
  const [removingMap, setRemoveMap] = useMap<string, boolean>()

  const removeCall = useCallback(
    async (call: LiveAssistedCall) => {
      setRemoveMap.set(call.oid, true)
      try {
        await deleteLiveAssistedCall(call)
      } catch (error) {
        handleError({ prefix: "Error removing call", error })
      } finally {
        setRemoveMap.remove(call.oid)
      }
    },
    [handleError, setRemoveMap],
  )

  if (error) {
    console.error("Error loading calls", error)
    return <div>Error loading calls</div>
  }

  return (
    <List
      header="Your Previous Calls"
      bordered
      dataSource={calls}
      renderItem={(call) => (
        <LiveAssistedCallItem
          key={call.oid}
          call={call}
          removeCall={removeCall}
          removing={removingMap.get(call.oid)}
        />
      )}
      loading={loading}
    />
  )
}

export default LiveAssistedCallsList
