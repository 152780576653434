import Button from "antd/es/button"
import Popover from "antd/es/popover"
import Select from "antd/es/select"
import { AsteriskIcon, CheckIcon, TagIcon } from "lucide-react"
import { useCallback, useMemo, useState } from "react"

import { useKeydown } from "../hooks/events"
import useErrorPopup from "../hooks/useErrorPopup"
import { editKnowledgeItemsApi } from "../knowledge/api"
import type { KnowledgeItemEditRequest } from "../knowledge/types"
import { TagListItem } from "../tags/Tag"
import type { GroupTag } from "../tags/types"
import type { UserDocument } from "../types/userDocument"

type Props = {
  onClose: () => void
  onFinish: () => void
  tags: GroupTag[]
  loading: boolean
  items: UserDocument[]
  isUserDocument: boolean
}

type TagState = "indeterminate" | "checked" | "unchecked"

const BulkTagForm: React.FC<Props> = ({
  onClose,
  onFinish,
  items,
  tags: groupTags,
  loading,
  isUserDocument,
}) => {
  const [isSubmitting, setSubmitting] = useState<boolean>(false)
  const { handleSuccess, handleError } = useErrorPopup()

  useKeydown(27 /*escape*/, onClose)

  const initialTagStates = useMemo(() => {
    const states: Record<string, TagState> = {}
    groupTags.forEach((tag) => {
      const allHaveTag = items.every(
        (item) => item.tags?.[tag.oid] !== undefined,
      )
      const someHaveTag = items.some(
        (item) => item.tags?.[tag.oid] !== undefined,
      )
      if (allHaveTag) {
        states[tag.oid] = "checked"
      } else if (someHaveTag) {
        states[tag.oid] = "indeterminate"
      } else {
        states[tag.oid] = "unchecked"
      }
    })
    return states
  }, [items, groupTags])

  const [tagStates, setTagStates] =
    useState<Record<string, TagState>>(initialTagStates)

  const handleTagChange = useCallback(
    (selectedTags: string[]) => {
      setTagStates((prevStates) => {
        const newStates = { ...prevStates }
        groupTags.forEach((tag) => {
          if (selectedTags.includes(tag.oid)) {
            newStates[tag.oid] = "checked"
          } else if (newStates[tag.oid] !== "indeterminate") {
            newStates[tag.oid] = "unchecked"
          }
        })
        return newStates
      })
    },
    [groupTags],
  )

  const onSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      setSubmitting(true)
      try {
        const requests: KnowledgeItemEditRequest[] = items.map((item) => {
          const updatedTags: { [k: string]: string | null } = { ...item.tags }

          groupTags.forEach((tag) => {
            const tagState = tagStates[tag.oid]
            if (tagState === "checked") {
              updatedTags[tag.oid] = null
            } else if (tagState === "unchecked") {
              delete updatedTags[tag.oid]
            }
            // For "indeterminate", we keep the original value, which is already in updatedTags
          })

          return {
            oid: item.oid,
            tags: updatedTags,
          }
        })

        await editKnowledgeItemsApi({ requests })

        handleSuccess(
          items.length === 1
            ? `Updated tags for ${isUserDocument ? "user document" : "knowledge item"}`
            : `Updated tags for ${isUserDocument ? "user documents" : "knowledge items"}`,
        )

        onFinish()
        onClose()
      } catch (error) {
        handleError({
          error,
          prefix: "Couldn't update tags for knowledge items",
        })
      } finally {
        setSubmitting(false)
      }
    },
    [
      handleSuccess,
      handleError,
      onClose,
      items,
      onFinish,
      groupTags,
      tagStates,
      isUserDocument,
    ],
  )

  const selectedTags = Object.keys(tagStates).filter(
    (oid) => tagStates[oid] === "checked",
  )

  return (
    <form className="w-[400px] p-4" onSubmit={onSubmit}>
      <Select
        mode="multiple"
        className="mb-4 w-full"
        placeholder="Select tags"
        value={selectedTags}
        onChange={handleTagChange}
        options={groupTags.map((tag) => ({
          value: tag.oid,
          label: <TagListItem tag={tag} />,
          name: tag.name,
        }))}
        loading={loading}
        optionFilterProp="name"
        menuItemSelectedIcon={(props: { value: string }) => {
          const { value } = props
          const state = tagStates[value]
          if (state === "indeterminate") {
            return <AsteriskIcon size={16} />
          } else if (state === "checked") {
            return <CheckIcon size={16} />
          }
          return null
        }}
      />
      <Button
        disabled={
          loading ||
          isSubmitting ||
          Object.values(tagStates).every((state) => state === "indeterminate")
        }
        className="w-full"
        type="primary"
        htmlType="submit"
      >
        {isSubmitting ? "Updating Tags..." : "Update Tags"}
      </Button>
    </form>
  )
}

type BulkTagProps = Omit<Props, "onClose">
const BulkTagButton: React.FC<BulkTagProps> = (props) => {
  const [open, setOpen] = useState(false)
  const hide = useCallback(() => setOpen(false), [])

  return (
    <Popover
      content={<BulkTagForm onClose={hide} {...props} />}
      trigger="click"
      open={open}
      onOpenChange={setOpen}
      placement="bottom"
      destroyTooltipOnHide
    >
      <Button
        type="primary"
        size="small"
        icon={<TagIcon size={14} />}
        className="flex items-center gap-2"
      >
        <span className="text-[12px] font-bold">Tag</span>
      </Button>
    </Popover>
  )
}

export default BulkTagButton
