import { formatNumber } from "../../utils"

const TopCard: React.FC<{
  title: string
  value: number
}> = ({ title, value }) => {
  return (
    <div className="bg-gray-25 h-48 flex-1 overflow-hidden rounded-2xl p-6 text-gray-800">
      <div className="mb-2 h-14 text-[16px] font-bold">{title}</div>
      <div className="text-6xl font-normal">{formatNumber(value)}</div>
    </div>
  )
}

export default TopCard
