import type { IHeaderParams } from "@ag-grid-community/core"
import { Button, Divider, Popover, Select } from "antd"
import { Settings } from "lucide-react"
import { useState } from "react"

import useErrorPopup from "../../hooks/useErrorPopup"
import type { AnswerQuestionnaireViewConfigRequest } from "../../types/jobs"
import type { QuestionAnswerLayout } from "../../types/sheets"

interface Props extends IHeaderParams {
  saveOptions: (config: AnswerQuestionnaireViewConfigRequest) => Promise<void>
  jobOid: string
  sheetName: string
  sheetLayout: QuestionAnswerLayout | undefined
}

export const ViewsConfigPopover = (params: Props) => {
  const [open, setOpen] = useState(false)
  const [saving, setSaving] = useState(false)
  const { handleSuccess, handleError } = useErrorPopup()
  const [headerRowIndex, setHeaderRowIndex] = useState<
    number | null | undefined
  >(params.sheetLayout?.header_index ?? null)
  const [hiddenColumns, setHiddenColumns] = useState<string[]>(() => {
    // Get column keys first
    const columnKeys =
      params.api
        .getColumnDefs()
        ?.map((col) => col.headerName)
        .filter((name): name is string => Boolean(name)) ?? []

    // Convert hidden field indices to column names
    return (params.sheetLayout?.hidden_columns ?? [])
      .map((index) => columnKeys[index])
      .filter(Boolean)
  })

  const columnKeys =
    params.api
      .getColumnDefs()
      ?.map((col) => col.headerName)
      .filter((name): name is string => Boolean(name)) ?? []

  const rowCount = params.api?.getDisplayedRowCount() || 0

  const rowOptions = Array.from({ length: rowCount }, (_, i) => ({
    label: `Row ${i + 1}`,
    value: i,
  }))

  const columnOptions = columnKeys?.map((col) => ({
    label: col,
    value: col,
  }))

  const handleSave = async () => {
    const config: AnswerQuestionnaireViewConfigRequest = {
      job_oid: params.jobOid,
      sheet_name: params.sheetName,
      header_index: headerRowIndex ?? null,
      hidden_columns: hiddenColumns
        .map((col) => columnKeys.indexOf(col))
        .filter((index) => index !== -1),
    }
    setSaving(true)
    try {
      await params.saveOptions(config)
      handleSuccess("View configuration saved successfully")
      setOpen(false)
    } catch (error) {
      handleError({
        error,
        prefix: "Failed to save view configuration",
      })
    } finally {
      setSaving(false)
    }
  }

  const content = (
    <div className="max-h-96 w-72 overflow-y-auto p-2">
      <div>
        <Button
          type="default"
          className="mb-2 w-full"
          onClick={() => {
            params.api?.sizeColumnsToFit()
          }}
        >
          Auto-resize Columns
        </Button>
      </div>

      <Divider className="my-2" />

      <div>
        <h3 className="mb-2 font-semibold">Header Row</h3>
        <Select
          placeholder="Search or select header row"
          className="w-full"
          options={rowOptions}
          value={headerRowIndex}
          onChange={setHeaderRowIndex}
          showSearch
          allowClear
          disabled={!params.sheetLayout}
          filterOption={(input, option) =>
            (option?.label ?? "")
              .toString()
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        />
      </div>

      <div>
        <h3 className="mb-2 font-semibold">Hidden Columns</h3>
        <Select
          mode="multiple"
          placeholder="Search or select columns to hide"
          className="w-full"
          options={columnOptions}
          value={hiddenColumns}
          onChange={setHiddenColumns}
          showSearch
          allowClear
          maxTagCount="responsive"
          disabled={!params.sheetLayout}
          filterOption={(input, option) =>
            (option?.label ?? "")
              .toString()
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        />
      </div>

      <div className="mt-4 flex gap-2">
        <Button
          type="default"
          className="flex-1"
          onClick={() => setOpen(false)}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          className="flex-1"
          onClick={handleSave}
          title={params.sheetLayout ? "" : "Quilt is not tracking this sheet"}
          disabled={saving || !params.sheetLayout}
          loading={saving}
        >
          Save
        </Button>
      </div>
    </div>
  )

  return (
    <Popover
      content={content}
      trigger="click"
      open={open}
      onOpenChange={setOpen}
      placement="rightTop"
      overlayClassName="column-manager-popover"
    >
      <Button
        type="text"
        className="rounded-full p-1 transition-colors hover:bg-gray-100"
        icon={<Settings className="h-4 w-4 text-gray-600" />}
      />
    </Popover>
  )
}

export default ViewsConfigPopover
