import emptyInvite from "../../assets/img/quilt_coming_soon.jpeg"
import { ExternalLinkButton } from "../../components/ExternalLink"

const DocNotSupported: React.FC<{ documentUrl?: string | undefined }> = ({
  documentUrl,
}) => {
  return (
    <div className="m-8 flex flex-col items-center justify-center">
      <img
        src={emptyInvite}
        alt="Document with Quilt Logo"
        className="w-[150px] rounded-xl"
      />
      <div className="mt-4 text-lg font-semibold text-gray-600">
        Document Preview
      </div>
      <p className="text-center text-gray-600">
        Soon, you’ll be able to directly preview the document here in Quilt!
      </p>
      {documentUrl ? (
        <ExternalLinkButton href={documentUrl}>Go to page</ExternalLinkButton>
      ) : (
        <div className="text-primary mt-2 rounded-md bg-purple-50 px-4 py-1 font-semibold">
          Coming soon
        </div>
      )}
    </div>
  )
}

export default DocNotSupported
