import { useCallback, useMemo } from "react"
import { useSearchParams } from "react-router-dom"

import type { AnswerWithDiscussion } from "../components/QuestionnaireReview/types"

export interface FeedFilterState {
  lastReviewedBy: string[]
  confidence: string | null
  lastAssignedTo: string[]
  approved: string | null
  discussionAssignedTo: string[]
  oid: string[]
}

export interface UseFeedFiltersResult {
  filterState: FeedFilterState
  setFilterValue: (
    key: keyof FeedFilterState,
    value: string[] | string | null | undefined,
  ) => void
  resetFilters: () => void
  filterAnswers: (answers: AnswerWithDiscussion[]) => AnswerWithDiscussion[]
  activeFilterCount: number
}

function parseUrlParamArray(value: string | null): string[] {
  return value ? value.split(",").filter(Boolean) : []
}

export function useFeedFilters(): UseFeedFiltersResult {
  const [searchParams, setSearchParams] = useSearchParams()

  const filterState = useMemo<FeedFilterState>(
    () => ({
      lastReviewedBy: parseUrlParamArray(searchParams.get("lastReviewedBy")),
      confidence: searchParams.get("confidence"),
      lastAssignedTo: parseUrlParamArray(searchParams.get("lastAssignedTo")),
      oid: parseUrlParamArray(searchParams.get("oid")),
      approved: searchParams.get("approved"),
      discussionAssignedTo: parseUrlParamArray(
        searchParams.get("discussionAssignedTo"),
      ),
    }),
    [searchParams],
  )

  const resetFilters = useCallback(() => {
    setSearchParams({})
  }, [setSearchParams])

  const setFilterValue = useCallback(
    (
      key: keyof FeedFilterState,
      value: string[] | string | null | undefined,
    ) => {
      const newParams = new URLSearchParams(searchParams)
      if (value == null || (Array.isArray(value) && value.length === 0)) {
        newParams.delete(key)
      } else if (Array.isArray(value)) {
        newParams.set(key, value.join(","))
      } else {
        newParams.set(key, value)
      }
      setSearchParams(newParams)
    },
    [searchParams, setSearchParams],
  )

  const filterAnswers = useCallback(
    (answers: AnswerWithDiscussion[]) => {
      return answers.filter((answer) => {
        let isIncluded = true

        if (filterState.oid.length > 0) {
          isIncluded &&= filterState.oid.includes(answer.oid)
        }

        if (filterState.lastReviewedBy.length > 0) {
          isIncluded &&= filterState.lastReviewedBy.includes(
            answer.last_reviewed_by?.uid ?? "",
          )
        }

        if (filterState.confidence === "0") {
          isIncluded &&= answer.confidence === 0
        } else if (filterState.confidence === "1") {
          isIncluded &&= answer.confidence > 0
        }

        if (filterState.lastAssignedTo.length > 0) {
          isIncluded &&= filterState.lastAssignedTo.includes(
            answer.last_assigned_to?.uid || "",
          )
        }

        if (filterState.approved) {
          const approved =
            !!answer.last_assigned_to &&
            answer.last_reviewed_by?.uid === answer.last_assigned_to?.uid
          if (filterState.approved === "1") {
            isIncluded &&= approved
          } else if (filterState.approved === "0") {
            isIncluded &&= !approved
          }
        }

        if (filterState.discussionAssignedTo.length > 0) {
          isIncluded &&=
            answer.discussions?.some((discussion) =>
              filterState.discussionAssignedTo.includes(
                discussion.assignment?.uid ?? "",
              ),
            ) ?? false
        }

        return isIncluded
      })
    },
    [filterState],
  )

  const activeFilterCount = Object.values(filterState).filter((value) =>
    Array.isArray(value) ? value.length > 0 : value !== null,
  ).length

  return {
    filterState,
    setFilterValue,
    resetFilters,
    filterAnswers,
    activeFilterCount,
  }
}
