const TextWithBulletPoints: React.FC<{ text: string }> = ({ text }) => (
  <ul className="m-0 rounded-xl pl-6">
    {text
      .split("•")
      .filter(Boolean)
      .map((a, i) => (
        <li key={i}>{a}</li>
      ))}
  </ul>
)
export default TextWithBulletPoints
