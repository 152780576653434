import Input from "antd/es/input"
import Skeleton from "antd/es/skeleton"
import Typography from "antd/es/typography"
import { useCallback, useState } from "react"
import { useSearchParams } from "react-router-dom"

import { getReferenceSearchResults } from "../api"
import ChatReferenceList from "../components/Chatv2/ChatReferenceList"
import { withHeader } from "../components/Header"
import { EMPTY_ARRAY } from "../constants"
import { useActiveUserAuthorizationFromContext } from "../contexts/ActiveUserAuthorizationContext"
import { getErrorMessage } from "../errors"
import type { AnswerReference } from "../types/answerer"

const SearchComponent: React.FC = () => {
  // The search query, which is the question to answer.
  const [searchParams, setSearchParams] = useSearchParams()

  // The answer to the question, or a default if we haven't asked anything.
  const [, setRequestId] = useState<string | null>(null)
  const [references, setReferences] = useState<AnswerReference[]>(EMPTY_ARRAY)

  // Is the answer loading?
  const [loading, setLoading] = useState<boolean>(false)

  // Has the user already searched for an answer?
  // Used to change the view from search only to search + results.
  const [answered, setAnswered] = useState<boolean>(false)

  // Error while retrieving an answer.
  const [error, setError] = useState<React.ReactNode | null>(null)

  const { hasPerm } = useActiveUserAuthorizationFromContext()

  const search = searchParams.get("q") ?? ""

  const setSearchFromEvent = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      setSearchParams({ q: e.target.value }),
    [setSearchParams],
  )

  const handleSearch = useCallback(async () => {
    if (search === undefined || search === "") {
      return
    }
    setAnswered(true)
    setLoading(true)
    setError(null)
    try {
      const result = await getReferenceSearchResults({
        query: {
          text: search,
        },
        analyticsSurface: "WEB_REFERENCE_SEARCH",
      })
      setReferences(result.references)
      setRequestId(result.request_id)
    } catch (error) {
      setError(getErrorMessage({ error, prefix: "Couldn't get answer" }))
    } finally {
      setLoading(false)
    }
  }, [search])

  if (!hasPerm("search")) {
    return (
      <div className="m-8 flex min-h-[50vh] flex-col items-center justify-center space-y-4">
        Sory, you do not have permission to access this page. Please contact
        your admin for details.
      </div>
    )
  }

  if (!answered) {
    // Show large centered view with no interactions or references.
    return (
      <div className="m-8 flex min-h-[50vh] flex-col items-center justify-center space-y-4">
        <Typography.Title level={2} className="mb-4">
          Search Your References
        </Typography.Title>
        <Typography.Title level={5} className="mb-4">
          We will show you the references that would be used to answer
          questions.
        </Typography.Title>
        <Input.Search
          className="w-3/4"
          placeholder='Your question here. For example "What is the name of your company?"'
          size="large"
          value={search}
          onChange={setSearchFromEvent}
          onSearch={handleSearch}
          loading={loading}
        />
      </div>
    )
  }

  return (
    <div className="flex grow flex-col overflow-y-hidden p-8 pb-0">
      <div className="mb-8 w-2/3">
        <Input.Search
          size="large"
          placeholder='Your query or question here. For example "What is the name of your company?"'
          value={search}
          onChange={setSearchFromEvent}
          onSearch={handleSearch}
          loading={loading}
        />
      </div>
      <div className="flex w-full grow flex-col overflow-y-auto pb-2">
        <h2 className="mb-2 text-xl">References</h2>
        <Skeleton loading={loading} active>
          {error ? (
            error
          ) : (
            <ChatReferenceList
              references={references}
              size="large"
              loadEagerly
              cardClassName="rounded-md border p-2"
            />
          )}
        </Skeleton>
      </div>
    </div>
  )
}

export default withHeader({
  title: "Search for References",
  subtitle: "Search for References without generating an answer",
})(SearchComponent)
