import { type WorkBook, utils as xlsxUtils } from "xlsx"

import {
  removeEmptyColumns,
  removeEmptyColumnsFromEnd,
  removeEmptyRowsFromEnd,
} from "./formatGrid"

type RowData = Record<string, unknown>

interface GetWorksheetJsonDataOptions {
  // Remove empty columns from inside instead of just the end of the sheet.
  dropInnerEmptyColumns?: boolean

  // Maximum number of columns to read from the sheet.
  // Avoids crashing on very large sheets.
  maxColumns?: number
}

// Gets data from the sheet, dropping empty columns and rows from the end.
// Optionally also drops empty columns from inside the sheet.
//
// Limited to 500 columns.
export function getWorksheetJsonData<T extends RowData>(
  workbook: WorkBook,
  sheetName: string,
  options: GetWorksheetJsonDataOptions = {},
): T[] {
  const { dropInnerEmptyColumns = true, maxColumns = 100 } = options

  const worksheet = workbook.Sheets[sheetName]

  // force range to start at A1 to avoid skipping empty rows at start.
  const range = xlsxUtils.decode_range(worksheet["!ref"] ?? "")
  range.s.r = range.s.c = 0
  range.e.c = Math.min(range.e.c, maxColumns)
  worksheet["!ref"] = xlsxUtils.encode_range(range)

  const jsonData = xlsxUtils.sheet_to_json<T>(worksheet, {
    header: "A",
    blankrows: true,
    defval: "",
    raw: false,
  })

  const rowsFiltered = removeEmptyRowsFromEnd(jsonData)
  if (dropInnerEmptyColumns) {
    return removeEmptyColumns(rowsFiltered)
  } else {
    return removeEmptyColumnsFromEnd(rowsFiltered)
  }
}
