import type { SelectProps } from "antd"
import Button from "antd/es/button"
import Input from "antd/es/input"
import Popover from "antd/es/popover"
import Select from "antd/es/select"
import Switch from "antd/es/switch"
import { SettingsIcon } from "lucide-react"

import { useActiveUserAuthorizationFromContext } from "../contexts/ActiveUserAuthorizationContext"
import { isQuiltInternalUser } from "../utils"

const disableActorsOptions: SelectProps["options"] = [
  { label: "Live Assistant (Q/A)", value: "LiveAssistantMini" },
  { label: "Static Cards", value: "StaticCardActor" },
  { label: "Auto Search", value: "AutoSearchActor" },
  { label: "Transcriber", value: "TranscriberActor" },
  { label: "Segmenter", value: "SegmenterActor" },
  { label: "UI Events", value: "UIEventActor" },
]

const simulationInputEventsOptions: SelectProps["options"] = [
  { label: "AUDIO_DATA", value: "AUDIO_DATA" },
  { label: "TRANSCRIBER_OUTPUT", value: "TRANSCRIBER_OUTPUT" },
  { label: "TRANSCRIPT_SEGMENT_UPDATE", value: "TRANSCRIPT_SEGMENT_UPDATE" },
  { label: "QUESTION_FOUND", value: "QUESTION_FOUND" },
  { label: "QUESTION_ANSWERED", value: "QUESTION_ANSWERED" },
  { label: "QUESTION_NOT_ANSWERED", value: "QUESTION_NOT_ANSWERED" },
  { label: "LIVE_UI_CARD_UPDATE", value: "LIVE_UI_CARD_UPDATE" },
]

export interface Settings {
  includeSystemAudio: boolean
  autoscrollTimeoutSeconds?: number
  showDebug?: boolean
  disabledActors: string[]
  enableResultTts?: boolean
  simulationInputEvents?: string[]
  modelName?: string
}

const defaultDisabledActors =
  import.meta.env.VITE_LIVE_ASSISTANT_DEFAULT_DISABLED_ACTORS?.split(",")
    .map((s) => s.trim())
    .filter(Boolean) ?? []

export const DEFAULT_SETTINGS = {
  // Disable system audio by default if the browser doesn't support it.
  includeSystemAudio: !!navigator?.mediaDevices?.getDisplayMedia,
  autoscrollTimeoutSeconds: 5,
  showDebug: false,
  disabledActors: defaultDisabledActors,
  simulationInputEvents: ["TRANSCRIBER_OUTPUT"],
  modelName: "default",
}

interface Props {
  settings: Settings
  disabled?: boolean
  onChange: (settings: Partial<Settings>) => void
  showSimulationSettings?: boolean
}

const LiveSettingsButton: React.FC<Props> = ({
  settings,
  disabled = false,
  onChange,
  showSimulationSettings = false,
}) => {
  const { authUser } = useActiveUserAuthorizationFromContext()
  const simulationSettingsContent = showSimulationSettings ? (
    <>
      <div className="mb-1 mt-4 text-sm font-semibold text-gray-800">
        Simulation Input Events
      </div>
      <Select
        mode="tags"
        allowClear
        className="min-w-64"
        placeholder="Simulation Input Events"
        disabled={disabled}
        value={settings.simulationInputEvents || []}
        onChange={(kinds) => onChange({ simulationInputEvents: kinds })}
        options={simulationInputEventsOptions}
      />
    </>
  ) : null

  const settingsContent = (
    <div className="flex-none flex-col">
      <div className="mb-1 text-sm font-semibold text-gray-800">
        Audio Settings
      </div>
      <Switch
        onChange={(value) => onChange({ includeSystemAudio: value })}
        value={settings.includeSystemAudio}
        disabled={disabled}
        checkedChildren="Mic + System"
        unCheckedChildren="Mic Only"
      />
      <div className="mb-1 text-sm font-semibold text-gray-800">
        Autoscroll Timeout (seconds)
      </div>
      <Input
        onChange={(e) =>
          onChange({
            autoscrollTimeoutSeconds: Math.max(
              1,
              Number(e.currentTarget.value),
            ),
          })
        }
        value={settings.autoscrollTimeoutSeconds}
        disabled={disabled}
        min="1"
        type="number"
      />
      {isQuiltInternalUser(authUser) && (
        <>
          <div className="mb-1 mt-4 text-sm font-semibold text-gray-800">
            Disabled Actors
          </div>
          <Select
            mode="multiple"
            allowClear
            className="min-w-64"
            placeholder="Disable actors"
            disabled={disabled}
            value={settings.disabledActors}
            onChange={(actors) => onChange({ disabledActors: actors })}
            options={disableActorsOptions}
          />
        </>
      )}
      {simulationSettingsContent}
      {isQuiltInternalUser(authUser) && (
        <div className="mt-4">
          <Switch
            onChange={(value) => onChange({ enableResultTts: value })}
            value={settings.enableResultTts}
            disabled={disabled}
            checkedChildren="Result TTS"
            unCheckedChildren="No Result TTS"
          />
        </div>
      )}
      {isQuiltInternalUser(authUser) && (
        <div className="mt-4">
          <Switch
            onChange={(value) => onChange({ showDebug: value })}
            value={settings.showDebug}
            disabled={disabled}
            checkedChildren="Show Debug"
            unCheckedChildren="Hide Debug"
          />
        </div>
      )}
    </div>
  )

  return (
    <Popover content={settingsContent} trigger="click" placement="bottom">
      <Button disabled={disabled}>
        <SettingsIcon />
        <span className="max-sm:!hidden">Settings</span>
      </Button>
    </Popover>
  )
}

export default LiveSettingsButton
