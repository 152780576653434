import type { CellRangeCoordinates, SheetsRange } from "../types/sheets"
import * as sheetsRange from "./range"

// n is the 1-indexed column number.
export const getColumnLetter = (n: number): string => {
  const m = n - 1
  const upper = Math.floor(m / 26)
  const lower = m % 26

  return (
    (upper > 0 ? getColumnLetter(upper) : "") + String.fromCharCode(65 + lower)
  )
}

export function getSpreadsheetId(url: string): string {
  const parts = new URL(url).pathname.split("/")
  for (const part of parts) {
    // Typical url is
    //  https://docs.google.com/spreadsheets/d/<id>/edit
    if (["", "spreadsheets", "d"].includes(part)) {
      continue
    }
    return part
  }
  throw new Error("Couldn't get the spreadsheet ID from the URL")
}

export function getSpreadsheetSheetId(url: string): number | null {
  let urlHash = new URL(url).hash
  if (urlHash.startsWith("#")) {
    urlHash = urlHash.substring(1)
  }

  const hashParams = new URLSearchParams(urlHash)
  const maybeGid = hashParams.get("gid")
  if (maybeGid !== null) {
    return parseInt(maybeGid)
  }

  return null
}

function getRegexGroups(
  re: RegExp,
  text: string,
): Record<string, string> | null {
  const match = text.trim().match(re)
  return match?.groups || null
}

export function getColumnNumber(letters: string): number {
  let n = 0
  for (let p = 0; p < letters.length; p++) {
    n = letters.charCodeAt(p) - 64 + n * 26
  }
  return n
}

export function rowColToString(rowColIndex: number, isRow: boolean): string {
  if (isRow) {
    return rowColIndex.toString()
  } else {
    return getColumnLetter(rowColIndex)
  }
}

export function rowColStrToIndex(rowColStr: string, isRow: boolean): number {
  if (isRow) {
    return parseInt(rowColStr)
  } else {
    return getColumnNumber(rowColStr)
  }
}

export function validateRowCol(rowColStr: string, isRow: boolean): void {
  if (isRow) {
    if (isNaN(parseInt(rowColStr))) {
      throw new Error("Not a valid row")
    }
  } else {
    const colRe = /^[A-Z]+$/
    if (!colRe.test(rowColStr)) {
      throw new Error("Not a valid col")
    }
  }
}

function parseCellRange(text: string): CellRangeCoordinates | null {
  const re =
    /^(?<firstColIndex>[A-Z]+)(?<firstRowIndex>[0-9]+):(?<lastColIndex>[A-Z]+)(?<lastRowIndex>[0-9]+)$/
  const groups = getRegexGroups(re, text)
  if (!groups) {
    return null
  }

  const firstColIndex = getColumnNumber(groups["firstColIndex"])
  const lastColIndex = getColumnNumber(groups["lastColIndex"])
  const firstRowIndex = parseInt(groups["firstRowIndex"])
  const lastRowIndex = parseInt(groups["lastRowIndex"])

  return {
    firstColIndex,
    lastColIndex,
    firstRowIndex,
    lastRowIndex,
  }
}

function parseSingleCell(text: string): CellRangeCoordinates | null {
  const re = /^(?<col>[A-Z]+)(?<row>[0-9]+)$/
  const groups = getRegexGroups(re, text)
  if (!groups) {
    return null
  }

  const col = getColumnNumber(groups["col"])
  const row = parseInt(groups["row"])

  return {
    firstColIndex: col,
    lastColIndex: col,
    firstRowIndex: row,
    lastRowIndex: row,
  }
}

function parseMultiFullRows(text: string): CellRangeCoordinates | null {
  const re = /^(?<firstRowIndex>[0-9]+):(?<lastRowIndex>[0-9]+)$/
  const groups = getRegexGroups(re, text)
  if (!groups) {
    return null
  }

  // TODO(mgraczyk): Somehow indicate that all columns are selected.
  const firstColIndex = 1
  const lastColIndex = 26
  const firstRowIndex = parseInt(groups["firstRowIndex"])
  const lastRowIndex = parseInt(groups["lastRowIndex"])

  return {
    firstColIndex,
    lastColIndex,
    firstRowIndex,
    lastRowIndex,
  }
}

function parseMultiFullColumns(text: string): CellRangeCoordinates | null {
  const re = /^(?<firstColIndex>[A-Z]+):(?<lastColIndex>[A-Z]+)$/
  const groups = getRegexGroups(re, text)
  if (!groups) {
    return null
  }

  // TODO(mgraczyk): Somehow indicate that all columns are selected.
  const firstColIndex = getColumnNumber(groups["firstColIndex"])
  const lastColIndex = getColumnNumber(groups["lastColIndex"])
  const firstRowIndex = 1
  const lastRowIndex = 400

  return {
    firstColIndex,
    lastColIndex,
    firstRowIndex,
    lastRowIndex,
  }
}

export function parseSelectionText(text: string): CellRangeCoordinates | null {
  return (
    parseCellRange(text) ||
    parseSingleCell(text) ||
    parseMultiFullRows(text) ||
    parseMultiFullColumns(text)
  )
}

function cellToText(row: number, col: number): string {
  return getColumnLetter(col) + row.toString()
}

export function coordinatesToText(range: CellRangeCoordinates): string {
  if (
    range.firstColIndex === range.lastColIndex &&
    range.firstRowIndex === range.lastRowIndex
  ) {
    return cellToText(range.firstRowIndex, range.firstColIndex)
  } else {
    return (
      cellToText(range.firstRowIndex, range.firstColIndex) +
      ":" +
      cellToText(range.lastRowIndex, range.lastColIndex)
    )
  }
}

export function rangeToText(range: SheetsRange, showSheetName = true): string {
  let text = ""
  if (showSheetName && range.sheetName) {
    text += range.sheetName + "!"
  }

  return text + coordinatesToText(range)
}

export function* cellNames(
  sheetsRange: CellRangeCoordinates,
): Generator<string> {
  for (
    let rowIdx = sheetsRange.firstRowIndex;
    rowIdx <= sheetsRange.lastRowIndex;
    rowIdx++
  ) {
    for (
      let colIdx = sheetsRange.firstColIndex;
      colIdx <= sheetsRange.lastColIndex;
      colIdx++
    ) {
      yield cellToText(rowIdx, colIdx)
    }
  }
}

export function shiftByLayout(
  range: SheetsRange,
  isRow: boolean,
  shiftAmount: number,
): SheetsRange {
  if (isRow) {
    return sheetsRange.shift(range, shiftAmount, 0)
  } else {
    return sheetsRange.shift(range, 0, shiftAmount)
  }
}
