import { createContext, useContext, useEffect, useMemo, useState } from "react"
import { Client } from "typesense"

import { getTypesenseApiKey } from "./api"
import type { GetTypesenseApiKeyResponse } from "./types"

interface TypesenseSearchContextType {
  loading: boolean
  error?: Error
  collectionName?: string
  client?: Client
}

const TypesenseSearchContext = createContext<TypesenseSearchContextType>({
  loading: true,
})

const TypesenseSearchProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [apiKeyLoading, setApiKeyLoading] = useState<boolean>(true)
  const [apiKeyError, setApiKeyError] = useState<Error>()
  const [apiKeyResponse, setApiKeyResponse] =
    useState<GetTypesenseApiKeyResponse>()
  const [client, setClient] = useState<Client>()

  useEffect(() => {
    void (async () => {
      try {
        const response = await getTypesenseApiKey()
        setApiKeyResponse(response)
        setClient(
          new Client({
            nodes: [
              {
                host: response.host,
                // @ts-expect-error empty string is ok, will just not set it.
                port: response.port ?? "",
                protocol: response.protocol,
              },
            ],
            apiKey: response.apiKey,
          }),
        )
      } catch (error) {
        setApiKeyError(error as Error)
      } finally {
        setApiKeyLoading(false)
      }
    })()
  }, [])

  const value = useMemo(
    () => ({
      loading: apiKeyLoading,
      error: apiKeyError,
      collectionName: apiKeyResponse?.collectionName,
      client: client,
    }),
    [apiKeyLoading, apiKeyError, client, apiKeyResponse?.collectionName],
  )
  return (
    <TypesenseSearchContext.Provider value={value}>
      {children}
    </TypesenseSearchContext.Provider>
  )
}

const useTypesenseSearch = (): TypesenseSearchContextType => {
  return useContext(TypesenseSearchContext)
}

export { TypesenseSearchProvider, useTypesenseSearch }
