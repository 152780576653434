import Avatar from "antd/es/avatar"
import Card from "antd/es/card"
import { doc, getDoc } from "firebase/firestore"
import { Check } from "lucide-react"
import { useCallback, useEffect, useState } from "react"

import fallbackImage from "../../assets/img/logo_only.svg"
import { useQuiltAuthState } from "../../contexts/useQuiltAuthState"
import { makeConverter } from "../../dbUtils"
import { db } from "../../firebaseApp"
import { switchGroupApi } from "../../groups/api"
import type { Group } from "../../groups/types"
import useErrorPopup from "../../hooks/useErrorPopup"
import { GROUPS_COLLECTION } from "../../types/common"
import { withHeader } from "../Header"

const UserGroupsManager: React.FC = () => {
  const {
    claims,
    loading: claimsLoading,
    forceRefreshAuthUser,
    authUser,
  } = useQuiltAuthState()
  const { handleError, handleSuccess } = useErrorPopup()
  const [groups, setGroups] = useState<Group[]>([])
  const [groupsLoading, setGroupsLoading] = useState(false)

  const switchToGroup = useCallback(
    async (groupOid: string, groupName: string) => {
      if (!authUser || claims?.activeGroupOid === groupOid) {
        return
      }
      setGroupsLoading(true)
      try {
        await switchGroupApi({ groupOid })
        await forceRefreshAuthUser(authUser)
        handleSuccess(`Switched to group ${groupName}`)
      } catch (error) {
        handleError({ prefix: "Unable to switch groups", error })
      } finally {
        setGroupsLoading(false)
      }
    },
    [
      authUser,
      forceRefreshAuthUser,
      handleSuccess,
      handleError,
      claims?.activeGroupOid,
    ],
  )

  // Use string for memoization.
  const groupOidsStr = claims?.roles ? Object.keys(claims.roles).join(",") : ""

  useEffect(() => {
    if (!groupOidsStr) {
      return
    }
    const fetchGroups = async () => {
      setGroupsLoading(true)
      try {
        const groupOids = groupOidsStr.split(",")
        const groupPromises = groupOids.map((oid) => {
          const docRef = doc(db, GROUPS_COLLECTION, oid).withConverter(
            makeConverter<Group>(),
          )
          return getDoc(docRef)
        })
        const groupDocs = (await Promise.all(groupPromises))
          .map((docSnapshot) => docSnapshot.data())
          .filter((doc) => doc !== undefined)
        setGroups(groupDocs)
      } catch (e) {
        handleError({ prefix: "Unable to load groups", error: e })
      } finally {
        setGroupsLoading(false)
      }
    }
    void fetchGroups()
  }, [groupOidsStr, handleError])

  const isActiveGroup = (groupOid: string) =>
    groupOid === claims?.activeGroupOid

  return (
    <div className="m-8">
      {groups.map((group) => (
        <Card
          key={group.oid}
          className={`mb-[10px] max-w-[500px] ${isActiveGroup(group.oid) ? "border-purple-500 bg-purple-50" : "hover:bg-gray-25 cursor-pointer"}`}
          bordered
          loading={claimsLoading || groupsLoading}
          onClick={() => switchToGroup(group.oid, group.name)}
        >
          <div className="flex w-full items-center justify-between">
            <div className="mr-auto flex flex-row items-center">
              <Card.Meta
                avatar={
                  <Avatar
                    size={64}
                    className="rounded-xl"
                    src={group.image_url || fallbackImage}
                  />
                }
              />
              <div className="flex flex-col">
                <div
                  className={`${isActiveGroup(group.oid) ? "text-purple-500" : ""} font-bold`}
                >
                  {group.name}
                </div>
                <div className="mt-[5px] text-sm text-gray-700">
                  {group.description}
                </div>
              </div>
            </div>

            <Check
              className={`${isActiveGroup(group.oid) ? "text-purple-500" : "invisible"}`}
            />
          </div>
        </Card>
      ))}
    </div>
  )
}
export default withHeader({ title: "Switch Groups" })(UserGroupsManager)
