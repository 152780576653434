import type { GroupTag, TagColor } from "./types"

export const TAGCOLORS_CLASSMAP: Record<TagColor, string> = {
  gray: "bg-gray-400",
  red: "bg-red-500",
  orange: "bg-orange-500",
  yellow: "bg-yellow-500",
  lime: "bg-lime-400",
  green: "bg-green-500",
  teal: "bg-teal-600",
  cyan: "bg-cyan-600",
  blue: "bg-blue-500",
  indigo: "bg-indigo-500",
  violet: "bg-violet-500",
  purple: "bg-purple-500",
  fuchsia: "bg-fuchsia-600",
  pink: "bg-pink-200",
}

interface Props {
  tag: Pick<GroupTag, "color" | "name">
  className?: string
  onClick?: () => void
}

export const TagListItem: React.FC<Props> = ({ tag, className }) => {
  return (
    <div className={`flex items-center ` + className}>
      <div
        className={`min-2-3 mr-2 h-3 w-3 rounded-full ${TAGCOLORS_CLASSMAP[tag.color]}`}
      ></div>{" "}
      {tag.name}
    </div>
  )
}

const QuiltTag: React.FC<Props> = ({ tag, className }) => {
  return (
    <div
      className={`inline-flex items-center rounded-full border bg-white px-3 py-1 text-sm ${className ?? ""}`}
      title={tag.name}
    >
      <span
        className={`mr-2 inline-block h-2 w-2 min-w-2 rounded-full ${TAGCOLORS_CLASSMAP[tag.color]}`}
      ></span>
      <span className="truncate text-black">{tag.name}</span>
    </div>
  )
}

export default QuiltTag
