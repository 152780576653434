import logoContent from "../assets/img/logo_full.svg?raw"
import markContent from "../assets/img/logo_only.svg?raw"

const QuiltLogo: React.FC<{
  small?: boolean
  width?: string
  className?: string
}> = ({ small = false, width, className }) => {
  const content = small ? markContent : logoContent

  return (
    <div
      className={`flex h-auto ${className ?? ""}`}
      style={{ width: width ?? "auto" }}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  )
}

export default QuiltLogo
